import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';

import {
    CContainer,
    CCol,
    CRow,
    CTable,
    CTableHead,
    CTableRow,
    CTableHeaderCell,
    CTableBody,
    CTableDataCell,
    CTooltip,
} from '@coreui/bootstrap-react';

import { NavbarBackoffice, Footer, FormatDate, LoadingSpinner } from '../../components';

import axios from 'axios';
import "./style.css";

import jwt_decode from 'jwt-decode';

import CIcon from '@coreui/icons-react';
import * as icon from '@coreui/icons';

const ListSupplier = () => {
    const BASE_URL = 'https://pi.backend.area.mg';
    const [shopDetails, setShopDetails] = useState(null);

    const navigate = useNavigate();

    // Get list of bill pending 
    const [suppliers, setSuppliers] = useState([]);
    // Get the list of provider of payment
    const getListOfSuppliers = async () => {
        const shop = await getShopDetails();
        const shop_id = shop.id;
        try {
            const response = await axios.get(`${BASE_URL}/shop/api/suppliers/${shop_id}/list/`);
            const supp = response.data;
            setSuppliers(supp);
            return supp;

        } catch (error) {
            console.log(error);
        };
    };

    // Assuming you have stored the access token in a variable called `accessToken`
    const decodedToken = jwt_decode(localStorage.getItem('authTokens'));
    const username = decodedToken.username;


    // Function return the staff user with their Username
    const getStaffUserByUsername = async () => {
        try {
            const response = await axios.get(`${BASE_URL}/api/user/${username}/users`);
            return response.data;
        } catch (error) {
            console.log(error);
            alert("Vous ne pouvez pas accéder à cette page.");
        };
    }

    // Get shop info
    const getShopDetails = async () => {
        const staff = await getStaffUserByUsername();
        const staff_id = staff.id;
        try {
            const response = await axios.get(`${BASE_URL}/shop/api/shop/owner/${staff_id}/`);
            if (response.data) {
                setShopDetails(response.data);
            }
            return response.data;
        } catch (error) {
            console.log(error);
        };
    };

    // Create a Function to Convert Data to CSV
    const convertToCSV = async (data) => {
        const header = [
            'Nom', 'Code', 'Description', 'Adresse', 'Phone', 'Email', 'Website', 'Date_Enregistrement',
            'Derniere_Actualisation'
        ];

        const rows = data.map(supp => [
            supp.name,
            supp.code,
            supp.description,
            supp.address,
            supp.phone,
            supp.email,
            supp.website,
            supp.created_at,
            supp.updated_at
        ]);

        return [header, ...rows].map(row => row.join(',')).join('\n');
    }

    const addSupplier = () => {
        navigate('/ajouter-fournisseur');
    }

    // Function to download CSV file
    const downloadCSV = async () => {
        const datetime = new Date().toISOString().replace(/:/g, '-');
        const filename = `Fournisseurs_${datetime}.csv`;

        if (!suppliers || suppliers.length === 0) {
            alert("Aucun fournisseur trouvé");
            return;
        }

        try {
            const data = await convertToCSV(suppliers);

            const csvContent = 'data:text/csv;charset=utf-8,' + encodeURIComponent(data);
            const link = document.createElement('a');
            link.setAttribute('href', csvContent);
            link.setAttribute('download', filename);
            link.click();
        } catch (error) {
            console.error("Error exporting data to CSV:", error);
            alert("Une erreur s'est produite lors de l'exportation des données en CSV.");
        }
    };

    useEffect(() => {
        const fetchSuppliers = async () => {
            await getListOfSuppliers();
        };
        getShopDetails();

        const interval = setInterval(() => {
            fetchSuppliers();
        }, 5000);

        return () => clearInterval(interval);


    }, [])

    return (
        (shopDetails ?
            <div>
                <NavbarBackoffice />
                <CContainer className="mt-1">
                    <CRow>
                        <CCol md={12}>
                            {suppliers.length === 0 ? <h4 className="display-6 text-center">Merci de patienter pour les mises à jour des fournisseurs ...
                                <CTooltip content="Ajouter un produit">
                                    <CIcon
                                        onClick={addSupplier}
                                        type="submit"
                                        className="custom-icon-add"
                                        icon={icon.cilPlus}
                                    />
                                </CTooltip>
                            </h4> :
                                <h4 className="display-6 text-center">Tous les fournisseurs existent dans la base ( {suppliers.length} ) &nbsp;&nbsp;
                                    <CTooltip content="Télécharger dans un fichier csv.">
                                        <CIcon
                                            onClick={downloadCSV}
                                            type="submit"
                                            className="custom-icon-download"
                                            icon={icon.cilCloudDownload}
                                        />
                                    </CTooltip>
                                    &nbsp;&nbsp;
                                    <CTooltip content="Ajouter un produit">
                                        <CIcon
                                            onClick={addSupplier}
                                            type="submit"
                                            className="custom-icon-add"
                                            icon={icon.cilPlus}
                                        />
                                    </CTooltip>
                                </h4>}
                            {suppliers &&
                                <CTable color="dark" striped>
                                    <CTableHead>
                                        <CTableRow>
                                            <CTableHeaderCell scope="col">Nom</CTableHeaderCell>
                                            <CTableHeaderCell scope="col">Code</CTableHeaderCell>
                                            <CTableHeaderCell scope="col">Description</CTableHeaderCell>
                                            <CTableHeaderCell scope="col">Adresse</CTableHeaderCell>
                                            <CTableHeaderCell scope="col">Téléphone</CTableHeaderCell>
                                            <CTableHeaderCell scope="col">Adresse-Email</CTableHeaderCell>
                                            <CTableHeaderCell scope="col">Website</CTableHeaderCell>
                                            <CTableHeaderCell scope="col">Dernière_MAJ</CTableHeaderCell>
                                            <CTableHeaderCell scope="col">Actions</CTableHeaderCell>
                                        </CTableRow>
                                    </CTableHead>
                                    <CTableBody>
                                        {suppliers.map((supp) => (
                                            <CTableRow key={supp.id} color='light'>
                                                <CTableHeaderCell scope="row">{supp.name}</CTableHeaderCell>
                                                <CTableDataCell>{supp.code}</CTableDataCell>
                                                <CTableDataCell>{supp.description}</CTableDataCell>
                                                <CTableDataCell>{supp.address}</CTableDataCell>
                                                <CTableDataCell>{supp.phone}</CTableDataCell>
                                                <CTableDataCell>{supp.email}</CTableDataCell>
                                                <CTableDataCell>{supp.website}</CTableDataCell>
                                                <CTableDataCell>
                                                    <FormatDate dateString={supp.updated_at} />
                                                </CTableDataCell>
                                                <CTableDataCell>
                                                    <CTooltip content="Modifier">
                                                        <CIcon
                                                            type="submit"
                                                            onClick={() => navigate(`/modifier-fournisseur`, {
                                                                state: {
                                                                    supplierID: supp.id,
                                                                }
                                                            })}
                                                            className="custom-icon-edit"
                                                            icon={icon.cilColorBorder}
                                                        />
                                                    </CTooltip>
                                                </CTableDataCell>
                                            </CTableRow>
                                        ))}

                                    </CTableBody>
                                </CTable>
                            }
                        </CCol>
                    </CRow>
                </CContainer>
                <br /><br />
                <Footer />
            </div> : <div><NavbarBackoffice /><LoadingSpinner /></div>
        )
    );
};

export default ListSupplier;