import React, { useEffect, useState } from 'react';
import axios from 'axios';

import { Footer, Navbar, LoadingSpinner } from '../../components';

import {
    CContainer,
    CRow,
    CCol,
} from '@coreui/bootstrap-react';

import jwt_decode from 'jwt-decode';

function UserInfo() {
    const BASE_URL = 'https://pi.backend.area.mg';
    const [authTokens, setAuthTokens] = useState(() => JSON.parse(localStorage.getItem('authTokens')) || null);

    const [userInfo, setUserInfo] = useState(null);
    const [userConnected, setUserConnected] = useState(null);
    const [cashierUser, setCashierUser] = useState(null);

    const [paidOrderItemsCount, setPaidOrderItemsCount] = useState("...  Calcul en cours ...");
    const [grandTotal, setGrandTotal] = useState("...  Calcul en cours ...");

    const [notPaidOrderItemsCount, setNotPaidOrderItemsCount] = useState("...  Calcul en cours ...");
    const [grandTotalNotPaid, setGrandTotalNotPaid] = useState("...  Calcul en cours ...");

    

    const [shopDetails, setShopDetails] = useState(null);

    const [countCompletedTasks, setCountCompletedTasks] = useState(0);
    const [countPendingTasks, setCountPendingTasks] = useState(0);

    // Assuming you have stored the access token in a variable called `accessToken`
    const decodedToken = jwt_decode(localStorage.getItem('authTokens'));
    const username = decodedToken.username;

    const refreshToken = async () => {
        try {
            const response = await axios.post(`${BASE_URL}/api/token/refresh/`, {
                refresh: authTokens && authTokens.refresh,
            });

            const { data } = response;

            if (response.status === 200) {
                setAuthTokens(data);
                localStorage.setItem('authTokens', JSON.stringify(data));
                return data.access;
            }
            return null;
        } catch (error) {
            console.error('Token refresh failed', error);
            return;
        }
    };

    const fetchUserInfo = async () => {
        try {
            const accessToken = await refreshToken();
            const response = await axios.get(`${BASE_URL}/api/user-info`, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
            const user_connected = response.data
            setUserInfo(user_connected);
            return user_connected;
        } catch (error) {
            console.error('Error fetching user information:', error);
        }
    };

    // Function return the staff user with their Username
    const getStaffUserByUsername = async () => {
        try {
            const response = await axios.get(`${BASE_URL}/api/user/${username}/users`);
            const user_connected = response.data;
            setUserConnected(user_connected);
            return user_connected;
        } catch (error) {
            console.log(error);
            alert("Vous ne pouvez pas accéder à cette page.");
        };
    }

    // Function return the staff user if cashier or not 
    const getCashierUser = async () => {
        // Get user connected
        const userConnected = await getStaffUserByUsername();
        const user_id = userConnected.id;

        try {
            const response = await axios.get(`${BASE_URL}/shop/api/cashier/active/${user_id}/`);
            const cashier = response.data;
            setCashierUser(cashier);
            return cashier;
        } catch (error) {
            console.log(error);
        };
    }

    // Get shop info
    const getShopDetails = async () => {
        const cashier = await getCashierUser();
        const shop_id = cashier.shop;
        try {
            const response = await axios.get(`${BASE_URL}/shop/api/shop/${shop_id}/`);
            if (response.data) {
                setShopDetails(response.data);
            }
            return response.data;
        } catch (error) {
            console.log(error);
        };
    };

    // Make an API call to "ip-api" to get the timezone based on the laptop's IP address
    // const getLocalDateWithTimeZone = async () => {
    //     try {
    //         const response = await fetch('http://ip-api.com/json');
    //         const data = await response.json();

    //         if (data && data.timezone) {
    //             const localDate = new Intl.DateTimeFormat('en-CA', {
    //                 timeZone: data.timezone,
    //                 year: 'numeric',
    //                 month: '2-digit',
    //                 day: '2-digit',
    //             }).format(new Date());
    //             console.log('Local Date:', localDate);
    //             return localDate.toISOString();
    //         }
    //     } catch (error) {
    //         console.log('Error fetching timezone:', error);
    //     }
    // };

    // Function to get summary of orderItem
    const orderItemStats = async () => {
        // get cashier user
        const cashier_id = cashierUser.id;
        try {
            const response = await axios.get(`${BASE_URL}/shop/api/orderItem/by/cashier/${cashier_id}/list/`);

            const data = response.data;
            console.log(data);
            const paidItems = data.paid_order_items;
            const notPaidItems = data.not_paid_order_items;

            // Count the number of paid_order_items for today
            const grandTotalArticlesNotYetPaid = notPaidItems.reduce(
                (count, item) => count + item.quantity,
                0
            );
            setNotPaidOrderItemsCount(parseInt(grandTotalArticlesNotYetPaid).toLocaleString())

            // Calculate the grand total amount for all products in OrderItem not paid every
            const grandTotalAmountNotPaid = notPaidItems.reduce(
                (total, item) => total + item.quantity * item.unit_price,
                0
            );

            setGrandTotalNotPaid(parseInt(grandTotalAmountNotPaid).toLocaleString());

            // Get today's date in 'YYYY-MM-DD' format
            const today = new Date().toISOString().slice(0, 10);

            // Filter paid order items for today
            const todayPaidOrderItems = paidItems.filter(item => {
                return item.order.updated_at && item.order.updated_at.slice(0, 10) === today;
            });
            // Calculate PaidOrderItemsCount
            const paidItemsCount = todayPaidOrderItems.reduce(
                (count, item) => count + item.quantity,
                0
            );
            setPaidOrderItemsCount(paidItemsCount);

            // Calculate GrandTotal
            const grandTotalAmount = todayPaidOrderItems.reduce(
                (total, item) => total + item.quantity * item.unit_price,
                0
            );
            setGrandTotal(grandTotalAmount);

        } catch (error) {
            // Handle error
            console.log('Error fetching orderItems data:', error)
        }
    }

    // Function to get summary of tasks
    const tasksStats = async () => {
        // get cashier user
        const shop = await getShopDetails();
        const shop_id = shop.id;
        try {
            const response = await axios.get(`${BASE_URL}/shop/api/tasks/pending&completed/shop/${shop_id}/`);

            const data = response.data;
            const completed_tasks = data.completed_tasks;
            const pending_tasks = data.pending_tasks;

            setCountCompletedTasks(completed_tasks.length);
            setCountPendingTasks(pending_tasks.length);

        } catch (error) {
            // Handle error
            console.log('Error fetching tasks data:', error)
        }
    }

    useEffect(() => {
        async function fetchData() {
            await getStaffUserByUsername();
            await fetchUserInfo();
            await getShopDetails();
            await tasksStats();
            if (userConnected) {
                await getCashierUser();
                if (cashierUser) {
                    await orderItemStats();
                }
            }
        }

        const interval = setInterval(() => {
            fetchData();
        }, 1000);

        return () => clearInterval(interval);

    }, [userInfo, userConnected, shopDetails]);

    return (
        (shopDetails ?
            <div>
                <Navbar />
                {userInfo ? (
                    <CContainer className="mt-5">
                        <CRow className="row align-items-start">
                            <CCol className="custom-col" xs={12} md={6}>
                                <h3>Mon information</h3>
                                <hr />
                                <p>Mon pseudo : {userInfo.username}</p>
                                <p>Adresse e-mail : {userInfo.email}</p>
                                <p>Nom : {userInfo.first_name}</p>
                                <p>Prénom : {userInfo.last_name}</p>
                                <p>Mon rôle: {userInfo.group_name}</p>
                                <hr />
                                <h3>Information SHOP</h3>
                                <p>Adresse : {shopDetails.city.name} ( {shopDetails.city.postal_code.code} ) - {shopDetails.state.name}</p>
                                <p>Contact Gérant(e) : {shopDetails.phone}</p>
                                <hr />
                            </CCol>
                            <CCol className="custom-col" xs={12} md={6}>
                                <h3>Mon rapport de la journée</h3>
                                <hr />
                                <p>Nombre article(s) vendu(s) aujourd'hui: {paidOrderItemsCount}</p>
                                <p style={{ color: 'red' }}>Nombre article(s) reservé(s) (Cumulé) | Non payé: {notPaidOrderItemsCount}</p>
                                <hr />
                                <p>Recette du jour : {grandTotal} {shopDetails.country.currency.symbol}</p>
                                <p style={{ color: 'red' }}>En attente de paiment (Cumulé) : {grandTotalNotPaid} {shopDetails.country.currency.symbol}</p>
                                <hr />
                                <p>Nombre tâche à faire : {countCompletedTasks+countPendingTasks}</p>
                                <p style={{ color: 'red' }}>Nombre tâche non fait : {countPendingTasks}</p>
                                <hr />
                            </CCol>
                        </CRow>

                    </CContainer>
                ) : (
                    <CContainer className="mt-5">
                        <CRow className="row g-3">
                            <h4>Mon information</h4>
                            <p>Chargement des informations utilisateur...</p>
                        </CRow>
                    </CContainer>
                )}
                <Footer/>
            </div> : <div><Navbar /><LoadingSpinner /></div>
        )
    );
}

export default UserInfo;
