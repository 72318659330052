import React, { useState, useEffect } from 'react';
import {
    CContainer, CRow, CCol, CForm,
    CFormLabel, CFormInput, CFormTextarea, CButton
} from '@coreui/bootstrap-react';

import { NavbarBackoffice, Footer, LoadingSpinner } from '../../components';

import axios from 'axios';

import jwt_decode from 'jwt-decode';

import CIcon from '@coreui/icons-react';
import * as icon from '@coreui/icons';

const AddMaterial = () => {
    const BASE_URL = 'https://pi.backend.area.mg';
    const [shopDetails, setShopDetails] = useState(null);

    // Assuming you have stored the access token in a variable called `accessToken`
    const decodedToken = jwt_decode(localStorage.getItem('authTokens'));
    const username = decodedToken.username;

    // Define a loading state
    const [isLoading, setIsLoading] = useState(false);

    // Function return the staff user with their Username
    const getStaffUserByUsername = async () => {
        try {
            const response = await axios.get(`${BASE_URL}/api/user/${username}/users`);
            return response.data;
        } catch (error) {
            console.log(error);
        };
    }

    // Get shop info
    const getShopDetails = async () => {
        const staff = await getStaffUserByUsername();
        const staff_id = staff.id;
        try {
            const response = await axios.get(`${BASE_URL}/shop/api/shop/owner/${staff_id}/`);
            if (response.data) {
                setShopDetails(response.data);
            }
            return response.data;
        } catch (error) {
            alert("vous n'êtes pas autorisé à accéder à cette page.")
            console.log(error);
        };
    };

    const [formData, setFormData] = React.useState({
        name: '',
        code: '',
        description: '',
        purchase_date: '',
        purchase_cost: '',
        amortization_period: '',
        remaining_quantity: '',
        shop: '', // This will be set based on the shop you want to associate the material with
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        // Set loading to true when starting the function
        setIsLoading(true);
        // Get shop id
        const shop_id = shopDetails.id;

        e.preventDefault();
        try {
            // Replace 'YOUR_API_URL' with the actual URL for MaterialCreateAPIView
            await axios.post(`${BASE_URL}/shop/api/material/${shop_id}/create/`, formData);
            // Optionally, you can add a success message or redirect the user to another page.
            alert('Création réussie!');
            setFormData({
                name: '',
                code: '',
                description: '',
                purchase_date: '',
                purchase_cost: '',
                amortization_period: '',
                remaining_quantity: '',
                shop: '', // This will be set based on the shop you want to associate the material with
            });
        } catch (error) {
            // Handle any errors that may occur during the API call
            console.error('Error creating material:', error);
            if (error.response.data.code) {
                alert("Erreur :" + error.response.data.code);
            } else {
                alert('Erreur dans la création du matériel .Veuillez réessayer');
            }
        } finally {
            // Set loading to false when the function is done, regardless of success or failure
            setIsLoading(false);
        }
    };

    useEffect(() => {
        async function fetchData() {
            await getShopDetails();
        }
        fetchData();

    }, []);

    return (
        (shopDetails ?
            <div>
                <NavbarBackoffice />
                <CContainer className="py-4">
                    <CRow className="justify-content-center">
                        <CCol sm="8" lg="6">
                            <CForm onSubmit={handleSubmit}>
                                {isLoading ? <LoadingSpinner /> :
                                    <>
                                        <CCol xs="auto">
                                            <CFormLabel htmlFor="name">Nom de matériel :</CFormLabel >
                                            <CFormInput type="text" id="name" name="name" value={formData.name} onChange={handleChange} required />
                                        </CCol>
                                        <CCol xs="auto">
                                            <CFormLabel htmlFor="description">Code matériel ( Unique | Ex : MAT20230101_01):</CFormLabel >
                                            <CFormInput type="text" id="code" name="code" value={formData.code} onChange={handleChange} required />
                                        </CCol>
                                        <CCol xs="auto">
                                            <CFormLabel htmlFor="description">Déscription :</CFormLabel >
                                            <CFormTextarea id="description" name="description" value={formData.description} onChange={handleChange} required />
                                        </CCol>
                                        <CCol xs="auto">
                                            <CFormLabel htmlFor="purchase_date">Date d'acquisition :</CFormLabel >
                                            <CFormInput type="date" id="purchase_date" name="purchase_date" value={formData.purchase_date} onChange={handleChange} required />
                                        </CCol>
                                        <CCol xs="auto">
                                            <CFormLabel htmlFor="purchase_cost">Coût d'achat en {shopDetails.country.currency.symbol} :</CFormLabel >
                                            <CFormInput type="number" id="purchase_cost" name="purchase_cost" value={formData.purchase_cost} onChange={handleChange} required />
                                        </CCol>
                                        <CCol xs="auto">
                                            <CFormLabel htmlFor="amortization_period">Période d'amortissement (En mois) :</CFormLabel >
                                            <CFormInput type="number" id="amortization_period" name="amortization_period" value={formData.amortization_period} onChange={handleChange} required />
                                        </CCol>
                                        <CCol xs="auto">
                                            <CFormLabel htmlFor="remaining_quantity">Quantité restante :</CFormLabel >
                                            <CFormInput type="number" id="remaining_quantity" name="remaining_quantity" value={formData.remaining_quantity} onChange={handleChange} required />
                                        </CCol>
                                        <hr />
                                        <CButton type="submit" color="primary">
                                            <CIcon type="submit" className="icon-default-add" icon={icon.cilPlaylistAdd} />
                                            &nbsp;Ajouter dans la liste des matériels
                                        </CButton>
                                    </>
                                }
                            </CForm>
                        </CCol>
                    </CRow>
                </CContainer>
                <br /><br />
                <Footer />
            </div> : <div><NavbarBackoffice /><LoadingSpinner /></div>
        )
    );
};

export default AddMaterial;
