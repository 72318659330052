import React, { useState } from 'react';
import axios from 'axios';

import { Footer, Navbar } from '../../components';

import {
  CContainer,
  CCol,
  CRow,
  CFormInput,
  CFormLabel,
  CButton,
  CForm,
} from '@coreui/bootstrap-react';

import "./style.css";
import { FaEye, FaEyeSlash } from 'react-icons/fa';

function ChangePassword() {
  const BASE_URL = 'https://pi.backend.area.mg/'
  const [authTokens, setAuthTokens] = useState(() => JSON.parse(localStorage.getItem('authTokens')) || null);

  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);

  const handleChangePassword = async (e) => {
    e.preventDefault();

    if (newPassword !== confirmPassword) {
      setErrorMessage("Le nouveau mot de passe et le mot de passe de confirmation ne correspondent pas");
      return;
    }

    const refreshToken = async () => {
      try {
        const response = await axios.post(`${BASE_URL}/api/token/refresh/`, {
          refresh: authTokens && authTokens.refresh,
        });

        const { data } = response;
        console.log(data)

        if (response.status === 200) {
          setAuthTokens(data);
          localStorage.setItem('authTokens', JSON.stringify(data));
          return data.access;
        }
        return null;
      } catch (error) {
        setErrorMessage("Token error");
        console.error('Token refresh failed', error);
        return;
      }
    };

    try {
      const token = await refreshToken();

      const response = await axios.post(`${BASE_URL}/api/change-password/`, {
        old_password: oldPassword,
        new_password: newPassword,
      },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
      console.log(response)

      setSuccessMessage(response.data.detail);
      setOldPassword('');
      setNewPassword('');
      setConfirmPassword('');
      setErrorMessage('');
    } catch (error) {
      console.log(error)
      setErrorMessage(error.response.data.detail);
    }
  };

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };


  const handleToggleNewPasswordVisibility = () => {
    setShowNewPassword(!showNewPassword);
  };

  return (
    <div>
      <Navbar/>
      <CContainer className="mt-5">
        <CRow className="row g-3">
          <h4>CHANGEMENT DE MOT DE PASSE</h4>
          <CForm onSubmit={handleChangePassword}>
            <CCol md="4" xs="12" className="custom-group-col">
              <CFormLabel className="visually-hidden">
                Ancien mot de passe :
              </CFormLabel>
              <CFormInput
                type={showPassword ? 'text' : 'password'}
                placeholder="Ancien mot de passe"
                value={oldPassword}
                onChange={(e) => setOldPassword(e.target.value)}
                required />
              <CButton type="button" className="password-toggle-button" onClick={handleTogglePasswordVisibility}>
                {showPassword ? <FaEyeSlash /> : <FaEye />}
              </CButton>
            </CCol><br />
            <CCol md="4" xs="12" className="custom-group-col">
              <CFormLabel className="visually-hidden">
                Nouveau mot de passe :
              </CFormLabel>
              <CFormInput
                type={showNewPassword ? 'text' : 'password'}
                placeholder="Nouveau mot de passe"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                required />
                <CButton type="button" className="password-toggle-button" onClick={handleToggleNewPasswordVisibility}>
                {showNewPassword ? <FaEyeSlash /> : <FaEye />}
              </CButton>
            </CCol><br />
            <CCol md="4" xs="12">
              <CFormLabel className="visually-hidden">
                Confirme le nouveau mot de passe :
              </CFormLabel>
              <CFormInput
                type='password'
                placeholder="Confirme le nouveau mot de passe"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                required />
            </CCol><br />
            <CCol className='custom-btn-submit' md="4" xs="12">
              <CButton type="submit" className="mb-4">
                Changer mon mot de passe
              </CButton>
            </CCol>
            <CCol className='custom-btn-submit' md="4" xs="12">
              <span className='errorMessage'>{errorMessage && {errorMessage}}</span>
              <span className='successMessage'>{successMessage && {successMessage}}</span>
            </CCol>
          </CForm>
        </CRow>
      </CContainer>
      <Footer/>
    </div>
  );
}

export default ChangePassword;
