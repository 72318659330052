import React, { useState } from 'react';
import axios from 'axios';
import {
  CContainer,
  CCol,
  CRow,
  CFormInput,
  CFormLabel,
  CButton,
  CForm,
} from '@coreui/bootstrap-react';

import { Link } from 'react-router-dom';

function ResetPassword() {
  const BASE_URL = 'https://pi.backend.area.mg/'
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${BASE_URL}/api/password-reset/`, { email });
      setMessage(response.data.message);
      setEmail('');
    } catch (error) {
      setErrorMessage("Échec de l'envoi de l'e-mail de réinitialisation du mot de passe.");
    }
  };

  return (
    <div>
      <CContainer className="mt-5">
        <CRow className="row g-3">
          <CCol md={12}>
            <h4>Réinitialiser mon mot de passe</h4>
            <CForm onSubmit={handleFormSubmit}>
              <CCol md="4" xs="12" >
                <CFormLabel>
                  Entrer votre adresse e-mail associé à votre pseudo :
                </CFormLabel>
                <CFormInput
                  type="email"
                  placeholder="votre identifiant e-mail"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required />
              </CCol><br />
              <CCol md="4" xs="12">
                <CButton type="submit" className="mb-4">
                  Réinitialiser
                </CButton>
              </CCol>
            </CForm>
            <CCol className='custom-btn-submit' md="4" xs="12">
              {errorMessage && <span className='errorMessage'>{errorMessage}</span>}
              {message && <span className='infoMessage'>{message}</span>}
              <Link to="/connexion" className='btn btn-link mt-2'>
                ◄ Retourner à la page de connexion
              </Link>
            </CCol>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  );
}

export default ResetPassword;
