import React from 'react';
import { Document, Page, View, Text, Image } from '@react-pdf/renderer';
import "./style.css";

const RecipeBillPDF = ({dataBill}) => (
    <Document>
        <Page size="A8" className="page"> {/* Use className to apply the styles */}
            <View styleName="header" className="header">
                <Image src={dataBill.logoImage} styleName="logo" className="logo" />
                <Text styleName="infoLabel" className="infoLabel">{dataBill.enterpriseName}</Text>
                <Text styleName="infoLabel" className="infoLabel">{dataBill.nifStat}</Text>
                <Text styleName="infoLabel" className="infoLabel">{dataBill.enterpriseAddress}</Text>
                <Text styleName="infoLabel" className="infoLabel">Tel : {dataBill.enterprisePhone}</Text>
            </View>

            <View className="tableRow" styleName="tableRow">
                <Text styleName="tableHeaderCell" className="tableHeaderCell">Désignation</Text>
                <Text styleName="tableHeaderCell" className="tableHeaderCell">Qté</Text>
                <Text styleName="tableHeaderCell" className="tableHeaderCell">Prix unitaire</Text>
            </View>
            {/* Loop through the items in the bill and display them here */}
            {dataBill.cartList.map(cart => (
                <View className="tableRow" styleName="tableRow" key={cart.productId}>
                    <Text styleName="tableCell" className="tableCell">{cart.name}</Text>
                    <Text styleName="tableCell" className="tableCell">{cart.quantity}</Text>
                    <Text styleName="tableCell" className="tableCell">{cart.price}</Text>
                </View>
            ))}

            <View className="pageBreak" styleName="pageBreak" /> {/* Use styleName to apply the styles */}
            <View className="footer" styleName="footer">
                <Text styleName="smallText" className="smallText">Remise: {dataBill.currency} {dataBill.total_discount}</Text>
                <Text styleName="smallText" className="smallText">Tax ( {dataBill.typeOfTax} - {dataBill.typeOfTaxValue}) : {dataBill.currency} {dataBill.taxValue}</Text>
                <Text styleName="smallText" className="smallText">Total: {dataBill.currency} {dataBill.totalAmount} </Text>
                { (dataBill.providerPaymentType = "Cash") && 
                    <><Text styleName="smallText" className="smallText">Montant : {dataBill.currency} {dataBill.amountOrCouponOrRef} </Text>
                    <Text styleName="smallText" className="smallText">La monnaie retour : {dataBill.currency} {dataBill.backAmount} </Text></>
                }
                
                <Text styleName="smallText" className="smallText">Merci pour votre visite</Text>
                <Text styleName="smallText" className="smallText">{dataBill.nameOfCashier}</Text>
            </View>
        </Page>
    </Document>
);

export default RecipeBillPDF;
