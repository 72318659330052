import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

import jwt_decode from 'jwt-decode';

import {
    CTooltip,
    CBadge,
    CContainer,
    CNavbar,
    CNavbarBrand,
    CNavbarToggler,
    CCollapse,
    CNavbarNav,
    CNavItem,
    CNavLink,
    CDropdown,
    CDropdownToggle,
    CDropdownMenu,
    CDropdownItem,
    CDropdownDivider,
    CImage,
    CButton,
} from '@coreui/bootstrap-react';

import CIcon from '@coreui/icons-react';
import * as icon from '@coreui/icons';

const NavbarBackoffice = () => {
    const BASE_URL = 'https://pi.backend.area.mg';
    const navigate = useNavigate();
    const [visible, setVisible] = useState(false)
    const [ordersPending, setOrdersPending] = useState(null);
    const [shopDetails, setShopDetails] = useState(null);
    const [countOutdateMaterials, setCountOutdateMaterials] = useState(null);
    const currDate = new Date().toLocaleDateString();

    const [countCompletedTasks, setCountCompletedTasks] = useState(0);
    const [countPendingTasks, setCountPendingTasks] = useState(0);

    const logoutUser = () => {
        localStorage.removeItem('authTokens');
        navigate('/connexion');
    };

    const handlePageDashboard = () => {
        navigate('/dashboard');
    }

    const handlePageReportOrders = () => {
        navigate('/ventes');
    }

    const handlePageExpenses = () => {
        navigate('/dépenses');
    }

    const handlePageProducts = () => {
        navigate('/produits');
    }

    const handlePageTodolist = () => {
        navigate('/todo-list-admin');
    }

    const handleChangePassword = () => {
        navigate('/changement-mot-de-passe-admin');
    }
    const handleInfoShop = () => {
        navigate('/info-shop');
    }

    const handlePageMaterial = () => {
        navigate('/matériels');
    }

    const handlePageSupplier = () => {
        navigate('/fournisseurs');
    }

    const handlePageDiscount = () => {
        navigate('/remises');
    }

    const handlePageCoupon = () => {
        navigate('/coupons');
    }

    const handlePageFacture = () => {
        navigate('/factures');
    }


    // Get the list of provider of payment
    const getListOfPendingOrders = async () => {
        const shop = await getShopDetails();
        try {
            const response = await axios.get(`${BASE_URL}/shop/api/orders/${shop.id}/`);
            if (response.data) {
                setOrdersPending(response.data);
            }
            return response.data;
        } catch (error) {
            console.log(error);
        };
    };

    // Assuming you have stored the access token in a variable called `accessToken`
    const decodedToken = jwt_decode(localStorage.getItem('authTokens'));
    const username = decodedToken.username;


    // Function return the staff user with their Username
    const getStaffUserByUsername = async () => {
        try {
            const response = await axios.get(`${BASE_URL}/api/user/${username}/users`);
            return response.data;
        } catch (error) {
            console.log(error);
        };
    }

    // Get shop info
    const getShopDetails = async () => {
        const staff = await getStaffUserByUsername();
        const staff_id = staff.id;
        try {
            const response = await axios.get(`${BASE_URL}/shop/api/shop/owner/${staff_id}/`);
            if (response.data) {
                setShopDetails(response.data);
            }
            return response.data;
        } catch (error) {
            alert("vous n'êtes pas autorisé à accéder à cette page.")
            logoutUser();
            console.log(error);
        };
    };

    // Function to get summary of tasks
    const tasksStats = async () => {
        // get shop id
        const shop = await getShopDetails();
        const shop_id = shop.id;
        try {
            const response = await axios.get(`${BASE_URL}/shop/api/tasks/pending&completed/shop/${shop_id}/`);

            const data = response.data;
            const completed_tasks = data.completed_tasks;
            const pending_tasks = data.pending_tasks;

            setCountCompletedTasks(completed_tasks.length);
            setCountPendingTasks(pending_tasks.length);

        } catch (error) {
            // Handle error
            console.log('Error fetching tasks data:', error)
        }
    }

    // Function to get count of material outdate
    const getCountOfOutdateMaterials = async () => {
        // get shop id
        const shop = await getShopDetails();
        const shop_id = shop.id;
        try {
            const response = await axios.get(`${BASE_URL}/shop/api/materials/amortization/${shop_id}/`);
            const count_outdate_material = response.data.count;

            setCountOutdateMaterials(count_outdate_material);

        } catch (error) {
            // Handle error
            console.log('Error fetching count outdate materials :', error)
        }
    }

    useEffect(() => {
        async function fetchData() {
            await getShopDetails();
            await getListOfPendingOrders();
            await tasksStats();
            await getCountOfOutdateMaterials();
        }
        fetchData();
        const interval = setInterval(() => {
            fetchData();
        }, 5000);

        return () => clearInterval(interval);


    }, []);

    return (
        <CNavbar expand="lg" colorScheme="light" className="bg-light" placement="sticky-top">
            <CContainer fluid>
                <CNavbarBrand href='#' onClick={handlePageDashboard} className="clearfix">
                    {shopDetails ?
                        <CImage align="center" src={BASE_URL + shopDetails.logo} width={40} height={40} />
                        :
                        <CImage align="center" src="/logo.png" width={40} height={40} />
                    }
                </CNavbarBrand>
                <CNavbarToggler
                    aria-label="Toggle navigation"
                    aria-expanded={visible}
                    onClick={() => setVisible(!visible)}
                />
                <CCollapse className="navbar-collapse" visible={visible}>
                    <CNavbarNav>
                        <CNavItem className="custom-nav">
                            {shopDetails ? (
                                <CTooltip content={shopDetails.description}>
                                    <CNavLink href='#'>{shopDetails.name}</CNavLink>
                                </CTooltip>
                            ) : (
                                <CTooltip content="undefined">
                                    <CNavLink href='#'>SHELL-FY</CNavLink>
                                </CTooltip>
                            )}
                        </CNavItem>
                        <CNavItem>
                            <CNavLink onClick={handlePageDashboard}>
                                <CButton className="custom-link-button">
                                    <CIcon type="submit" className="icon-cart" icon={icon.cilChartPie} />
                                    &nbsp;DASHBOARD</CButton>
                            </CNavLink>
                        </CNavItem>
                        <CNavItem className="custom-nav">
                            <CNavLink href='#' onClick={handlePageReportOrders}>
                                <CIcon type="submit" className="icon-default" icon={icon.cilChartLine} />
                                &nbsp;Ventes {ordersPending && (
                                    <CBadge color="info" shape="rounded-pill">{ordersPending.length}</CBadge>)}&nbsp;&nbsp;
                            </CNavLink>
                        </CNavItem>
                        <CNavItem className="custom-nav">
                            <CNavLink href='#' onClick={handlePageProducts}>
                                <CIcon type="submit" className="icon-default" icon={icon.cilChart} />
                                &nbsp;Produits
                            </CNavLink>
                        </CNavItem>
                        <CNavItem className="custom-nav">
                            <CNavLink href='#' onClick={handlePageExpenses}>
                                <CIcon type="submit" className="icon-default" icon={icon.cilClipboard} />
                                &nbsp;Dépenses
                            </CNavLink>
                        </CNavItem>
                        <CNavItem className="custom-nav">
                            <CNavLink href='#' onClick={handlePageTodolist}>
                                <CIcon type="submit" className="icon-default" icon={icon.cilCalendarCheck} />
                                &nbsp;Todo List&nbsp;
                                {countPendingTasks !== 0 && <>
                                    {countCompletedTasks &&
                                        (<CBadge color="success" shape="rounded-pill">{countCompletedTasks}</CBadge>)
                                    } sur &nbsp;
                                    {countPendingTasks &&
                                        (<CBadge color="warning" shape="rounded-pill">{countCompletedTasks + countPendingTasks}</CBadge>)
                                    } </>
                                }
                                &nbsp;
                            </CNavLink>
                        </CNavItem>
                        <CNavItem className="custom-nav">
                            <CNavLink href='#' onClick={handlePageMaterial}>
                                <CIcon type="submit" className="icon-default" icon={icon.cilListRich} />
                                &nbsp;Matériels&nbsp;
                                {countOutdateMaterials !== 0 &&
                                    (<CBadge color="danger" shape="rounded-pill">{countOutdateMaterials}</CBadge>)
                                }
                            </CNavLink>
                        </CNavItem>&nbsp;&nbsp;&nbsp;&nbsp;
                        <CDropdown variant="nav-item" popper={false} className="custom-nav">
                            <CDropdownToggle>
                                <CTooltip content="Paramètres">
                                    <CIcon className="icon-default" icon={icon.cilSettings} />
                                </CTooltip>
                            </CDropdownToggle>
                            <CDropdownMenu>
                                <CDropdownItem href="#" onClick={handleInfoShop}>
                                    <CIcon type="submit" className="icon-info-user" icon={icon.cilLibrary} />
                                    &nbsp;&nbsp;Info
                                </CDropdownItem>
                                <CDropdownItem href="#" onClick={handleChangePassword}>
                                    <CIcon type="submit" className="icon-info-user" icon={icon.cilFingerprint} />
                                    &nbsp;&nbsp;Changement mot de passe
                                </CDropdownItem>
                                <CDropdownDivider />
                                <CDropdownItem href="#" onClick={handlePageSupplier}>
                                    <CIcon type="submit" className="icon-info-user" icon={icon.cilAddressBook} />
                                    &nbsp;&nbsp;Fournisseurs
                                </CDropdownItem>
                                <CDropdownItem href="#" onClick={handlePageDiscount}>
                                    <CIcon type="submit" className="icon-info-user" icon={icon.cilAppsSettings} />
                                    &nbsp;&nbsp;Rémises
                                </CDropdownItem>
                                <CDropdownItem href="#" onClick={handlePageCoupon}>
                                    <CIcon type="submit" className="icon-info-user" icon={icon.cilQrCode} />
                                    &nbsp;&nbsp;Créer un bon d'achat
                                </CDropdownItem>
                                <CDropdownDivider />
                                <CDropdownItem href="#" onClick={handlePageFacture}>
                                    <CIcon type="submit" className="icon-info-user" icon={icon.cilListRich} />
                                    &nbsp;&nbsp;Factures
                                </CDropdownItem>
                                <CDropdownDivider />
                                <CDropdownItem href='#' onClick={logoutUser}>
                                    <CIcon type="submit" className="icon-info-user" icon={icon.cilAccountLogout} />
                                    &nbsp;&nbsp;Déconnexion
                                </CDropdownItem>
                            </CDropdownMenu>
                        </CDropdown>
                    </CNavbarNav>
                </CCollapse>
                <strong><CIcon className="icon-default" icon={icon.cilCalendar} /> {currDate}</strong>
            </CContainer>
        </CNavbar>
    )
};

export default NavbarBackoffice;
