import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

const NotFoundView = () => {
  return (
    <Container className="mt-5">
      <Row>
        <Col md={12} className="text-center">
          <h1 className="display-4">404 - Page Not Found</h1>
          <p className="lead">The requested page could not be found.</p>
        </Col>
      </Row>
    </Container>
  );
};

export default NotFoundView;