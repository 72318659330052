import React, { useState, useEffect } from 'react';

import { CChart } from '@coreui/react-chartjs'

import { Footer, NavbarBackoffice, LoadingSpinner } from '../../components';
import {
  CContainer,
  CRow,
  CCol,
  CFormSelect,
} from '@coreui/bootstrap-react';

import axios from 'axios';

import jwt_decode from 'jwt-decode';

import './style.css';

const DashboardView = () => {
  const BASE_URL = 'https://pi.backend.area.mg';
  const [shopDetails, setShopDetails] = useState(null);

  // Assuming you have stored the access token in a variable called `accessToken`
  const decodedToken = jwt_decode(localStorage.getItem('authTokens'));
  const username = decodedToken.username;

  // Initialize default frequency to weekly frequencyStats
  const [frequency, setFrequency] = useState(1);
  const [frequencyStats, setFrequencyStats] = useState(1);
  // Set to true if default value
  const [iSDataDefault, setISDataDefault] = useState(true);
  const labelMonthly = ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin",
    "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"];
  const labelWeekly = ["Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi", "Dimanche"];
  const [label, setLabel] = useState(labelWeekly);

  const [weeklyQuantityOrderItem, setWeeklyQuantityOrderItem] = useState();
  const [monthlyQuantityOrderItem, setMonthlyQuantityOrderItem] = useState();
  // Data quantity OrderItem
  const [dataQtOrderItem, setDataQtOrderItem] = useState();

  const [weeklyVolumeOrderItem, setWeeklyVolumeOrderItem] = useState();
  const [prevWeeklyVolumeOrderItem, setPrevWeeklyVolumeOrderItem] = useState();
  const [monthlyVolumeOrderItem, setMonthlyVolumeOrderItem] = useState();
  const [prevMonthlyVolumeOrderItem, setPrevMonthlyVolumeOrderItem] = useState();
  // Data volume OrderItem
  const [dataVolumeOrderItem, setDataVolumeOrderItem] = useState();
  const [prevDataVolumeOrderItem, setPrevDataVolumeOrderItem] = useState();

  // Data earnings
  const [weekEarningsData, setWeekEarningsData] = useState();
  const [yearEarningsData, setYearEarningsData] = useState();
  const [prevWeekEarningsData, setPrevWeekEarningsData] = useState();
  const [prevYearEarningsData, setPrevYearEarningsData] = useState();
  const [earningsData, setEarningsData] = useState();
  const [prevEarningsData, setPrevEarningsData] = useState();

  // Data earnings for each categories
  const [dayEarningsByCategoryData, setDayEarningsByCategoryData] = useState([]);
  const [weekEarningsByCategoryData, setWeekEarningsByCategoryData] = useState([]);
  const [monthEarningsByCategoryData, setMonthEarningsByCategoryData] = useState([]);
  const [yearEarningsByCategoryData, setYearEarningsByCategoryData] = useState([]);
  const [lastFiveYearEarningsByCategoryData, setLastFiveYearEarningsByCategoryData] = useState([]);
  const [earningsByCategoryData, setEarningsByCategoryData] = useState([]);

  // list of category active
  const [listActiveCategory, setListActiveCategory] = useState([]);
  const [backgroundColors, setBackgroundColors] = useState([]);

  // Data expenses
  const [weekExpensesData, setWeekExpensesData] = useState();
  const [yearExpensesData, setYearExpensesData] = useState();
  const [prevWeekExpensesData, setPrevWeekExpensesData] = useState();
  const [prevYearExpensesData, setPrevYearExpensesData] = useState();
  const [expensesData, setExpensesData] = useState();
  const [prevExpensesData, setPrevExpensesData] = useState();

  // Data order item total amount summary for each categories
  const [dayTotalAmountByCategoryData, setDayTotalAmountByCategoryData] = useState([]);
  const [weekTotalAmountByCategoryData, setWeekTotalAmountByCategoryData] = useState([]);
  const [monthTotalAmountByCategoryData, setMonthTotalAmountByCategoryData] = useState([]);
  const [yearTotalAmountByCategoryData, setYearTotalAmountByCategoryData] = useState([]);
  const [lastFiveYearTotalAmountByCategoryData, setLastFiveYearTotalAmountByCategoryData] = useState([]);
  const [totalAmountByCategoryData, setTotalAmountByCategoryData] = useState([]);

  // Data order item count summary for each categories
  const [dayCountByCategoryData, setDayCountByCategoryData] = useState([]);
  const [weekCountByCategoryData, setWeekCountByCategoryData] = useState([]);
  const [monthCountByCategoryData, setMonthCountByCategoryData] = useState([]);
  const [yearCountByCategoryData, setYearCountByCategoryData] = useState([]);
  const [lastFiveYearCountByCategoryData, setLastFiveYearCountByCategoryData] = useState([]);
  const [countByCategoryData, setCountByCategoryData] = useState([]);

  // Data product summary for each category
  const [countProductAvailableByCategory, setCountProductAvailableByCategory] = useState([]);
  const [totalProductAmountAvailableByCategory, setTotalProductAmountAvailableByCategory] = useState([]);

  // Color List Generator
  const generateUniqueColors = (count) => {
    const colorList = [];

    for (let i = 0; i < count; i++) {
      const color = getRandomColor();
      colorList.push(color);
    }

    return colorList;
  };

  const getRandomColor = () => {
    const letters = '0123456789ABCDEF';
    let color = '#';

    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }

    return color;
  };

  // Function return the staff user with their Username
  const getStaffUserByUsername = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/api/user/${username}/users`);
      return response.data;
    } catch (error) {
      console.log(error);
    };
  }

  // Get shop info
  const getShopDetails = async () => {
    const staff = await getStaffUserByUsername();
    const staff_id = staff.id;
    try {
      const response = await axios.get(`${BASE_URL}/shop/api/shop/owner/${staff_id}/`);
      if (response.data) {
        setShopDetails(response.data);
      }
      return response.data;
    } catch (error) {
      alert("vous n'êtes pas autorisé à accéder à cette page.")
      console.log(error);
    };
  };

  const handleChangeFrequencyTrend = (e) => {
    setISDataDefault(false);
    const value = parseInt(e.target.value, 10);
    setFrequency(value);
    if (value === 1) {
      //Set label of frequency to weekly
      setLabel(labelWeekly);
      // Set summary order data
      setDataQtOrderItem(weeklyQuantityOrderItem);
      setDataVolumeOrderItem(weeklyVolumeOrderItem);
      setPrevDataVolumeOrderItem(prevWeeklyVolumeOrderItem);
      // Set summary earnings data
      setEarningsData(weekEarningsData);
      setPrevEarningsData(prevWeekEarningsData);
      // Set summary of expenses data
      setExpensesData(weekExpensesData);
      setPrevExpensesData(prevWeekExpensesData);
    } else {
      //Set label of frequency to monthly
      setLabel(labelMonthly);
      setDataQtOrderItem(monthlyQuantityOrderItem);
      setDataVolumeOrderItem(monthlyVolumeOrderItem);
      setPrevDataVolumeOrderItem(prevMonthlyVolumeOrderItem);
      setEarningsData(yearEarningsData);
      setPrevEarningsData(prevYearEarningsData);
      setExpensesData(yearExpensesData);
      setPrevExpensesData(prevYearExpensesData);
    }
  };

  const handleChangeFrequencyStats = (e) => {
    setISDataDefault(false);
    const value = parseInt(e.target.value, 10);
    setFrequencyStats(value);

    // Use a switch statement for better readability
    switch (value) {
      case 1:
        setEarningsByCategoryData(dayEarningsByCategoryData);
        setTotalAmountByCategoryData(dayTotalAmountByCategoryData);
        setCountByCategoryData(dayCountByCategoryData);
        break;
      case 2:
        setEarningsByCategoryData(weekEarningsByCategoryData);
        setTotalAmountByCategoryData(weekTotalAmountByCategoryData);
        setCountByCategoryData(weekCountByCategoryData);
        break;
      case 3:
        setEarningsByCategoryData(monthEarningsByCategoryData);
        setTotalAmountByCategoryData(monthTotalAmountByCategoryData);
        setCountByCategoryData(monthCountByCategoryData);
        break;
      case 4:
        setEarningsByCategoryData(yearEarningsByCategoryData);
        setTotalAmountByCategoryData(yearTotalAmountByCategoryData);
        setCountByCategoryData(yearCountByCategoryData);
        break;
      case 5:
        setEarningsByCategoryData(lastFiveYearEarningsByCategoryData);
        setTotalAmountByCategoryData(lastFiveYearTotalAmountByCategoryData);
        setCountByCategoryData(lastFiveYearCountByCategoryData);
        break;
      default:
        // Handle unknown value or provide a default case
        break;
    }
  };


  useEffect(() => {
    async function fetchData() {
      const shop = await getShopDetails();
      // Fetch orders items data
      try {
        const response = await axios.get(`${BASE_URL}/shop/api/order-item-summary/${shop.id}/`);
        const dataSummary = response.data;
        if (dataSummary) {
          // set current period
          setWeeklyQuantityOrderItem(dataSummary.weekly_quantity_data);
          setMonthlyQuantityOrderItem(dataSummary.yearly_quantity_data);
          setWeeklyVolumeOrderItem(dataSummary.weekly_volume_data);
          setMonthlyVolumeOrderItem(dataSummary.yearly_volume_data);
          // set precedent period
          setPrevWeeklyVolumeOrderItem(dataSummary.prev_weekly_volume_data);
          setPrevMonthlyVolumeOrderItem(dataSummary.prev_yearly_volume_data);


          if (iSDataDefault) {
            // Initiation of data
            setDataQtOrderItem(dataSummary.weekly_quantity_data);
            setDataVolumeOrderItem(dataSummary.weekly_volume_data);
            setPrevDataVolumeOrderItem(dataSummary.prev_weekly_volume_data);
          }
        }
      } catch (error) {
        console.log(error);
      };

      // Fetch earnings data
      try {
        const response = await axios.get(`${BASE_URL}/shop/api/earnings-summary/${shop.id}/`);
        const dataEarningsSummary = response.data;
        if (dataEarningsSummary) {
          // set current period
          setWeekEarningsData(dataEarningsSummary.week_earnings_data);
          setYearEarningsData(dataEarningsSummary.year_earnings_data);
          // set precedent period
          setPrevWeekEarningsData(dataEarningsSummary.prev_week_earnings_data);
          setPrevYearEarningsData(dataEarningsSummary.prev_year_earnings_data);

          if (iSDataDefault) {
            // Initiation of data
            setEarningsData(dataEarningsSummary.week_earnings_data);
            setPrevEarningsData(dataEarningsSummary.prev_week_earnings_data);
          }
        }
      } catch (error) {
        console.log(error);
      };

      // Fetch expenses data
      try {
        const response = await axios.get(`${BASE_URL}/shop/api/expenses-summary/${shop.id}/`);
        const dataExpensesSummary = response.data;
        if (dataExpensesSummary) {
          // set current period
          setWeekExpensesData(dataExpensesSummary.week_expenses_data);
          setYearExpensesData(dataExpensesSummary.year_expenses_data);
          // set precedent period
          setPrevWeekExpensesData(dataExpensesSummary.prev_week_expenses_data);
          setPrevYearExpensesData(dataExpensesSummary.prev_year_expenses_data);

          if (iSDataDefault) {
            // Initiation of data
            setExpensesData(dataExpensesSummary.week_expenses_data);
            setPrevExpensesData(dataExpensesSummary.prev_week_expenses_data);
          }
        }
      } catch (error) {
        console.log(error);
      };

      // Fetch earnings data filtered by category
      try {
        const response = await axios.get(`${BASE_URL}/shop/api/earnings-summary-by-category/${shop.id}/${shop.type_shop.id}/`);
        const dataEarningsSummaryByCat = response.data;
        if (dataEarningsSummaryByCat) {
          // list of actif category for shop with type shop
          setListActiveCategory(dataEarningsSummaryByCat.categories);
          // List color generator with length of categories
          setBackgroundColors(generateUniqueColors(dataEarningsSummaryByCat.categories.length));
          // Earnings
          setDayEarningsByCategoryData(dataEarningsSummaryByCat.daily_earnings_data);
          setWeekEarningsByCategoryData(dataEarningsSummaryByCat.weekly_earnings_data);
          setMonthEarningsByCategoryData(dataEarningsSummaryByCat.monthly_earnings_data);
          setYearEarningsByCategoryData(dataEarningsSummaryByCat.yearly_earnings_data);
          setYearEarningsByCategoryData(dataEarningsSummaryByCat.yearly_earnings_data);
          setLastFiveYearEarningsByCategoryData(dataEarningsSummaryByCat.last_five_year_earnings_data);

          if (iSDataDefault) {
            // Initiation of data
            setEarningsByCategoryData(dataEarningsSummaryByCat.daily_earnings_data);
          }
        }
      } catch (error) {
        console.log(error);
      };

      // Fetch order item data filtered by category
      try {
        const response = await axios.get(`${BASE_URL}/shop/api/order-item-summary-by-category/${shop.id}/${shop.type_shop.id}/`);
        const dataOrderItemSummaryByCat = response.data;
        if (dataOrderItemSummaryByCat) {
          // Amount
          setDayTotalAmountByCategoryData(dataOrderItemSummaryByCat.daily_order_item_total_amount_data);
          setWeekTotalAmountByCategoryData(dataOrderItemSummaryByCat.weekly_order_item_total_amount_data);
          setMonthTotalAmountByCategoryData(dataOrderItemSummaryByCat.monthly_order_item_total_amount_data);
          setYearTotalAmountByCategoryData(dataOrderItemSummaryByCat.yearly_order_item_total_amount_data);
          setLastFiveYearTotalAmountByCategoryData(dataOrderItemSummaryByCat.last_five_year_order_item_total_amount_data);

          // Count
          setDayCountByCategoryData(dataOrderItemSummaryByCat.daily_order_item_count_data);
          setWeekCountByCategoryData(dataOrderItemSummaryByCat.weekly_order_item_count_data);
          setMonthCountByCategoryData(dataOrderItemSummaryByCat.monthly_order_item_count_data);
          setYearCountByCategoryData(dataOrderItemSummaryByCat.yearly_order_item_count_data);
          setLastFiveYearCountByCategoryData(dataOrderItemSummaryByCat.last_five_year_order_item_count_data);

          if (iSDataDefault) {
            // Initiation of data
            setCountByCategoryData(dataOrderItemSummaryByCat.daily_order_item_count_data);
            setTotalAmountByCategoryData(dataOrderItemSummaryByCat.daily_order_item_total_amount_data);
            setISDataDefault(false);
          }
        }
      } catch (error) {
        console.log(error);
      };

      // Fetch product data filtered by category
      try {
        const response = await axios.get(`${BASE_URL}/shop/api/product-summary-by-category/${shop.id}/${shop.type_shop.id}/`);
        const dataProductSummaryByCat = response.data;
        if (dataProductSummaryByCat) {
          // Amount
          setCountProductAvailableByCategory(dataProductSummaryByCat.count_product_available_by_category_data);

          // Count
          setTotalProductAmountAvailableByCategory(dataProductSummaryByCat.total_product_amount_available_by_category_data);
        }
      } catch (error) {
        console.log(error);
      };
    };
    fetchData();


  }, []);

  return (
    (shopDetails ?
      <div>
        <NavbarBackoffice />
        <CContainer className="mt-3">
          <CRow>
            <CCol md={9} xs={12} className="custom-select2">
              <h6 className='custom_doughnut'>Choisir une fréquence :</h6>
            </CCol>
            <CCol md={3} xs={12} className="text-center">
              <CFormSelect
                aria-label="Select option"
                size="sm"
                value={frequency}
                onChange={handleChangeFrequencyTrend}
                options={[
                  { label: 'Hebdomadaire', value: 1 },
                  { label: 'Annuelle', value: 2 },
                ]}
              />
            </CCol>
          </CRow>
          <hr />
          <CRow>
            <CCol md={4} xs={12} className="text-center">
              <h6>Nombre des produits vendus</h6>
              <CChart
                type="bar"
                data={{
                  labels: label,
                  datasets: [
                    {
                      label: 'Nombre des produits vendus',
                      backgroundColor: '#456e93',
                      data: dataQtOrderItem,
                    },
                  ],
                }}
                labels="months"
                options={{
                  plugins: {
                    legend: {
                      labels: {
                        color: '#456e93',
                      }
                    }
                  },
                  scales: {
                    x: {
                      grid: {
                        color: '#7f7f7f',
                      },
                      ticks: {
                        color: '#7f7f7f',
                      },
                    },
                    y: {
                      grid: {
                        color: '#7f7f7f',
                      },
                      ticks: {
                        color: '#7f7f7f',
                      },
                    },
                  },
                }}
              />
            </CCol>
            <CCol md={4} xs={12} className="text-center">
              <h6>Tendance des Recettes </h6>
              <CChart
                type="line"
                data={{
                  labels: label,
                  datasets: [
                    {
                      label: "Actual période",
                      backgroundColor: "#3b74b9",
                      borderColor: "#3b74b9",
                      pointBackgroundColor: "#3b74b9",
                      pointBorderColor: "#fff",
                      data: dataVolumeOrderItem
                    },
                    {
                      label: "Précedent période",
                      backgroundColor: "#f8a91c",
                      borderColor: "#f8a91c",
                      pointBackgroundColor: "#f8a91c",
                      pointBorderColor: "#fff",
                      data: prevDataVolumeOrderItem
                    },
                  ],
                }}
                options={{
                  plugins: {
                    legend: {
                      labels: {
                        color: '#3b74b9',
                      }
                    }
                  },
                  scales: {
                    x: {
                      grid: {
                        color: '#7f7f7f',
                      },
                      ticks: {
                        color: '#7f7f7f',
                      },
                    },
                    y: {
                      grid: {
                        color: '#7f7f7f',
                      },
                      ticks: {
                        color: '#7f7f7f',
                      },
                    },
                  },
                }}
              />
            </CCol>
            <CCol md={4} xs={12} className="text-center">
              <h6>Total Recettes</h6>
              <CChart
                type="bar"
                data={{
                  labels: label,
                  datasets: [
                    {
                      label: 'Total des ventes',
                      backgroundColor: '#456e93',
                      data: dataVolumeOrderItem,
                    },
                  ],
                }}
                labels="months"
                options={{
                  plugins: {
                    legend: {
                      labels: {
                        color: '#456e93',
                      }
                    }
                  },
                  scales: {
                    x: {
                      grid: {
                        color: '#7f7f7f',
                      },
                      ticks: {
                        color: '#7f7f7f',
                      },
                    },
                    y: {
                      grid: {
                        color: '#7f7f7f',
                      },
                      ticks: {
                        color: '#7f7f7f',
                      },
                    },
                  },
                }}
              />
            </CCol>
            <hr />
            <br /> <br />
            <CCol md={4} xs={12} className="text-center">
              <h6>Tendance des Revenus</h6>
              <CChart
                type="line"
                data={{
                  labels: label,
                  datasets: [
                    {
                      label: "Actual période",
                      backgroundColor: "#41B883",
                      borderColor: "#41B883",
                      pointBackgroundColor: "#41B883",
                      pointBorderColor: "#fff",
                      data: earningsData
                    },
                    {
                      label: "Précedent période",
                      backgroundColor: "#f8a91c",
                      borderColor: "#f8a91c",
                      pointBackgroundColor: "#f8a91c",
                      pointBorderColor: "#fff",
                      data: prevEarningsData
                    },
                  ],
                }}
                options={{
                  plugins: {
                    legend: {
                      labels: {
                        color: '#3b74b9',
                      }
                    }
                  },
                  scales: {
                    x: {
                      grid: {
                        color: '#7f7f7f',
                      },
                      ticks: {
                        color: '#7f7f7f',
                      },
                    },
                    y: {
                      grid: {
                        color: '#7f7f7f',
                      },
                      ticks: {
                        color: '#7f7f7f',
                      },
                    },
                  },
                }}
              />
            </CCol>
            <CCol md={4} xs={12} className="text-center">
              <h6>Tendance des dépenses</h6>
              <CChart
                type="line"
                data={{
                  labels: label,
                  datasets: [
                    {
                      label: "Actual période",
                      backgroundColor: "#FF0000",
                      borderColor: "#FF0000",
                      pointBackgroundColor: "#FF0000",
                      pointBorderColor: "#fff",
                      data: expensesData
                    },
                    {
                      label: "Précedent période",
                      backgroundColor: "#ffb2b2",
                      borderColor: "#ffb2b2",
                      pointBackgroundColor: "#ffb2b2",
                      pointBorderColor: "#fff",
                      data: prevExpensesData
                    },
                  ],
                }}
                options={{
                  plugins: {
                    legend: {
                      labels: {
                        color: '#3b74b9',
                      }
                    }
                  },
                  scales: {
                    x: {
                      grid: {
                        color: '#7f7f7f',
                      },
                      ticks: {
                        color: '#7f7f7f',
                      },
                    },
                    y: {
                      grid: {
                        color: '#7f7f7f',
                      },
                      ticks: {
                        color: '#7f7f7f',
                      },
                    },
                  },
                }}
              />
            </CCol>
            {/* <CCol md={4} xs={12} className="text-center">
              <h6>Revenues <br />  </h6>
              <CChart
                type="bar"
                data={{
                  labels: label,
                  datasets: [
                    {
                      label: 'Revenus',
                      backgroundColor: '#41B883',
                      data: earningsData
                    },
                  ],
                }}
                labels="months"
                options={{
                  plugins: {
                    legend: {
                      labels: {
                        color: '#355E3B',
                      }
                    }
                  },
                  scales: {
                    x: {
                      grid: {
                        color: '#7f7f7f',
                      },
                      ticks: {
                        color: '#7f7f7f',
                      },
                    },
                    y: {
                      grid: {
                        color: '#7f7f7f',
                      },
                      ticks: {
                        color: '#7f7f7f',
                      },
                    },
                  },
                }}
              />
            </CCol> */}
            <CCol md={4} xs={12} className="text-center">
              <h6>Revenus vs Dépenses </h6>
              <CChart
                type="line"
                data={{
                  labels: label,
                  datasets: [
                    {
                      label: "Revenus",
                      backgroundColor: "#41B883",
                      borderColor: "#41B883",
                      pointBackgroundColor: "#41B883",
                      pointBorderColor: "#fff",
                      data: earningsData
                    },
                    {
                      label: "Dépenses",
                      backgroundColor: "#FF0000",
                      borderColor: "#FF0000",
                      pointBackgroundColor: "#FF0000",
                      pointBorderColor: "#fff",
                      data: expensesData
                    },
                  ],
                }}
                options={{
                  plugins: {
                    legend: {
                      labels: {
                        color: '#3b74b9',
                      }
                    }
                  },
                  scales: {
                    x: {
                      grid: {
                        color: '#7f7f7f',
                      },
                      ticks: {
                        color: '#7f7f7f',
                      },
                    },
                    y: {
                      grid: {
                        color: '#7f7f7f',
                      },
                      ticks: {
                        color: '#7f7f7f',
                      },
                    },
                  },
                }}
              />
            </CCol>
            <br /> <br />
            <hr />
            <br /> <br />
            <h4 className="text-center">Stats des produits par categories</h4>
            <br /> <br />
            <CRow>
              <CCol md={9} xs={12} className="custom-select2">
                <h6 className='custom_doughnut'>Choisir une fréquence :</h6>
              </CCol>
              <CCol md={3} xs={12} className="text-center">
                <CFormSelect
                  aria-label="Select option"
                  size="sm"
                  value={frequencyStats}
                  onChange={handleChangeFrequencyStats}
                  options={[
                    { label: "Aujourd'hui", value: 1 },
                    { label: 'Cette semaine-ci', value: 2 },
                    { label: 'Ce mois-ci', value: 3 },
                    { label: 'Cette année-ci', value: 4 },
                    { label: '5 dernières années', value: 5 }
                  ]}
                />
              </CCol>
              <br /><br />
              <hr />
            </CRow>
            <CRow className='custom-row-chart'>
              <CCol md={4} xs={12} className="text-center">
                <h6 className='custom_doughnut'>Nombre des stocks</h6>
                <CChart
                  type="doughnut"
                  className='custom_doughnut'
                  data={{
                    labels: listActiveCategory,
                    datasets: [
                      {
                        backgroundColor: backgroundColors,
                        data: countProductAvailableByCategory,
                      },
                    ],
                  }}
                  options={{
                    plugins: {
                      legend: {
                        labels: {
                          color: '#7f7f7f',
                        }
                      }
                    },
                  }}
                />
              </CCol>
              <CCol md={4} xs={12} className="text-center">
                <h6 className='custom_doughnut'>Total stocks</h6>
                <CChart
                  type="doughnut"
                  className='custom_doughnut'
                  data={{
                    labels: listActiveCategory,
                    datasets: [
                      {
                        backgroundColor: backgroundColors,
                        data: totalProductAmountAvailableByCategory,
                      },
                    ],
                  }}
                  options={{
                    plugins: {
                      legend: {
                        labels: {
                          color: '#7f7f7f',
                        }
                      }
                    },
                  }}
                />
              </CCol>
              <CCol md={4} xs={12} className="text-center">
                <h6 className='custom_doughnut'>Nbre des produits vendus</h6>
                <CChart
                  type="doughnut"
                  className='custom_doughnut'
                  data={{
                    labels: listActiveCategory,
                    datasets: [
                      {
                        backgroundColor: backgroundColors,
                        data: countByCategoryData,
                      },
                    ],
                  }}
                  options={{
                    plugins: {
                      legend: {
                        labels: {
                          color: '#7f7f7f',
                        }
                      }
                    },
                  }}
                />
              </CCol>
              <CCol md={4} xs={12} className="text-center">
                <h6 className='custom_doughnut'>Recettes</h6>
                <CChart
                  type="doughnut"
                  className='custom_doughnut'
                  data={{
                    labels: listActiveCategory,
                    datasets: [
                      {
                        backgroundColor: backgroundColors,
                        data: totalAmountByCategoryData,
                      },
                    ],
                  }}
                  options={{
                    plugins: {
                      legend: {
                        labels: {
                          color: '#7f7f7f',
                        }
                      }
                    },
                  }}
                />
              </CCol>
              <CCol md={4} xs={12} className="text-center">
                <h6 className='custom_doughnut'>Revenus </h6>
                <CChart
                  className='custom_doughnut'
                  type="doughnut"
                  data={{
                    labels: listActiveCategory,
                    datasets: [
                      {
                        backgroundColor: backgroundColors,
                        data: earningsByCategoryData,
                      },
                    ],
                  }}
                  options={{
                    plugins: {
                      legend: {
                        labels: {
                          color: '#7f7f7f',
                        }
                      }
                    },
                  }}
                />
              </CCol>
            </CRow>
            <br /><br />
          </CRow>
          <br /> <br />
          <hr />
        </CContainer>
        <Footer />
      </div> : <div><NavbarBackoffice /><LoadingSpinner /></div>
    )
  );
};

export default DashboardView;
