import React from "react";

import { CFooter, CLink } from '@coreui/react'

const vars = {
  'backgroundColor' : '#e5e5e54a',
  'display': 'flex',
  'justifyContent' : 'center',
  'position': 'fixed', // Set the footer position to fixed
  'bottom': '0', // Stick the footer to the bottom of the viewport
  'left': '0', // Optionally set the horizontal alignment
  'right': '0', // Optionally set the horizontal alignment
  'padding': '15px', // Add some padding to the footer for better visibility
};

const Footer = () => {
  return (
      <CFooter style={vars}>
        <div>
          <CLink color="#3b74b9" href="https://pimg.mg">Pi </CLink>
          <span>&copy; 2023 Unlimited ideas. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        </div>
        <div>
          <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Powered by</span>
          <CLink color="#3b74b9" href="https://pimg.mg">&nbsp; PI Group</CLink>
        </div>
      </CFooter>
  );
};

export default Footer;
