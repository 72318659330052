import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';

import {
    CContainer,
    CCol,
    CRow,
    CTooltip,
    CTable,
    CTableHead,
    CTableRow,
    CTableHeaderCell,
    CTableBody,
    CTableDataCell,
} from '@coreui/bootstrap-react';

import { NavbarBackoffice, Footer } from '../../components';

import { NumberFormat, FormatDate, LoadingSpinner } from '../../components';
import CIcon from '@coreui/icons-react';
import * as icon from '@coreui/icons';

import axios from 'axios';
import "./style.css";

import jwt_decode from 'jwt-decode';

const ListInvoice = () => {
    const BASE_URL = 'https://pi.backend.area.mg';
    const navigate = useNavigate();
    const [shopDetails, setShopDetails] = useState(null);

    // Get list of bill pending 
    const [invoices, setInvoices] = useState([]);
    // Get the list of provider of payment
    const getListOfInvoices30days = async () => {
        const shop = await getShopDetails();
        try {
            const response = await axios.get(`${BASE_URL}/shop/api/invoices/by_shop/${shop.id}/`);
            const invoices = response.data;

            return invoices;

        } catch (error) {
            console.log(error);
        };
    };

    // Assuming you have stored the access token in a variable called `accessToken`
    const decodedToken = jwt_decode(localStorage.getItem('authTokens'));
    const username = decodedToken.username;


    // Function return the staff user with their Username
    const getStaffUserByUsername = async () => {
        try {
            const response = await axios.get(`${BASE_URL}/api/user/${username}/users`);
            return response.data;
        } catch (error) {
            console.log(error);
            alert("Vous ne pouvez pas accéder à cette page.");
        };
    }

    // Get shop info
    const getShopDetails = async () => {
        const staff = await getStaffUserByUsername();
        const staff_id = staff.id;
        try {
            const response = await axios.get(`${BASE_URL}/shop/api/shop/owner/${staff_id}/`);
            if (response.data) {
                setShopDetails(response.data);
            }
            return response.data;
        } catch (error) {
            console.log(error);
        };
    };

    // Function to convert data to CSV format
    function convertToCSV(data) {
        const header = [
            'Num_Commande', 'Type_Commande', 'Code_Caisse', 'Num_facture', 'Date_facture', 'Date_echeance', 'Code_Si_Coupon', 'Reste_Paye_Si_Coupon_Pas_Suffisant',
            'Sous_Total_' + shopDetails.country.currency.symbol, 'Remise_' + shopDetails.country.currency.symbol,
            'tax_' + shopDetails.country.currency.symbol, 'Frais_livraison_' + shopDetails.country.currency.symbol,
            'Total_Montant_Paye_' + shopDetails.country.currency.symbol, 'Etat', 'Validation',
            'Transaction_id', 'Nom_Fournisseur_Paiement', 'Code_Fournisseur_Paiement', 'Type_Fournisseur_Paiement', 'Ext_Transaction_Id',
            'Date_Enregistrement', 'Derniere_Actualisation'
        ];

        const rows = data.map(invoice => [
            invoice.order.id,
            invoice.order.order_type,
            invoice.order.cashier.code,
            invoice.invoice_number,
            invoice.invoice_date,
            invoice.due_date,
            invoice.coupon ? invoice.coupon.code : '',
            invoice.rest_paid_if_coupon_not_enough,
            invoice.subtotal,
            invoice.discount,
            invoice.tax,
            invoice.shipping_cost,
            invoice.total,
            invoice.is_paid ? 'Paye' : 'Non paye',
            invoice.status,
            invoice.payment.transaction_id,
            invoice.payment.payment_provider.name,
            invoice.payment.payment_provider.code_partner,
            invoice.payment.payment_provider.type,
            invoice.payment.transaction_id_third,
            invoice.created_at,
            invoice.updated_at
        ]);

        return [header, ...rows].map(row => row.join(',')).join('\n');
    }

    // Function to download CSV file
    const downloadCSV = async () => {
        const datetime = new Date().toISOString().replace(/:/g, '-');
        const filename = `factures_${datetime}.csv`;

        if (!invoices || invoices.length === 0) {
            alert("Aucune facture trouvée");
            return;
        }

        try {
            const data = convertToCSV(invoices);

            const csvContent = 'data:text/csv;charset=utf-8,' + encodeURIComponent(data);
            const link = document.createElement('a');
            link.setAttribute('href', csvContent);
            link.setAttribute('download', filename);
            link.click();
        } catch (error) {
            console.error("Error exporting data to CSV:", error);
            alert("Une erreur s'est produite lors de l'exportation des données en CSV.");
        }
    };


    useEffect(() => {
        const fetchInvoices = async () => {
            const allInvoices = await getListOfInvoices30days();

            // Filter invoices from the last 30 days based on the 'updated_at' field
            const thirtyDaysAgo = new Date();
            thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);

            const filteredInvoices = allInvoices.filter(
                (invoice) => new Date(invoice.updated_at) >= thirtyDaysAgo
            );

            setInvoices(filteredInvoices);
        };
        getShopDetails();

        const interval = setInterval(() => {
            fetchInvoices();
        }, 2000);

        return () => clearInterval(interval);
    }, [])

    return (
        (shopDetails ?
            <div>
                <NavbarBackoffice />
                <CContainer className="mt-1">
                    <CRow>
                        <CCol md={12} >
                            {invoices.length === 0 ? <h4 className="display-6 text-center">Veuillez patienter pour les mises à jour des factures durant 30 derniers jours......</h4> :
                                <h4 className="display-6 text-center">Factures des 30 derniers jours ( {invoices.length} ) &nbsp;&nbsp;
                                    <CTooltip content="Télécharger dans un fichier csv.">
                                        <CIcon
                                            onClick={downloadCSV}
                                            type="submit"
                                            className="custom-icon-download"
                                            icon={icon.cilCloudDownload}
                                        />
                                    </CTooltip>
                                </h4>}
                            <p className="text-center">Cmd : Numéro commande - Type_Cmd : Type commande - Num_fac : Numéro Facture -
                                Type_Frs_Pay : Type_Fournisseur_Paiement - Ext_Trans_Id : External_Transaction_Id - Dernière_MAJ : Dernière actualisation</p>
                            {invoices &&
                                <CTable color="dark" striped>
                                    <CTableHead>
                                        <CTableRow>
                                            <CTableHeaderCell scope="col">Cmd</CTableHeaderCell>
                                            <CTableHeaderCell scope="col">Type_Cmd</CTableHeaderCell>
                                            <CTableHeaderCell scope="col">Code_Caisse</CTableHeaderCell>
                                            <CTableHeaderCell scope="col">Num_fac</CTableHeaderCell>
                                            <CTableHeaderCell scope="col">Montant_à_payer</CTableHeaderCell>
                                            <CTableHeaderCell scope="col">Etat</CTableHeaderCell>
                                            <CTableHeaderCell scope="col">Validation</CTableHeaderCell>
                                            <CTableHeaderCell scope="col">Type_Frs_Pay</CTableHeaderCell>
                                            <CTableHeaderCell scope="col">Ext_Trans_Id</CTableHeaderCell>
                                            <CTableHeaderCell scope="col">Dernière_MAJ</CTableHeaderCell>
                                            <CTableHeaderCell scope="col">Action</CTableHeaderCell>
                                        </CTableRow>
                                    </CTableHead>
                                    <CTableBody>
                                        {invoices.map((invoice) => (
                                            <CTableRow key={invoice.id} color={(invoice.is_paid && invoice.status === "complete") ? 'light' : 'danger'}>
                                                <CTableHeaderCell scope="row">{invoice.order.id}</CTableHeaderCell>
                                                <CTableDataCell>{invoice.order.order_type}</CTableDataCell>
                                                <CTableDataCell>{invoice.order.cashier.code}</CTableDataCell>
                                                <CTableDataCell>{invoice.invoice_number}</CTableDataCell>
                                                <CTableDataCell>
                                                    {shopDetails.country.currency.symbol} <NumberFormat number={invoice.total} />
                                                </CTableDataCell>
                                                <CTableDataCell>{invoice.is_paid ? 'Payé' : 'Non payé'}</CTableDataCell>
                                                <CTableDataCell>{
                                                    invoice.status === 'complete' ? 'Complete' :
                                                        invoice.status === 'pending' ? 'En attente validation' :
                                                            invoice.status === 'failed' ? 'Echec' :
                                                                invoice.status === 'canceled' ? 'Annulé' : ''
                                                }
                                                </CTableDataCell>
                                                <CTableDataCell>{invoice.payment.payment_provider.type}</CTableDataCell>
                                                <CTableDataCell>{invoice.payment.transaction_id_third}</CTableDataCell>
                                                <CTableDataCell>
                                                    <FormatDate dateString={invoice.updated_at} />
                                                </CTableDataCell>
                                                <CTableDataCell>
                                                    <CTooltip content="Mettre à jour la facture">
                                                        <CIcon
                                                            onClick={() => navigate(`/modifier-facture`, {
                                                                state: {
                                                                    invoiceNumber: invoice.invoice_number,
                                                                }
                                                            })}
                                                            type="submit"
                                                            className="custom-icon-proceed"
                                                            icon={icon.cilChevronDoubleRight}
                                                        />
                                                    </CTooltip>
                                                </CTableDataCell>
                                            </CTableRow>
                                        ))}

                                    </CTableBody>
                                </CTable>
                            }
                        </CCol>
                    </CRow>
                </CContainer>
                <br /><br />
                <Footer />
            </div> : <div><NavbarBackoffice /><LoadingSpinner /></div>
        )
    );
};

export default ListInvoice;