import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';

import {
    CContainer,
    CCol,
    CRow,
    CTable,
    CTableHead,
    CTableRow,
    CTableHeaderCell,
    CTableBody,
    CTableDataCell,
    CTooltip,
} from '@coreui/bootstrap-react';

import { NavbarBackoffice, Footer, FormatDate, NumberFormat, LoadingSpinner } from '../../components';

import axios from 'axios';
import "./style.css";

import jwt_decode from 'jwt-decode';

import CIcon from '@coreui/icons-react';
import * as icon from '@coreui/icons';

const ListCoupon = () => {
    const BASE_URL = 'https://pi.backend.area.mg';
    const [shopDetails, setShopDetails] = useState(null);

    const navigate = useNavigate();

    const [coupons, setCoupons] = useState([]);
    const getListOfCoupons = async () => {
        const shop = await getShopDetails();
        const shop_id = shop.id;
        try {
            const response = await axios.get(`${BASE_URL}/shop/api/coupons/${shop_id}/list/`);
            const coup = response.data;
            setCoupons(coup);
            return coup;

        } catch (error) {
            console.log(error);
        };
    };

    // Assuming you have stored the access token in a variable called `accessToken`
    const decodedToken = jwt_decode(localStorage.getItem('authTokens'));
    const username = decodedToken.username;


    // Function return the staff user with their Username
    const getStaffUserByUsername = async () => {
        try {
            const response = await axios.get(`${BASE_URL}/api/user/${username}/users`);
            return response.data;
        } catch (error) {
            console.log(error);
            alert("Vous ne pouvez pas accéder à cette page.");
        };
    }

    // Get shop info
    const getShopDetails = async () => {
        const staff = await getStaffUserByUsername();
        const staff_id = staff.id;
        try {
            const response = await axios.get(`${BASE_URL}/shop/api/shop/owner/${staff_id}/`);
            if (response.data) {
                setShopDetails(response.data);
            }
            return response.data;
        } catch (error) {
            console.log(error);
        };
    };

    // Create a Function to Convert Data to CSV
    const convertToCSV = async (data) => {
        const header = [
            'Code', 'Coupon_type','Date_Debut', 'Date_Fin', 'Montant_'+shopDetails.country.currency.symbol, 'Etat', 'Produit', 'Usage_limit','Deja_utilise', 'Date_Enregistrement',
            'Derniere_Actualisation'
        ];

        const rows = data.map(coup => [
            coup.code,
            coup.coupon_type.name,
            coup.start_date,
            coup.end_date,
            coup.amount,
            coup.is_active ? 'Actif' : 'Non actif',
            coup.usage_limit,
            coup.used,
            coup.created_at,
            coup.updated_at
        ]);

        return [header, ...rows].map(row => row.join(',')).join('\n');
    }

    const addCoupon = () => {
        navigate('/ajouter-coupon');
    }

    // Function to download CSV file
    const downloadCSV = async () => {
        const datetime = new Date().toISOString().replace(/:/g, '-');
        const filename = `Coupons_${datetime}.csv`;

        if (!coupons || coupons.length === 0) {
            alert("Aucun coupon trouvé");
            return;
        }

        try {
            const data = await convertToCSV(coupons);

            const csvContent = 'data:text/csv;charset=utf-8,' + encodeURIComponent(data);
            const link = document.createElement('a');
            link.setAttribute('href', csvContent);
            link.setAttribute('download', filename);
            link.click();
        } catch (error) {
            console.error("Error exporting data to CSV:", error);
            alert("Une erreur s'est produite lors de l'exportation des données en CSV.");
        }
    };

    useEffect(() => {
        const fetchCoupons = async () => {
            await getListOfCoupons();
        };
        getShopDetails();

        const interval = setInterval(() => {
            fetchCoupons();
        }, 5000);

        return () => clearInterval(interval);


    }, [])

    return (
        (shopDetails ?
            <div>
                <NavbarBackoffice />
                <CContainer className="mt-1">
                    <CRow>
                        <CCol md={12}>
                            {coupons.length === 0 ? <h4 className="display-6 text-center">Merci de patienter pour les mises à jour des coupons ...
                                <CTooltip content="Configurer une remise">
                                    <CIcon
                                        onClick={addCoupon}
                                        type="submit"
                                        className="custom-icon-add"
                                        icon={icon.cilPlus}
                                    />
                                </CTooltip>
                            </h4> :
                                <h4 className="display-6 text-center">Tous les coupons existent dans la base ( {coupons.length} ) &nbsp;&nbsp;
                                    <CTooltip content="Télécharger dans un fichier csv.">
                                        <CIcon
                                            onClick={downloadCSV}
                                            type="submit"
                                            className="custom-icon-download"
                                            icon={icon.cilCloudDownload}
                                        />
                                    </CTooltip>
                                    &nbsp;&nbsp;
                                    <CTooltip content="Ajouter un produit">
                                        <CIcon
                                            onClick={addCoupon}
                                            type="submit"
                                            className="custom-icon-add"
                                            icon={icon.cilPlus}
                                        />
                                    </CTooltip>
                                </h4>}
                            {coupons &&
                                <CTable color="dark" striped>
                                    <CTableHead>
                                        <CTableRow>
                                            <CTableHeaderCell scope="col">Code</CTableHeaderCell>
                                            <CTableHeaderCell scope="col">Coupon_type</CTableHeaderCell>
                                            <CTableHeaderCell scope="col">Date_Debut</CTableHeaderCell>
                                            <CTableHeaderCell scope="col">Date_Fin</CTableHeaderCell>
                                            <CTableHeaderCell scope="col">Montant</CTableHeaderCell>
                                            <CTableHeaderCell scope="col">Etat</CTableHeaderCell>
                                            <CTableHeaderCell scope="col">Usage limit</CTableHeaderCell>
                                            <CTableHeaderCell scope="col">Déjà Utilisé</CTableHeaderCell>
                                            <CTableHeaderCell scope="col">Date_MAJ</CTableHeaderCell>
                                            <CTableHeaderCell scope="col">Actions</CTableHeaderCell>
                                        </CTableRow>
                                    </CTableHead>
                                    <CTableBody>
                                        {coupons.map((coup) => (
                                            <CTableRow key={coup.id} color={coup.is_active ? 'light' : 'danger'}>
                                                <CTableHeaderCell scope="row">{coup.code}</CTableHeaderCell>
                                                <CTableDataCell>{coup.coupon_type.name}</CTableDataCell>
                                                <CTableDataCell><FormatDate dateString={coup.start_date} /></CTableDataCell>
                                                <CTableDataCell><FormatDate dateString={coup.end_date} /></CTableDataCell>
                                                <CTableDataCell>{shopDetails.country.currency.symbol} <NumberFormat number={coup.amount} /></CTableDataCell>
                                                <CTableDataCell>{coup.is_active ? 'Actif' : 'Non actif'}</CTableDataCell>
                                                <CTableDataCell>{coup.usage_limit}</CTableDataCell>
                                                <CTableDataCell>{coup.used}</CTableDataCell>
                                                <CTableDataCell>
                                                    <FormatDate dateString={coup.updated_at} />
                                                </CTableDataCell>
                                                <CTableDataCell>
                                                    <CTooltip content="Modifier">
                                                        <CIcon
                                                            type="submit"
                                                            onClick={() => navigate(`/modifier-coupon`, {
                                                                state: {
                                                                    couponID: coup.id,
                                                                }
                                                            })}
                                                            className="custom-icon-edit"
                                                            icon={icon.cilColorBorder}
                                                        />
                                                    </CTooltip>
                                                </CTableDataCell>
                                            </CTableRow>
                                        ))}

                                    </CTableBody>
                                </CTable>
                            }
                        </CCol>
                    </CRow>
                </CContainer>
                <br /><br />
                <Footer />
            </div> : <div><NavbarBackoffice /><LoadingSpinner /></div>
        )
    );
};

export default ListCoupon;