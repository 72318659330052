import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import {
    CContainer, CRow, CCol, CForm,
    CFormLabel, CFormInput, CButton, CFormSelect
} from '@coreui/bootstrap-react';

import { NavbarBackoffice, Footer, LoadingSpinner } from '../../components';

import axios from 'axios';

import jwt_decode from 'jwt-decode';

import CIcon from '@coreui/icons-react';
import * as icon from '@coreui/icons';

const EditDiscount = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const BASE_URL = 'https://pi.backend.area.mg';
    const [shopDetails, setShopDetails] = useState(null);
    const [discountTypes, setDiscountTypes] = useState([]);
    const [products, setProducts] = useState([]);

    // Assuming you have stored the access token in a variable called `accessToken`
    const decodedToken = jwt_decode(localStorage.getItem('authTokens'));
    const username = decodedToken.username;

    // Perform a conditional check to access the discountID property
    const discountID = (location.state ? location.state.discountID : '');

    // Define a loading state
    const [isLoading, setIsLoading] = useState(false);

    const [discount, setDiscount] = useState({});
    const [formData, setFormData] = useState({
        discount_type_id: '',
        discount_type: '',
        usage_code: '',
        value_type: '',
        value: '',
        start_date: '',
        end_date: '',
        is_active: '',
        product_id: '',
        product: '',
    });

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData({
            ...formData,
            [name]: type === 'checkbox' ? checked : value,
        });
    };

    const handleSubmit = async (e) => {
        // Set loading to true when starting the function
        setIsLoading(true);
        const shop_id = shopDetails.id;
        e.preventDefault();
        // Send a PUT request to update the discount
        axios.put(`${BASE_URL}/shop/api/discount/${discountID}/${shop_id}/edit/`, formData)
            .then((response) => {
                console.log('discount updated successfully:', response.data);
                // Handle success or redirect to another page
                // Redirect to another page
                alert('Modification réussie!');
                navigate('/remises');
            })
            .catch((error) => {
                console.log('Error updating discount:', error);
                alert('Erreur dans la modification de la remise .Veuillez réessayer');
            })
            .finally(() => {
                // Set loading to false when the function is done, regardless of success or failure
                setIsLoading(false);
            });
    }

    // Function return the staff user with their Username
    const getStaffUserByUsername = async () => {
        try {
            const response = await axios.get(`${BASE_URL}/api/user/${username}/users`);
            return response.data;
        } catch (error) {
            console.log(error);
        };
    }

    // Get shop info
    const getShopDetails = async () => {
        const staff = await getStaffUserByUsername();
        const staff_id = staff.id;
        try {
            const response = await axios.get(`${BASE_URL}/shop/api/shop/owner/${staff_id}/`);
            if (response.data) {
                setShopDetails(response.data);
            }
            return response.data;
        } catch (error) {
            alert("vous n'êtes pas autorisé à accéder à cette page.")
            console.log(error);
        };
    }
    useEffect(() => {
        // Update the form data when the discount details are fetched
        setFormData({
            discount_type_id: discount.discount_type ? discount.discount_type.id : '',
            discount_type: discount.discount_type || '',
            usage_code: discount.usage_code || '',
            value_type: discount.value_type || '',
            value: discount.value || '',
            start_date: discount.start_date || '',
            end_date: discount.end_date || '',
            is_active: discount.is_active || '',
            product_id: discount.product ? discount.product.id : '',
            product: discount.product || '',
        });
    }, [discount]);


    useEffect(() => {
        async function fetchData() {
            const shop = await getShopDetails();
            // Fetch available discountTypes from the API
            axios.get(`${BASE_URL}/shop/api/discounts-types/`)
                .then((response) => setDiscountTypes(response.data))
                .catch((error) => console.log(error));

            // Fetch available discount from the API
            axios.get(`${BASE_URL}/shop/api/discount/${discountID}/${shop.id}/edit/`)
                .then((response) => setDiscount(response.data))
                .catch((error) => console.log(error));

            // Fetch available products from the API
            axios.get(`${BASE_URL}/shop/api/products/${shop.id}/list/`)
                .then((response) => setProducts(response.data))
                .catch((error) => console.log(error));
        }
        fetchData();

    }, []);

    return (
        (shopDetails ?
            <div>
                <NavbarBackoffice />
                <CContainer className="py-4">
                    <CRow className="justify-content-center">
                        <CCol sm="8" lg="6">
                            <CForm onSubmit={handleSubmit}>
                                {isLoading ? <LoadingSpinner /> :
                                    <>
                                        <CCol xs="auto">
                                            <CFormLabel htmlFor="discount_type">Tye de remise</CFormLabel>
                                            {/* Create a dropdown to select the discount type */}
                                            <CFormSelect id="discount_type" name="discount_type" value={formData.discount_type_id} onChange={handleChange} required>
                                                <option key={formData.discount_type.id} value={formData.discount_type.id}>{formData.discount_type.name}</option>
                                                {discountTypes.map((type) => (
                                                    (type.id !== formData.discount_type_id &&
                                                        <option key={type.id} value={type.id}>{type.name}</option>
                                                    )
                                                ))}
                                            </CFormSelect>
                                        </CCol>
                                        <CCol xs="auto">
                                            <CFormLabel htmlFor="usage_code">Usage_code :</CFormLabel >
                                            <CFormInput type="text" id="usage_code" name="usage_code" value={formData.usage_code} onChange={handleChange} required />
                                        </CCol>
                                        <CCol xs="auto">
                                            <CFormLabel htmlFor="value_type">Value Type :</CFormLabel>
                                            <CFormSelect id="value_type" name="value_type" value={formData.value_type} onChange={handleChange} required>
                                                <option key={formData.value_type} value={formData.value_type}>{formData.value_type}</option>
                                                {formData.value_type === "percent" ? <option value="fixed">Fixed</option> : <option value="percent">Percent</option>}
                                            </CFormSelect>
                                        </CCol>
                                        <CCol xs="auto">
                                            <CFormLabel htmlFor="value">Value :</CFormLabel >
                                            <CFormInput id="number" name="value" value={formData.value} onChange={handleChange} required />
                                        </CCol>
                                        <CCol xs="auto">
                                            <CFormLabel htmlFor="start_date">Date de début</CFormLabel >
                                            <CFormInput type="date" id="start_date" name="start_date" value={formData.start_date} onChange={handleChange} />
                                        </CCol>
                                        <CCol xs="auto">
                                            <CFormLabel htmlFor="end_date">Date de fin</CFormLabel >
                                            <CFormInput type="date" id="end_date" name="end_date" value={formData.end_date} onChange={handleChange} />
                                        </CCol>
                                        <hr />
                                        <CCol xs="auto">
                                            <CFormLabel htmlFor="is_active">Activer : </CFormLabel>
                                            &nbsp;<input type="checkbox" name="is_active" checked={formData.is_active} onChange={handleChange} />
                                        </CCol>
                                        <hr />
                                        <CCol xs="auto">
                                            <CFormLabel htmlFor="product">Selectioner un produit</CFormLabel>
                                            {/* Create a dropdown to select the discount type */}
                                            <CFormSelect id="product" name="product" value={formData.product_id} onChange={handleChange}>
                                                {formData.product !== "" && <option key={formData.product.id} value={formData.product.id}>{formData.product.code_product} -- {formData.product.name}</option>}
                                                <option value=''>Tous les produits</option>
                                                {products.map((prod) => (
                                                    <option key={prod.id} value={prod.id}>{prod.code_product} -- {prod.name}</option>
                                                ))}
                                            </CFormSelect>
                                        </CCol>
                                        <hr />
                                        <CButton type="submit" color="primary">
                                            <CIcon type="submit" className="icon-default-add" icon={icon.cilSave} />
                                            &nbsp;Enregistrer la modification
                                        </CButton>
                                    </>
                                }
                            </CForm>
                        </CCol>
                    </CRow>
                </CContainer>
                <br /><br />
                <Footer />
            </div> : <div><NavbarBackoffice /><LoadingSpinner /></div>
        )
    );
};

export default EditDiscount;
