import React, { useState, useEffect } from 'react';
import {
    CContainer, CRow, CCol, CForm,
    CFormLabel, CFormInput, CButton, CFormSelect
} from '@coreui/bootstrap-react';

import { NavbarBackoffice, Footer, LoadingSpinner } from '../../components';

import axios from 'axios';

import jwt_decode from 'jwt-decode';

import CIcon from '@coreui/icons-react';
import * as icon from '@coreui/icons';

const AddCoupon = () => {
    const BASE_URL = 'https://pi.backend.area.mg';
    const [shopDetails, setShopDetails] = useState(null);
    const [couponTypes, setCouponTypes] = useState([]);

    // Assuming you have stored the access token in a variable called `accessToken`
    const decodedToken = jwt_decode(localStorage.getItem('authTokens'));
    const username = decodedToken.username;

    // Define a loading state
    const [isLoading, setIsLoading] = useState(false);

    // Function return the staff user with their Username
    const getStaffUserByUsername = async () => {
        try {
            const response = await axios.get(`${BASE_URL}/api/user/${username}/users`);
            return response.data;
        } catch (error) {
            console.log(error);
        };
    }

    // Get shop info
    const getShopDetails = async () => {
        const staff = await getStaffUserByUsername();
        const staff_id = staff.id;
        try {
            const response = await axios.get(`${BASE_URL}/shop/api/shop/owner/${staff_id}/`);
            if (response.data) {
                setShopDetails(response.data);
            }
            return response.data;
        } catch (error) {
            alert("vous n'êtes pas autorisé à accéder à cette page.")
            console.log(error);
        };
    };



    const [formData, setFormData] = React.useState({
        code: '',
        coupon_type: '',
        start_date: '',
        end_date: '',
        amount: '',
        is_active: '',
        usage_limit: '',
    });

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData({
            ...formData,
            [name]: type === 'checkbox' ? checked : value,
        });
    };

    const handleSubmit = async (e) => {
        // Set loading to true when starting the function
        setIsLoading(true);
        // Get shop id
        const shop_id = shopDetails.id;

        e.preventDefault();
        try {
            // Replace 'YOUR_API_URL' with the actual URL for CouponCreateAPIView
            await axios.post(`${BASE_URL}/shop/api/coupon/${shop_id}/create/`, formData);
            // Optionally, you can add a success message or redirect the user to another page.
            alert('Création réussie!');
            setFormData({
                code: '',
                coupon_type: '',
                start_date: '',
                end_date: '',
                amount: '',
                is_active: '',
                usage_limit: '',
            });
        } catch (error) {
            // Handle any errors that may occur during the API call
            console.error('Error creating coupon:', error);
            alert('Erreur dans la création du coupon .Veuillez réessayer');
        } finally {
            // Set loading to false when the function is done, regardless of success or failure
            setIsLoading(false);
        }
    };

    useEffect(() => {
        async function fetchData() {
            await getShopDetails();
            // Fetch available couponTypes from the API
            axios.get(`${BASE_URL}/shop/api/coupons-types/`)
                .then((response) => setCouponTypes(response.data))
                .catch((error) => console.log(error));
        }
        fetchData();

    }, []);

    return (
        (shopDetails ?
            <div>
                <NavbarBackoffice />
                <CContainer className="py-4">
                    <CRow className="justify-content-center">
                        <CCol sm="8" lg="6">
                            <CForm onSubmit={handleSubmit}>
                                {isLoading ? <LoadingSpinner /> :
                                    <>
                                        <CCol xs="auto">
                                            <CFormLabel htmlFor="code">Code :</CFormLabel >
                                            <CFormInput type="text" id="code" name="code" value={formData.code} onChange={handleChange} required />
                                        </CCol>
                                        <CCol xs="auto">
                                            <CFormLabel htmlFor="coupon_type">Type coupon</CFormLabel>
                                            {/* Create a dropdown to select the expense type */}
                                            <CFormSelect id="coupon_type" name="coupon_type" value={formData.coupon_type} onChange={handleChange} required>
                                                <option value="">Choisir type de coupon</option>
                                                {couponTypes.map((type) => (
                                                    <option key={type.id} value={type.id}>{type.name}</option>
                                                ))}
                                            </CFormSelect>
                                        </CCol>
                                        <CCol xs="auto">
                                            <CFormLabel htmlFor="start_date">Date de début :</CFormLabel >
                                            <CFormInput type="date" id="start_date" name="start_date" value={formData.start_date} onChange={handleChange} required />
                                        </CCol>
                                        <CCol xs="auto">
                                            <CFormLabel htmlFor="end_date">Date de fin :</CFormLabel >
                                            <CFormInput type="date" id="end_date" name="end_date" value={formData.end_date} onChange={handleChange} required />
                                        </CCol>
                                        <CCol xs="auto">
                                            <CFormLabel htmlFor="amount">Montant en {shopDetails.country.currency.symbol} :</CFormLabel >
                                            <CFormInput type="number" id="amount" name="amount" value={formData.amount} onChange={handleChange} required />
                                        </CCol>
                                        <hr />
                                        <CCol xs="auto">
                                            <CFormLabel htmlFor="is_active">Activer : </CFormLabel>
                                            &nbsp;<input type="checkbox" name="is_active" checked={formData.is_active} onChange={handleChange} />
                                        </CCol>
                                        <hr />
                                        <CCol xs="auto">
                                            <CFormLabel htmlFor="usage_limit">Usage limit :</CFormLabel >
                                            <CFormInput id="number" name="usage_limit" value={formData.usage_limit} onChange={handleChange} required />
                                        </CCol>
                                        <hr />
                                        <CButton type="submit" color="primary">
                                            <CIcon type="submit" className="icon-default-add" icon={icon.cilPlaylistAdd} />
                                            &nbsp;Ajouter dans la liste des coupons
                                        </CButton>
                                    </>
                                }
                            </CForm>
                        </CCol>
                    </CRow>
                </CContainer>
                <br /><br />
                <Footer />
            </div> : <div><NavbarBackoffice /><LoadingSpinner /></div>
        )
    );
};

export default AddCoupon;
