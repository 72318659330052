import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import {
    CContainer, CRow, CCol, CForm,
    CFormLabel, CFormInput, CFormTextarea, CButton, CFormSelect, CImage
} from '@coreui/bootstrap-react';

import { NavbarBackoffice, Footer, LoadingSpinner } from '../../components';

import axios from 'axios';

import jwt_decode from 'jwt-decode';

import CIcon from '@coreui/icons-react';
import * as icon from '@coreui/icons';

const EditProduct = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const BASE_URL = 'https://pi.backend.area.mg';
    const [shopDetails, setShopDetails] = useState(null);
    const [productTypes, setProductTypes] = useState([]);
    const [suppliers, setSuppliers] = useState([]);
    const [product, setProduct] = useState({});

    // Define a loading state
    const [isLoading, setIsLoading] = useState(false);

    // Assuming you have stored the access token in a variable called `accessToken`
    const decodedToken = jwt_decode(localStorage.getItem('authTokens'));
    const username = decodedToken.username;

    // Perform a conditional check to access the productId property
    const productID = (location.state ? location.state.productId : '');

    // Function return the staff user with their Username
    const getStaffUserByUsername = async () => {
        try {
            const response = await axios.get(`${BASE_URL}/api/user/${username}/users`);
            return response.data;
        } catch (error) {
            console.log(error);
        };
    }

    // Get shop info
    const getShopDetails = async () => {
        const staff = await getStaffUserByUsername();
        const staff_id = staff.id;
        try {
            const response = await axios.get(`${BASE_URL}/shop/api/shop/owner/${staff_id}/`);
            if (response.data) {
                setShopDetails(response.data);
            }
            return response.data;
        } catch (error) {
            alert("vous n'êtes pas autorisé à accéder à cette page.")
            console.log(error);
        };
    };



    const [formData, setFormData] = React.useState({
        name: '',
        description: '',
        supplier: '', // Set the default supplier ID here
        product_type: '', // Set the default product type ID here
        is_active: true, // Set the default value here
        price: '',
        price_purchase: '',
        quantity: '',
        threshold_quantity: '',
    });

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData({
            ...formData,
            [name]: type === 'checkbox' ? checked : value,
        });
    };

    // State to hold the selected image file
    const [imageFile, setImageFile] = useState(null);

    // Handle image file change
    const handleImageChange = (e) => {
        setImageFile(e.target.files[0]);
    };

    const handleSubmit = async (e) => {
        // Set loading to true when starting the function
        setIsLoading(true);
        // Get shop id
        const shop_id = shopDetails.id;
        e.preventDefault();

        try {
            // Create a FormData object to include the image file
            const formDataWithImage = new FormData();
            formDataWithImage.append('name', formData.name);
            formDataWithImage.append('description', formData.description);
            formDataWithImage.append('code_product', product.code_product);
            formDataWithImage.append('supplier', formData.supplier.id); // Set the supplier ID or an empty string if not found
            formDataWithImage.append('product_type', formData.product_type.id); // Set the product type ID or an empty string if not found
            formDataWithImage.append('is_active', formData.is_active);
            formDataWithImage.append('price', formData.price);
            formDataWithImage.append('price_purchase', formData.price_purchase);
            formDataWithImage.append('quantity', formData.quantity);
            formDataWithImage.append('threshold_quantity', formData.threshold_quantity);
            formDataWithImage.append('shop', shop_id);
            // Append other fields as needed
            if (imageFile) {
                formDataWithImage.append('default_image', imageFile);
            };
            

            await axios.post(`${BASE_URL}/shop/api/product/${productID}/${shop_id}/edit/`, formDataWithImage, {
                headers: {
                    'Content-Type': 'multipart/form-data', // Set the content type to multipart/form-data
                },
            });
            // Redirect to another page
            alert('Modification réussie!');
            navigate('/produits');

        } catch (error) {
            console.log('Error updating product:', error);
            alert('Erreur dans la modification du produit .Veuillez réessayer');
        } finally {
            // Set loading to false when the function is done, regardless of success or failure
            setIsLoading(false);
        }
    };

    useEffect(() => {
        async function fetchData() {
            const shop = await getShopDetails();
            const shop_id = shop.id;
            // Fetch available productTypes from the API
            axios.get(`${BASE_URL}/shop/api/product-types/${shop_id}/list/`)
                .then((response) => setProductTypes(response.data))
                .catch((error) => console.log(error));
            // Fetch available suppliers from the API
            axios.get(`${BASE_URL}/shop/api/suppliers/${shop_id}/list/`)
                .then((response) => setSuppliers(response.data))
                .catch((error) => console.log(error));
            // Fetch the Expense details from the API using the provided expenseId
            axios.get(`${BASE_URL}/shop/api/product/${productID}/${shop_id}/edit/`)
                .then((response) => setProduct(response.data))
                .catch((error) => console.log(error));
        }
        fetchData();
    }, []);

    useEffect(() => {

        // Update the form data when the Expense details are fetch
        setFormData({
            name: product.name || '',
            description: product.description || '',
            supplier: product.supplier || '',
            product_type: product.product_type || '',
            current_default_image: product.default_image,
            is_active: product.is_active || '',
            price: product.price || '',
            price_purchase: product.price_purchase || '',
            quantity: product.quantity || '',
            threshold_quantity: product.threshold_quantity || '',
        });

    }, [product])

    return (
        (shopDetails ?
            <div>
                <NavbarBackoffice />
                <CContainer className="py-4">
                    <CRow className="justify-content-center">
                        <CCol sm="8" lg="6">
                            <CForm onSubmit={handleSubmit}>
                            {isLoading ? <LoadingSpinner /> :
                                    <>
                                <CCol xs="auto">
                                    <CFormLabel htmlFor="name">Nom du produit:</CFormLabel >
                                    <CFormInput type="text" id="name" name="name" value={formData.name} onChange={handleChange} required />
                                </CCol>
                                <CCol xs="auto">
                                    <CFormLabel htmlFor="description">Code produit :</CFormLabel >
                                    <CFormInput id="description" name="description" value={product.code_product} disabled />
                                </CCol>
                                <CCol xs="auto">
                                    <CFormLabel htmlFor="description">Déscription :</CFormLabel >
                                    <CFormTextarea id="description" name="description" value={formData.description} onChange={handleChange} required />
                                </CCol>
                                <CCol xs="auto">
                                    <CFormLabel htmlFor="supplier">Fournisseur</CFormLabel>
                                    {/* Create a dropdown to select the supplier */}
                                    <CFormSelect id="supplier" name="supplier" value={formData.supplier} onChange={handleChange}>
                                        <option key={formData.supplier.id} value={formData.supplier}>{formData.supplier.name}</option>
                                        {suppliers.map((sup) => (
                                            (sup.id !== formData.supplier.id &&
                                                <option key={sup.id} value={sup}>{sup.name}</option>
                                            )
                                        ))}
                                    </CFormSelect>
                                </CCol>
                                <CCol xs="auto">
                                    <CFormLabel htmlFor="product_type">Type de produit</CFormLabel>
                                    {/* Create a dropdown to select the product_type */}
                                    <CFormSelect id="product_type" name="product_type" value={formData.product_type} onChange={handleChange} required>
                                        <option key={formData.product_type.id} value={formData.product_type}>{formData.product_type.name}</option>
                                        {productTypes.map((type) => (
                                            (type.id !== formData.product_type.id &&
                                                <option key={type.id} value={type}>{type.name}</option>
                                            )
                                        ))}
                                    </CFormSelect>
                                </CCol>
                                <hr />
                                <CCol xs="auto">
                                    <div className="clearfix">
                                        <CImage align="start" rounded src={formData.current_default_image} width={100} height={100} />
                                    </div>
                                    <CFormLabel htmlFor="default_image">Charger une nouvelle image :</CFormLabel >
                                    <CFormInput type="file" size="sm" id="default_image" name="default_image" label="Image par défaut" onChange={handleImageChange} />
                                </CCol>
                                <hr />
                                <CCol xs="auto">
                                    <CFormLabel htmlFor="price">Prix de vente en {shopDetails.country.currency.symbol} : </CFormLabel >
                                    <CFormInput type="number" name="price" value={formData.price} onChange={handleChange} required />
                                </CCol>
                                <CCol xs="auto">
                                    <CFormLabel htmlFor="price_purchase">Prix d'achat en {shopDetails.country.currency.symbol} : </CFormLabel >
                                    <CFormInput type="number" name="price_purchase" value={formData.price_purchase} onChange={handleChange} required />
                                </CCol>
                                <CCol xs="auto">
                                    <CFormLabel htmlFor="quantity">Quantité : </CFormLabel >
                                    <CFormInput type="number" name="quantity" value={formData.quantity} onChange={handleChange} required />
                                </CCol>
                                <CCol xs="auto">
                                    <CFormLabel htmlFor="threshold_quantity">Alerte quantité minimale : </CFormLabel >
                                    <CFormInput type="number" name="threshold_quantity" value={formData.threshold_quantity} onChange={handleChange} required />
                                </CCol>
                                <hr />
                                <CCol xs="auto">
                                    <CFormLabel htmlFor="is_active">Activer : </CFormLabel>
                                    &nbsp;<input type="checkbox" name="is_active" checked={formData.is_active} onChange={handleChange} />
                                </CCol>
                                <hr />
                                <CButton type="submit" color="primary">
                                    <CIcon type="submit" className="icon-default-add" icon={icon.cilSave} />
                                    &nbsp;Enregistrer la modification
                                </CButton>
                                </>
}
                            </CForm>
                        </CCol>
                    </CRow>
                </CContainer>
                <br /><br />
                <Footer />
            </div> : <div><NavbarBackoffice /><LoadingSpinner /></div>
        )
    );
};

export default EditProduct;
