import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import './App.css';
import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
// import PrivateRoute from './utils/PrivateRoute';
import { AuthProvider } from './context/AuthContext';
import {
  Login,
  ResetPassword,
  Cashier,
  InfoUser,
  ChangePassword,
  Report,
  NotFoundView,
  DashboardView,
  TodoList,
  OrderList,
  OrderDetails,
  ChangePasswordAdmin,
  TodoListAdmin,
  InfoShop,
  ProductList,
  ExpenseList,
  AddMaterial,
  Material,
  AddExpense,
  EditExpense,
  EditMaterial,
  EditProduct,
  AddProduct,
  ListSupplier,
  EditSupplier,
  AddSupplier,
  ListDiscount,
  AddDiscount,
  EditDiscount,
  ListCoupon,
  AddCoupon,
  EditCoupon,
  ListInvoice,
  EditInvoice,
} from './pages';



function App() {

  return (
    <div>
      <Router>
        <AuthProvider>
          <Routes>
            <Route path="/" element={<Navigate replace to="/connexion" />} />
            <Route exact path="/connexion" element={<Login />}  />
            <Route path="/reinitialiser-mot-de-passe" element={<ResetPassword />} />
            <Route exact path="/caisse" element={<Cashier/>} />
            <Route path="/mon-information" element={<InfoUser/>} />
            <Route path="/info-shop" element={<InfoShop/>} />
            <Route exact path="/changement-mot-de-passe" element={<ChangePassword />} />
            <Route exact path="/changement-mot-de-passe-admin" element={<ChangePasswordAdmin />} />
            <Route exact path="/facture-en-attente" element={<Report />} />
            <Route exact path="/ventes" element={<OrderList />} />
            <Route exact path="/dépenses" element={<ExpenseList />} />
            <Route exact path="/ajouter-dépense" element={<AddExpense />}/>
            <Route exact path="/modifier-dépense" element={<EditExpense />}/>
            <Route exact path="/vente-details" element={<OrderDetails />} />
            <Route exact path="/produits" element={<ProductList />} />
            <Route exact path="/ajouter-produit" element={<AddProduct />} />
            <Route exact path="/modifier-produit" element={<EditProduct />} />
            <Route exact path="/dashboard" element={<DashboardView />}/>
            <Route exact path="/todo-list" element={<TodoList />}/>
            <Route exact path="/todo-list-admin" element={<TodoListAdmin />}/>
            <Route exact path="/matériels" element={<Material />}/>
            <Route exact path="/ajouter-matériel" element={<AddMaterial />}/>
            <Route exact path="/modifier-matériel" element={<EditMaterial />}/>
            <Route exact path="/fournisseurs" element={<ListSupplier />}/>
            <Route exact path="/ajouter-fournisseur" element={<AddSupplier />}/>
            <Route exact path="/modifier-fournisseur" element={<EditSupplier />}/>
            <Route exact path="/remises" element={<ListDiscount />}/>
            <Route exact path="/ajouter-remise" element={<AddDiscount />}/>
            <Route exact path="/modifier-remise" element={<EditDiscount />}/>
            <Route exact path="/coupons" element={<ListCoupon />}/>
            <Route exact path="/ajouter-coupon" element={<AddCoupon />}/>
            <Route exact path="/modifier-coupon" element={<EditCoupon/>}/>
            <Route exact path="/factures" element={<ListInvoice />}/>
            <Route exact path="/modifier-facture" element={<EditInvoice/>}/>
            <Route path="*" element={<NotFoundView />} />
          </Routes>
        </AuthProvider>
      </Router>
    </div>
  );
}

export default App;
