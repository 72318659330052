import React, { useEffect, useState } from 'react';
import axios from 'axios';

import { Footer, FormatDate, NavbarBackoffice, LoadingSpinner } from '../../components';

import {
    CContainer,
    CRow,
    CCol,
} from '@coreui/bootstrap-react';

import jwt_decode from 'jwt-decode';

function InfoShop() {
    const BASE_URL = 'https://pi.backend.area.mg';
    const [authTokens, setAuthTokens] = useState(() => JSON.parse(localStorage.getItem('authTokens')) || null);

    const [userInfo, setUserInfo] = useState(null);

    const [shopDetails, setShopDetails] = useState(null);
    const [listCashier, setListCashier] = useState([]);

    // Assuming you have stored the access token in a variable called `accessToken`
    const decodedToken = jwt_decode(localStorage.getItem('authTokens'));
    const username = decodedToken.username;

    const refreshToken = async () => {
        try {
            const response = await axios.post(`${BASE_URL}/api/token/refresh/`, {
                refresh: authTokens && authTokens.refresh,
            });

            const { data } = response;

            if (response.status === 200) {
                setAuthTokens(data);
                localStorage.setItem('authTokens', JSON.stringify(data));
                return data.access;
            }
            return null;
        } catch (error) {
            console.error('Token refresh failed', error);
            return;
        }
    };

    const fetchUserInfo = async () => {
        try {
            const accessToken = await refreshToken();
            const response = await axios.get(`${BASE_URL}/api/user-info`, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
            const user_connected = response.data
            setUserInfo(user_connected);
            return user_connected;
        } catch (error) {
            console.error('Error fetching user information:', error);
        }
    };

    const fetchListOfCashierShop = async () => {
        const shop = await getShopDetails();
        axios.get(`${BASE_URL}/shop/api/cashiers/by_shop/${shop.id}/`)
            .then((response) => setListCashier(response.data))
            .catch((error) => console.log(error));
    }

    // Function return the staff user with their Username
    const getStaffUserByUsername = async () => {
        try {
            const response = await axios.get(`${BASE_URL}/api/user/${username}/users`);
            const user_connected = response.data;
            return user_connected;
        } catch (error) {
            console.log(error);
            alert("Vous ne pouvez pas accéder à cette page.");
        };
    }

    // Get shop info
    const getShopDetails = async () => {
        const staff = await getStaffUserByUsername();
        const staff_id = staff.id;
        try {
            const response = await axios.get(`${BASE_URL}/shop/api/shop/owner/${staff_id}/`);
            if (response.data) {
                setShopDetails(response.data);
            }
            return response.data;
        } catch (error) {
            console.log(error);
        };
    };

    useEffect(() => {
        async function fetchData() {
            await getStaffUserByUsername();
            await fetchUserInfo();
            await getShopDetails();
            await fetchListOfCashierShop();
        }

        const interval = setInterval(() => {
            fetchData();
        }, 1000);

        return () => clearInterval(interval);

    }, [userInfo, shopDetails]);

    return (
        (shopDetails ?
            <div>
                <NavbarBackoffice />
                {userInfo ? (
                    <CContainer className="mt-5">
                        <CRow className="row align-items-start">
                            <CCol className="custom-col" xs={12} md={6}>
                                <h3>Mon information</h3>
                                <hr />
                                <p>Mon pseudo : {userInfo.username}</p>
                                <p>Adresse e-mail : {userInfo.email}</p>
                                <p>Nom : {userInfo.first_name}</p>
                                <p>Prénom : {userInfo.last_name}</p>
                                <p>Mon rôle: {userInfo.group_name}</p>
                                <hr />
                                <h3>Info de Cassier(ère) ( Nombre : {listCashier.length} )</h3>
                                {listCashier.map((cashier) => (
                                    <p>
                                        <span>Caisse N° : {cashier.cashier_number}</span> --&nbsp;
                                        <span>Code : {cashier.code}</span><br/>
                                        <span>Nom : {cashier.cashier_account.first_name} {cashier.cashier_account.last_name}</span><br/>
                                        <span>Date d'adhésion : {cashier.cashier_account.date_joined}</span> --&nbsp;
                                        <span>Dernière connexion : {cashier.cashier_account.last_login}</span>
                                    </p>
                                ))}
                            </CCol>
                            <CCol className="custom-col" xs={12} md={6}>
                                <h3>Information SHOP</h3>
                                <hr />
                                <p>Nom : {shopDetails.name}</p>
                                <p>Code : {shopDetails.code}</p>
                                <p>Description : {shopDetails.description}</p>
                                <p>Contact : {shopDetails.phone}</p>
                                <hr />
                                <p>Adresse : {shopDetails.address_line_1}</p>
                                <p>Ville : {shopDetails.city.name} ( {shopDetails.city.postal_code.code} ) - {shopDetails.state.name}</p>
                                <hr />
                                <p>Contrat : {parseInt(shopDetails.contract_cost).toLocaleString()} {shopDetails.country.currency.symbol}</p>
                                <p>Début du contrat : <FormatDate dateString={shopDetails.start_date} /></p>
                                <p>Durrée du contrat : {shopDetails.contract_duration} Mois</p>
                                <hr />
                            </CCol>
                        </CRow>
                        <br /><br />
                    </CContainer>
                ) : (
                    <CContainer className="mt-5">
                        <CRow className="row g-3">
                            <h4>Mon information</h4>
                            <p>Chargement des informations utilisateur...</p>
                        </CRow>
                    </CContainer>
                )}
                <Footer />
            </div> : <div><NavbarBackoffice /><LoadingSpinner /></div>
        )
    );
}

export default InfoShop;
