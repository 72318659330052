import React from "react";

import {
    CCard,
    CCardImage,
    CCardBody,
    CCardText,
} from '@coreui/bootstrap-react';

const Category = ({selectedProductType, nameCategory,code, imgCategory }) => {
    const BASE_URL = "https://pi.backend.area.mg"

    return (
        <div key={code}>
            <CCard style={{ width: '123px' , height: '100px' }} className={selectedProductType === code ? 'custom-selected' : ''}>
                <CCardImage className="custom-card-image" orientation="top" src={BASE_URL+imgCategory} />
                <CCardBody>
                    <CCardText className="custom-card-body">
                        {nameCategory}
                    </CCardText>
                </CCardBody>
            </CCard>
        </div>
    );
};

export default Category;
