import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';

import {
  CContainer,
  CCol,
  CRow,
  CTable,
  CTableHead,
  CTableRow,
  CTableHeaderCell,
  CTableDataCell,
  CTableBody,
  CTooltip,
} from '@coreui/bootstrap-react';

import { Navbar, NumberFormat, FormatDate, Footer, LoadingSpinner } from '../../components';

import CIcon from '@coreui/icons-react';
import * as icon from '@coreui/icons';

import axios from 'axios';
import "./style.css";

import jwt_decode from 'jwt-decode';

const Report = () => {
  const BASE_URL = 'https://pi.backend.area.mg';
  const navigate = useNavigate();
  const [shopDetails, setShopDetails] = useState(null);

  // Get list of bill pending 
  const [orders, setOrders] = useState([]);
  // Get the list of provider of payment
  const getListOfPendingOrders = async () => {
    const shop = await getShopDetails();
    const cashier = await getCashierUser();
    try {
      const response = await axios.get(`${BASE_URL}/shop/api/orders/pending/${shop.id}/${cashier.id}/`);
      if (response.data) {
        setOrders(response.data);
      }
      return response.data;
    } catch (error) {
      console.log(error);
    };
  };

  // Assuming you have stored the access token in a variable called `accessToken`
  const decodedToken = jwt_decode(localStorage.getItem('authTokens'));
  const username = decodedToken.username;


  // Function return the staff user with their Username
  const getStaffUserByUsername = async (username) => {
    try {
      const response = await axios.get(`${BASE_URL}/api/user/${username}/users`);
      return response.data;
    } catch (error) {
      console.log(error);
      alert("Vous ne pouvez pas accéder à cette page.");
    };
  }

  // Function return the staff user if cashier or not 
  const getCashierUser = async () => {
    const user_connected = await getStaffUserByUsername(username);
    const user_id = user_connected.id;
    try {
      const response = await axios.get(`${BASE_URL}/shop/api/cashier/active/${user_id}/`);
      return response.data;
    } catch (error) {
      console.log(error);
    };
  }

  // Get shop info
  const getShopDetails = async () => {
    const cashier = await getCashierUser();
    const shop_id = cashier.shop;
    try {
      const response = await axios.get(`${BASE_URL}/shop/api/shop/${shop_id}/`);
      if (response.data) {
        setShopDetails(response.data);
      }
      return response.data;
    } catch (error) {
      console.log(error);
    };
  };


  // Function to convert data to CSV format
  function convertToCSV(data) {
    const header = [
      'N°', 'Nom_Client', 'Contact', 'Adresse_Mail', 'Adresse', 'Remise_' + shopDetails.country.currency.symbol,
      'Montant_' + shopDetails.country.currency.symbol, 'Date_Enregistrement', 'Derniere_Actualisation'
    ];

    const rows = data.map(order => [
      order.id,
      order.client_name,
      order.client_contact,
      order.client_email,
      order.client_address,
      order.discount_amount,
      order.total_amount,
      order.created_at,
      order.updated_at
    ]);

    return [header, ...rows].map(row => row.join(',')).join('\n');
  }

  // Function to download CSV file
  const prepareCSV = async (data, filename) => {
    try {
      const csvContent = 'data:text/csv;charset=utf-8,' + encodeURIComponent(data);
      const link = document.createElement('a');
      link.setAttribute('href', csvContent);
      link.setAttribute('download', filename);
      link.click();
    } catch (error) {
      console.error("Error exporting data to CSV:", error);
      alert("Une erreur s'est produite lors de l'exportation des données en CSV.");
    }
  };

  // Usage: Call the downloadCSV function to initiate the export
  const downloadCSV = () => {
    const datetime = new Date().toISOString().replace(/:/g, '-');
    const filename = `commandes_attentes_${datetime}.csv`;

    if (!orders || orders.length === 0) {
      alert("Aucune commande en attente de paiement trouvée");
      return;
    }

    const csvData = convertToCSV(orders);
    prepareCSV(csvData, filename);
  };


  useEffect(() => {
    getListOfPendingOrders();
    getShopDetails();
  }, [])

  return (
    (shopDetails ?
      <div>
        <Navbar />
        <CContainer className="mt-1">
          <CRow>
            <CCol md={12} className="text-center">
              {orders.length === 0 ? <h4 className="display-6">Veuillez patienter pour les mises à jour des factures en attentes ...</h4> :
                <h4 className="display-6">Factures en attentes ( {orders.length} ) &nbsp;&nbsp;
                  <CTooltip content="Télécharger dans un fichier csv.">
                    <CIcon
                      onClick={downloadCSV}
                      type="submit"
                      className="custom-icon-download"
                      icon={icon.cilCloudDownload}
                    />
                  </CTooltip>
                </h4>}
              {orders &&
                <CTable color="dark" hover>
                  <CTableHead>
                    <CTableRow>
                      <CTableHeaderCell scope="col">N°</CTableHeaderCell>
                      <CTableHeaderCell scope="col">Nom client</CTableHeaderCell>
                      <CTableHeaderCell scope="col">Contact</CTableHeaderCell>
                      <CTableHeaderCell scope="col">Adresse-mail</CTableHeaderCell>
                      <CTableHeaderCell scope="col">Adresse</CTableHeaderCell>
                      <CTableHeaderCell scope="col">Remise</CTableHeaderCell>
                      <CTableHeaderCell scope="col">Montant à payer</CTableHeaderCell>
                      <CTableHeaderCell scope="col">Date d'actualisation</CTableHeaderCell>
                      <CTableHeaderCell scope="col">Action</CTableHeaderCell>
                    </CTableRow>
                  </CTableHead>
                  <CTableBody>
                    {orders.map((order) => (
                      <CTableRow color="light">
                        <CTableHeaderCell scope="row">{order.id}</CTableHeaderCell>
                        <CTableDataCell>{order.client_name}</CTableDataCell>
                        <CTableDataCell>{order.client_contact}</CTableDataCell>
                        <CTableDataCell>{order.client_email}</CTableDataCell>
                        <CTableDataCell>{order.client_address}</CTableDataCell>
                        <CTableDataCell><NumberFormat number={order.discount_amount} /> {shopDetails.country.currency.symbol}</CTableDataCell>
                        <CTableDataCell><NumberFormat number={order.total_amount} /> {shopDetails.country.currency.symbol}</CTableDataCell>
                        <CTableDataCell><FormatDate dateString={order.updated_at} /></CTableDataCell>
                        <CTableDataCell>
                          <CTooltip content="Payer ou ajouter des autres articles">
                            <CIcon
                              onClick={() => navigate(`/caisse`, {
                                state: {
                                  orderId: order.id,
                                  nameClient: order.client_name,
                                  contactClient: order.client_contact,
                                  emailClient: order.client_email,
                                  addressClient: order.client_address,
                                }
                              })}
                              type="submit"
                              className="custom-icon-proceed"
                              icon={icon.cilChevronDoubleRight}
                            />
                          </CTooltip>
                        </CTableDataCell>
                      </CTableRow>
                    ))}
                  </CTableBody>
                </CTable>}
            </CCol>
          </CRow>
        </CContainer>
        <Footer />
      </div> : <div><Navbar /><LoadingSpinner /></div>
    )
  );
};

export default Report;