import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';

import {
    CContainer,
    CCol,
    CRow,
    CTable,
    CTableHead,
    CTableRow,
    CTableHeaderCell,
    CTableBody,
    CTableDataCell,
    CTooltip,
} from '@coreui/bootstrap-react';

import { NavbarBackoffice, Footer, FormatDate, LoadingSpinner } from '../../components';

import axios from 'axios';
import "./style.css";

import jwt_decode from 'jwt-decode';

import CIcon from '@coreui/icons-react';
import * as icon from '@coreui/icons';

const ListDiscount = () => {
    const BASE_URL = 'https://pi.backend.area.mg';
    const [shopDetails, setShopDetails] = useState(null);

    const navigate = useNavigate();

    // Get list of bill pending 
    const [discounts, setDiscounts] = useState([]);
    // Get the list of provider of payment
    const getListOfDiscounts = async () => {
        const shop = await getShopDetails();
        const shop_id = shop.id;
        try {
            const response = await axios.get(`${BASE_URL}/shop/api/discounts/${shop_id}/list/`);
            const disc = response.data;
            setDiscounts(disc);
            return disc;

        } catch (error) {
            console.log(error);
        };
    };

    // Assuming you have stored the access token in a variable called `accessToken`
    const decodedToken = jwt_decode(localStorage.getItem('authTokens'));
    const username = decodedToken.username;


    // Function return the staff user with their Username
    const getStaffUserByUsername = async () => {
        try {
            const response = await axios.get(`${BASE_URL}/api/user/${username}/users`);
            return response.data;
        } catch (error) {
            console.log(error);
            alert("Vous ne pouvez pas accéder à cette page.");
        };
    }

    // Get shop info
    const getShopDetails = async () => {
        const staff = await getStaffUserByUsername();
        const staff_id = staff.id;
        try {
            const response = await axios.get(`${BASE_URL}/shop/api/shop/owner/${staff_id}/`);
            if (response.data) {
                setShopDetails(response.data);
            }
            return response.data;
        } catch (error) {
            console.log(error);
        };
    };

    // Create a Function to Convert Data to CSV
    const convertToCSV = async (data) => {
        const header = [
            'Type_Remise', 'Usage_Code', 'Value_Type', 'Value', 'Debut', 'Fin', 'Etat', 'Produit', 'Date_Enregistrement',
            'Derniere_Actualisation'
        ];

        const rows = data.map(disc => [
            disc.discount_type.name,
            disc.usage_code,
            disc.value_type,
            disc.value,
            disc.start_date,
            disc.end_date,
            disc.is_active ? 'Actif' : 'Non actif',
            disc.product ? disc.product.code_product+'-'+disc.product.name : '',
            disc.created_at,
            disc.updated_at
        ]);

        return [header, ...rows].map(row => row.join(',')).join('\n');
    }

    const addDiscount = () => {
        navigate('/ajouter-remise');
    }

    // Function to download CSV file
    const downloadCSV = async () => {
        const datetime = new Date().toISOString().replace(/:/g, '-');
        const filename = `Remises_${datetime}.csv`;

        if (!discounts || discounts.length === 0) {
            alert("Aucune remise trouvée");
            return;
        }

        try {
            const data = await convertToCSV(discounts);

            const csvContent = 'data:text/csv;charset=utf-8,' + encodeURIComponent(data);
            const link = document.createElement('a');
            link.setAttribute('href', csvContent);
            link.setAttribute('download', filename);
            link.click();
        } catch (error) {
            console.error("Error exporting data to CSV:", error);
            alert("Une erreur s'est produite lors de l'exportation des données en CSV.");
        }
    };

    useEffect(() => {
        const fetchDiscounts = async () => {
            await getListOfDiscounts();
        };
        getShopDetails();

        const interval = setInterval(() => {
            fetchDiscounts();
        }, 5000);

        return () => clearInterval(interval);


    }, [])

    return (
        (shopDetails ?
            <div>
                <NavbarBackoffice />
                <CContainer className="mt-1">
                    <CRow>
                        <CCol md={12}>
                            {discounts.length === 0 ? <h4 className="display-6 text-center">Merci de patienter pour les mises à jour des remises ...
                                <CTooltip content="Configurer une remise">
                                    <CIcon
                                        onClick={addDiscount}
                                        type="submit"
                                        className="custom-icon-add"
                                        icon={icon.cilPlus}
                                    />
                                </CTooltip>
                            </h4> :
                                <h4 className="display-6 text-center">Tous les remises existent dans la base ( {discounts.length} ) &nbsp;&nbsp;
                                    <CTooltip content="Télécharger dans un fichier csv.">
                                        <CIcon
                                            onClick={downloadCSV}
                                            type="submit"
                                            className="custom-icon-download"
                                            icon={icon.cilCloudDownload}
                                        />
                                    </CTooltip>
                                    &nbsp;&nbsp;
                                    <CTooltip content="Ajouter un produit">
                                        <CIcon
                                            onClick={addDiscount}
                                            type="submit"
                                            className="custom-icon-add"
                                            icon={icon.cilPlus}
                                        />
                                    </CTooltip>
                                </h4>}
                            {discounts &&
                                <CTable color="dark" striped>
                                    <CTableHead>
                                        <CTableRow>
                                            <CTableHeaderCell scope="col">Type de remise</CTableHeaderCell>
                                            <CTableHeaderCell scope="col">Usage_code</CTableHeaderCell>
                                            <CTableHeaderCell scope="col">Value_type</CTableHeaderCell>
                                            <CTableHeaderCell scope="col">Value</CTableHeaderCell>
                                            <CTableHeaderCell scope="col">Start_date</CTableHeaderCell>
                                            <CTableHeaderCell scope="col">End_date</CTableHeaderCell>
                                            <CTableHeaderCell scope="col">Etat</CTableHeaderCell>
                                            <CTableHeaderCell scope="col">Produit</CTableHeaderCell>
                                            <CTableHeaderCell scope="col">Date_MAJ</CTableHeaderCell>
                                            <CTableHeaderCell scope="col">Actions</CTableHeaderCell>
                                        </CTableRow>
                                    </CTableHead>
                                    <CTableBody>
                                        {discounts.map((disc) => (
                                            <CTableRow key={disc.id} color={disc.is_active ? 'light' : 'danger'}>
                                                <CTableHeaderCell scope="row">{disc.discount_type.name}</CTableHeaderCell>
                                                <CTableDataCell>{disc.usage_code}</CTableDataCell>
                                                <CTableDataCell>{disc.value_type}</CTableDataCell>
                                                <CTableDataCell>{disc.value}</CTableDataCell>
                                                <CTableDataCell>{disc.start_date}</CTableDataCell>
                                                <CTableDataCell>{disc.end_date}</CTableDataCell>
                                                <CTableDataCell>{disc.is_active ? 'Actif' : 'Non actif'}</CTableDataCell>
                                                <CTableDataCell>{disc.product ? disc.product.code_product+"-"+disc.product.name : ''}</CTableDataCell>
                                                <CTableDataCell>
                                                    <FormatDate dateString={disc.updated_at} />
                                                </CTableDataCell>
                                                <CTableDataCell>
                                                    <CTooltip content="Modifier">
                                                        <CIcon
                                                            type="submit"
                                                            onClick={() => navigate(`/modifier-remise`, {
                                                                state: {
                                                                    discountID: disc.id,
                                                                }
                                                            })}
                                                            className="custom-icon-edit"
                                                            icon={icon.cilColorBorder}
                                                        />
                                                    </CTooltip>
                                                </CTableDataCell>
                                            </CTableRow>
                                        ))}

                                    </CTableBody>
                                </CTable>
                            }
                        </CCol>
                    </CRow>
                </CContainer>
                <br /><br />
                <Footer />
            </div> : <div><NavbarBackoffice /><LoadingSpinner /></div>
        )
    );
};

export default ListDiscount;