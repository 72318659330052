import React, { useState, useEffect } from 'react';
import {
    CContainer, CRow, CCol, CForm,
    CFormLabel, CFormInput, CFormTextarea, CButton, CFormSelect
} from '@coreui/bootstrap-react';

import { NavbarBackoffice, Footer, LoadingSpinner } from '../../components';

import axios from 'axios';

import jwt_decode from 'jwt-decode';

import CIcon from '@coreui/icons-react';
import * as icon from '@coreui/icons';

const AddExpense = () => {
    const BASE_URL = 'https://pi.backend.area.mg';
    const [shopDetails, setShopDetails] = useState(null);
    const [expenseTypes, setExpenseTypes] = useState([]);

    // Assuming you have stored the access token in a variable called `accessToken`
    const decodedToken = jwt_decode(localStorage.getItem('authTokens'));
    const username = decodedToken.username;

    // Define a loading state
    const [isLoading, setIsLoading] = useState(false);

    // Function return the staff user with their Username
    const getStaffUserByUsername = async () => {
        try {
            const response = await axios.get(`${BASE_URL}/api/user/${username}/users`);
            return response.data;
        } catch (error) {
            console.log(error);
        };
    }

    // Get shop info
    const getShopDetails = async () => {
        const staff = await getStaffUserByUsername();
        const staff_id = staff.id;
        try {
            const response = await axios.get(`${BASE_URL}/shop/api/shop/owner/${staff_id}/`);
            if (response.data) {
                setShopDetails(response.data);
            }
            return response.data;
        } catch (error) {
            alert("vous n'êtes pas autorisé à accéder à cette page.")
            console.log(error);
        };
    };



    const [formData, setFormData] = React.useState({
        label: '',
        amount: '',
        shop: '', // Set the default shop ID here
        expense_type: '',
        description: '',
        is_completed: true, // Set the default value here
    });

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData({
            ...formData,
            [name]: type === 'checkbox' ? checked : value,
        });
    };

    const handleSubmit = async (e) => {
        // Set loading to true when starting the function
        setIsLoading(true);
        // Get shop id
        const shop_id = shopDetails.id;

        e.preventDefault();
        try {
            // Replace 'YOUR_API_URL' with the actual URL for MaterialCreateAPIView
            await axios.post(`${BASE_URL}/shop/api/expense/${shop_id}/create/`, formData);
            // Optionally, you can add a success message or redirect the user to another page.
            alert('Création réussie!');
            setFormData({
                label: '',
                amount: '',
                shop: '', // Set the default shop ID here
                expense_type: '',
                description: '',
                is_completed: true, // Set the default value here
            });
        } catch (error) {
            // Handle any errors that may occur during the API call
            console.error('Error creating expense:', error);
            alert('Erreur dans la création du matériel .Veuillez réessayer');
        } finally {
            // Set loading to false when the function is done, regardless of success or failure
            setIsLoading(false);
        }
    };

    useEffect(() => {
        async function fetchData() {
            await getShopDetails();
            // Fetch available ExpenseTypes from the API
            axios.get(`${BASE_URL}/shop/api/expense-types/`)
                .then((response) => setExpenseTypes(response.data))
                .catch((error) => console.log(error));
        }
        fetchData();

    }, []);

    return (
        (shopDetails ?
            <div>
                <NavbarBackoffice />
                <CContainer className="py-4">
                    <CRow className="justify-content-center">
                        <CCol sm="8" lg="6">
                            <CForm onSubmit={handleSubmit}>
                            {isLoading ? <LoadingSpinner /> :
                                    <>
                                <CCol xs="auto">
                                    <CFormLabel htmlFor="label">Libélé :</CFormLabel >
                                    <CFormInput type="text" id="label" name="label" value={formData.label} onChange={handleChange} required />
                                </CCol>
                                <CCol xs="auto">
                                    <CFormLabel htmlFor="amount">Montant : </CFormLabel >
                                    <CFormInput type="number" name="amount" value={formData.amount} onChange={handleChange} required />
                                </CCol>
                                <CCol xs="auto">
                                    <CFormLabel htmlFor="description">Déscription :</CFormLabel >
                                    <CFormTextarea id="description" name="description" value={formData.description} onChange={handleChange} required />
                                </CCol>
                                <CCol xs="auto">
                                    <CFormLabel htmlFor="expense_type">Expense Type</CFormLabel>
                                    {/* Create a dropdown to select the expense type */}
                                    <CFormSelect id="expense_type" name="expense_type" value={formData.expense_type} onChange={handleChange} required>
                                        <option value="">Choisir type de dépense</option>
                                        {expenseTypes.map((type) => (
                                            <option key={type.id} value={type.id}>{type.name}</option>
                                        ))}
                                    </CFormSelect>
                                </CCol>
                                <hr />
                                <CCol xs="auto">
                                    <CFormLabel htmlFor="is_completed">Si terminer : </CFormLabel>
                                    &nbsp;<input type="checkbox" name="is_completed" checked={formData.is_completed} onChange={handleChange} />
                                </CCol>
                                <hr />
                                <CButton type="submit" color="primary">
                                    <CIcon type="submit" className="icon-default-add" icon={icon.cilPlaylistAdd} />
                                    &nbsp;Ajouter dans la liste des dépenses
                                </CButton>
                                </>
}
                            </CForm>
                        </CCol>
                    </CRow>
                </CContainer>
                <br /><br />
                <Footer />
            </div> : <div><NavbarBackoffice /><LoadingSpinner /></div>
        )
    );
};

export default AddExpense;
