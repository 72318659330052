import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import {
    CContainer, CRow, CCol, CForm,
    CFormLabel, CFormInput, CFormTextarea, CButton, CFormSelect
} from '@coreui/bootstrap-react';

import { NavbarBackoffice, Footer, LoadingSpinner } from '../../components';

import axios from 'axios';

import jwt_decode from 'jwt-decode';

import CIcon from '@coreui/icons-react';
import * as icon from '@coreui/icons';

const EditExpense = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const BASE_URL = 'https://pi.backend.area.mg';
    const [shopDetails, setShopDetails] = useState(null);
    const [expenseTypes, setExpenseTypes] = useState([]);

    // Assuming you have stored the access token in a variable called `accessToken`
    const decodedToken = jwt_decode(localStorage.getItem('authTokens'));
    const username = decodedToken.username;

    // Perform a conditional check to access the expenseId property
    const expenseId = (location.state ? location.state.expenseId : '');

    // Define a loading state
    const [isLoading, setIsLoading] = useState(false);

    const [expense, setExpense] = useState({});
    const [formData, setFormData] = useState({
        label: '',
        amount: '',
        expense_type: '',
        description: '',
        is_completed: '',
    });

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData({
            ...formData,
            [name]: type === 'checkbox' ? checked : value,
        });
    };

    const handleSubmit = async (e) => {
        // Set loading to true when starting the function
        setIsLoading(true);
        const shop_id = shopDetails.id;
        e.preventDefault();
        // Send a PUT request to update the Expense
        axios.put(`${BASE_URL}/shop/api/expense/${expenseId}/${shop_id}/edit/`, formData)
            .then((response) => {
                console.log('Expense updated successfully:', response.data);
                // Handle success or redirect to another page
                // Redirect to another page
                alert('Modification réussie!');
                navigate('/dépenses');
            })
            .catch((error) => {
                console.log('Error updating expense:', error);
                alert('Erreur dans la modification du dépense .Veuillez réessayer');
            })
            .finally(() => {
                // Set loading to false when the function is done, regardless of success or failure
                setIsLoading(false);
            });
    }

    // Function return the staff user with their Username
    const getStaffUserByUsername = async () => {
        try {
            const response = await axios.get(`${BASE_URL}/api/user/${username}/users`);
            return response.data;
        } catch (error) {
            console.log(error);
        };
    }

    // Get shop info
    const getShopDetails = async () => {
        const staff = await getStaffUserByUsername();
        const staff_id = staff.id;
        try {
            const response = await axios.get(`${BASE_URL}/shop/api/shop/owner/${staff_id}/`);
            if (response.data) {
                setShopDetails(response.data);
            }
            return response.data;
        } catch (error) {
            alert("vous n'êtes pas autorisé à accéder à cette page.")
            console.log(error);
        };
    }
    useEffect(() => {
        // Update the form data when the Expense details are fetched
        setFormData({
            label: expense.label || '',
            amount: expense.amount || '',
            expense_type: expense.expense_type || '',
            description: expense.description || '',
            is_completed: expense.is_completed || false,
        });
    }, [expense]);


    useEffect(() => {
        async function fetchData() {
            const shop = await getShopDetails();
            const shop_id = shop.id;
            // Fetch available ExpenseTypes from the API
            axios.get(`${BASE_URL}/shop/api/expense-types/`)
                .then((response) => setExpenseTypes(response.data))
                .catch((error) => console.log(error));
            // Fetch the Expense details from the API using the provided expenseId
            axios.get(`${BASE_URL}/shop/api/expense/${expenseId}/${shop_id}/edit/`)
                .then((response) => setExpense(response.data))
                .catch((error) => console.log(error));
        }
        fetchData();

    }, []);

    return (
        (shopDetails ?
            <div>
                <NavbarBackoffice />
                <CContainer className="py-4">
                    <CRow className="justify-content-center">
                        <CCol sm="8" lg="6">
                            <CForm onSubmit={handleSubmit}>
                                {isLoading ? <LoadingSpinner /> :
                                    <>
                                        <CCol xs="auto">
                                            <CFormLabel htmlFor="label">Libélé :</CFormLabel >
                                            <CFormInput type="text" id="label" name="label" value={formData.label} onChange={handleChange} required />
                                        </CCol>
                                        <CCol xs="auto">
                                            <CFormLabel htmlFor="amount">Montant : </CFormLabel >
                                            <CFormInput type="number" name="amount" value={formData.amount} onChange={handleChange} required />
                                        </CCol>
                                        <CCol xs="auto">
                                            <CFormLabel htmlFor="description">Déscription :</CFormLabel >
                                            <CFormTextarea id="description" name="description" value={formData.description} onChange={handleChange} required />
                                        </CCol>
                                        <CCol xs="auto">
                                            <CFormLabel htmlFor="expense_type">Expense Type</CFormLabel>
                                            {/* Create a dropdown to select the expense type */}
                                            <CFormSelect id="expense_type" name="expense_type" value={formData.expense_type} onChange={handleChange} required>
                                                <option key={formData.expense_type.id} value={formData.expense_type.id}>{formData.expense_type.name}</option>
                                                {expenseTypes.map((type) => (
                                                    (type.id !== formData.expense_type.id &&
                                                        <option key={type.id} value={type.id}>{type.name}</option>
                                                    )
                                                ))}
                                            </CFormSelect>
                                        </CCol>
                                        <hr />
                                        <CCol xs="auto">
                                            <CFormLabel htmlFor="is_completed">Si terminer : </CFormLabel>
                                            &nbsp;<input type="checkbox" name="is_completed" checked={formData.is_completed} onChange={handleChange} />
                                        </CCol>
                                        <hr />
                                        <CButton type="submit" color="primary">
                                            <CIcon type="submit" className="icon-default-add" icon={icon.cilSave} />
                                            &nbsp;Enregistrer la modification
                                        </CButton>
                                    </>
                                }
                            </CForm>
                        </CCol>
                    </CRow>
                </CContainer>
                <br /><br />
                <Footer />
            </div> : <div><NavbarBackoffice /><LoadingSpinner /></div>
        )
    );
};

export default EditExpense;
