import React, { useState, useEffect } from 'react';
import {
    CContainer, CRow, CCol, CForm,
    CFormLabel, CFormInput, CButton, CFormSelect
} from '@coreui/bootstrap-react';

import { NavbarBackoffice, Footer, LoadingSpinner } from '../../components';

import axios from 'axios';

import jwt_decode from 'jwt-decode';

import CIcon from '@coreui/icons-react';
import * as icon from '@coreui/icons';

const AddDiscount = () => {
    const BASE_URL = 'https://pi.backend.area.mg';
    const [shopDetails, setShopDetails] = useState(null);
    const [discountTypes, setDiscountTypes] = useState([]);
    const [products, setProducts] = useState([]);

    // Assuming you have stored the access token in a variable called `accessToken`
    const decodedToken = jwt_decode(localStorage.getItem('authTokens'));
    const username = decodedToken.username;

    const [isLoading, setIsLoading] = useState(false);

    // Function return the staff user with their Username
    const getStaffUserByUsername = async () => {
        try {
            const response = await axios.get(`${BASE_URL}/api/user/${username}/users`);
            return response.data;
        } catch (error) {
            console.log(error);
        };
    }

    // Get shop info
    const getShopDetails = async () => {
        const staff = await getStaffUserByUsername();
        const staff_id = staff.id;
        try {
            const response = await axios.get(`${BASE_URL}/shop/api/shop/owner/${staff_id}/`);
            if (response.data) {
                setShopDetails(response.data);
            }
            return response.data;
        } catch (error) {
            alert("vous n'êtes pas autorisé à accéder à cette page.")
            console.log(error);
        };
    };



    const [formData, setFormData] = React.useState({
        discount_type: '',
        usage_code: '',
        value_type: '',
        value: '',
        start_date: '',
        end_date: '',
        is_active: '',
        product: '',
    });

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData({
            ...formData,
            [name]: type === 'checkbox' ? checked : value,
        });
    };

    const handleSubmit = async (e) => {
        // Set loading to true when starting the function
        setIsLoading(true);
        // Get shop id
        const shop_id = shopDetails.id;

        e.preventDefault();
        try {
            // Replace 'YOUR_API_URL' with the actual URL for DiscountCreateAPIView
            await axios.post(`${BASE_URL}/shop/api/discount/${shop_id}/create/`, formData);
            // Optionally, you can add a success message or redirect the user to another page.
            alert('Création réussie!');
            setFormData({
                discount_type: '',
                usage_code: '',
                value_type: '',
                value: '',
                start_date: '',
                end_date: '',
                is_active: '',
                product: '',
            });
        } catch (error) {
            // Handle any errors that may occur during the API call
            console.error('Error creating discount:', error);
            alert('Erreur dans la création de la remise .Veuillez réessayer');
        } finally {
            // Set loading to false when the function is done, regardless of success or failure
            setIsLoading(false);
        }
    };

    useEffect(() => {
        async function fetchData() {
            const shop = await getShopDetails();
            // Fetch available discountTypes from the API
            axios.get(`${BASE_URL}/shop/api/discounts-types/`)
                .then((response) => setDiscountTypes(response.data))
                .catch((error) => console.log(error));
            // Fetch available discountTypes from the API
            axios.get(`${BASE_URL}/shop/api/products/${shop.id}/list/`)
                .then((response) => setProducts(response.data))
                .catch((error) => console.log(error));
        }
        fetchData();

    }, []);

    return (
        (shopDetails ?
            <div>
                <NavbarBackoffice />
                <CContainer className="py-4">
                    <CRow className="justify-content-center">
                        <CCol sm="8" lg="6">
                            <CForm onSubmit={handleSubmit}>
                                {isLoading ? <LoadingSpinner /> :
                                    <>
                                        <CCol xs="auto">
                                            <CFormLabel htmlFor="discount_type">Tye de remise</CFormLabel>
                                            {/* Create a dropdown to select the expense type */}
                                            <CFormSelect id="discount_type" name="discount_type" value={formData.discount_type} onChange={handleChange} required>
                                                <option value="">Choisir type de remise</option>
                                                {discountTypes.map((type) => (
                                                    <option key={type.id} value={type.id}>{type.name}</option>
                                                ))}
                                            </CFormSelect>
                                        </CCol>
                                        <CCol xs="auto">
                                            <CFormLabel htmlFor="usage_code">Usage_code :</CFormLabel >
                                            <CFormInput type="text" id="usage_code" name="usage_code" value={formData.usage_code} onChange={handleChange} required />
                                        </CCol>
                                        <CCol xs="auto">
                                            <CFormLabel htmlFor="value_type">Value Type:</CFormLabel>
                                            <CFormSelect id="value_type" name="value_type" value={formData.value_type} onChange={handleChange} required>
                                                <option value="">Choisir</option>
                                                <option value="percent">Percent</option>
                                                <option value="fixed">Fixed</option>
                                            </CFormSelect>
                                        </CCol>
                                        <CCol xs="auto">
                                            <CFormLabel htmlFor="value">Value :</CFormLabel >
                                            <CFormInput id="number" name="value" value={formData.value} onChange={handleChange} required />
                                        </CCol>
                                        <CCol xs="auto">
                                            <CFormLabel htmlFor="start_date">Date début :</CFormLabel >
                                            <CFormInput type="date" id="start_date" name="start_date" value={formData.start_date} onChange={handleChange} required />
                                        </CCol>
                                        <CCol xs="auto">
                                            <CFormLabel htmlFor="end_date">Date fin :</CFormLabel >
                                            <CFormInput type="date" id="end_date" name="end_date" value={formData.end_date} onChange={handleChange} required />
                                        </CCol>
                                        <hr />
                                        <CCol xs="auto">
                                            <CFormLabel htmlFor="is_active">Activer : </CFormLabel>
                                            &nbsp;<input type="checkbox" name="is_active" checked={formData.is_active} onChange={handleChange} />
                                        </CCol>
                                        <hr />
                                        <CCol xs="auto">
                                            <CFormLabel htmlFor="product">Selectioner un produit</CFormLabel>
                                            {/* Create a dropdown to select the expense type */}
                                            <CFormSelect id="product" name="product" value={formData.product} onChange={handleChange}>
                                                <option value="">Tous les produits</option>
                                                {products.map((prod) => (
                                                    <option key={prod.id} value={prod.id}>{prod.code_product} -- {prod.name}</option>
                                                ))}
                                            </CFormSelect>
                                        </CCol>
                                        <hr />
                                        <CButton type="submit" color="primary">
                                            <CIcon type="submit" className="icon-default-add" icon={icon.cilPlaylistAdd} />
                                            &nbsp;Ajouter dans la liste des remises
                                        </CButton>
                                    </>
                                }
                            </CForm>
                        </CCol>
                    </CRow>
                </CContainer>
                <br /><br />
                <Footer />
            </div> : <div><NavbarBackoffice /><LoadingSpinner /></div>
        )
    );
};

export default AddDiscount;
