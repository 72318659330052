import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';

import {
    CContainer,
    CCol,
    CRow,
    CTable,
    CTableHead,
    CTableRow,
    CTableHeaderCell,
    CTableBody,
    CTableDataCell,
    CTooltip,
    CImage,
} from '@coreui/bootstrap-react';

import { NavbarBackoffice, Footer, NumberFormat, FormatDate,LoadingSpinner } from '../../components';

import axios from 'axios';
import "./style.css";

import jwt_decode from 'jwt-decode';

import CIcon from '@coreui/icons-react';
import * as icon from '@coreui/icons';

const ProductList = () => {
    const BASE_URL = 'https://pi.backend.area.mg';
    const [shopDetails, setShopDetails] = useState(null);

    const navigate = useNavigate();

    // Get list of bill pending 
    const [products, setProducts] = useState([]);
    // Get the list of provider of payment
    const getListOfProducts = async () => {
        const shop = await getShopDetails();
        const shop_id = shop.id;
        try {
            const response = await axios.get(`${BASE_URL}/shop/api/products/${shop_id}/list/`);
            const products = response.data;
            setProducts(products);
            return products;

        } catch (error) {
            console.log(error);
        };
    };

    // Assuming you have stored the access token in a variable called `accessToken`
    const decodedToken = jwt_decode(localStorage.getItem('authTokens'));
    const username = decodedToken.username;


    // Function return the staff user with their Username
    const getStaffUserByUsername = async () => {
        try {
            const response = await axios.get(`${BASE_URL}/api/user/${username}/users`);
            return response.data;
        } catch (error) {
            console.log(error);
            alert("Vous ne pouvez pas accéder à cette page.");
        };
    }

    // Get shop info
    const getShopDetails = async () => {
        const staff = await getStaffUserByUsername();
        const staff_id = staff.id;
        try {
            const response = await axios.get(`${BASE_URL}/shop/api/shop/owner/${staff_id}/`);
            if (response.data) {
                setShopDetails(response.data);
            }
            return response.data;
        } catch (error) {
            console.log(error);
        };
    };

    // Create a Function to Convert Data to CSV
    const convertToCSV = async (data) => {
        const header = [
            'Code', 'Etat', 'Categorie', 'Nom', 'Fournisseur', 'Prix_Achat', 'Prix_Vente', 'Marge_Prix',
            'Quantite_Disponible', 'Quantite_Reserve', 'Etat_Quantite_Stock', 'Date_Enregistrement',
            'Derniere_Actualisation'
        ];

        const rows = data.map(prod => [
            prod.code_product,
            prod.is_active ? 'Actif' : 'Désactivé',
            prod.product_type.name,
            prod.name,
            prod.supplier ? prod.supplier.name : "",
            prod.price_purchase,
            prod.price,
            prod.price - prod.price_purchase,
            prod.quantity,
            prod.reserved_quantity,
            prod.threshold_quantity < prod.quantity ? 'Normal' : 'Critique',
            prod.created_at,
            prod.updated_at
        ]);

        return [header, ...rows].map(row => row.join(',')).join('\n');
    }

    const addProduct = () => {
        navigate('/ajouter-produit');
    }

    const deleteProduct = async (prodId) => {
        const shop = await getShopDetails();
        // Get shop_id
        const shop_id = shop.id;

        // Ask for confirmation before deleting the material
        const confirmDelete = window.confirm('Êtes-vous sûr de vouloir supprimer ce produit ?');
        if (!confirmDelete) {
            return; // If the user clicks "Cancel," do not proceed with the deletion
        }

        try {
            const response = await axios.delete(`${BASE_URL}/shop/api/product/delete/${shop_id}/${prodId}/`);
            if (response) {
                alert("La suppression reussie");
            }
        } catch (error) {
            console.log(error);
            alert("Une erreur s'est produite");
        };
    }

    // Function to download CSV file
    const downloadCSV = async () => {
        const datetime = new Date().toISOString().replace(/:/g, '-');
        const filename = `products_${datetime}.csv`;

        if (!products || products.length === 0) {
            alert("Aucun article trouvé");
            return;
        }

        try {
            const data = await convertToCSV(products);

            const csvContent = 'data:text/csv;charset=utf-8,' + encodeURIComponent(data);
            const link = document.createElement('a');
            link.setAttribute('href', csvContent);
            link.setAttribute('download', filename);
            link.click();
        } catch (error) {
            console.error("Error exporting data to CSV:", error);
            alert("Une erreur s'est produite lors de l'exportation des données en CSV.");
        }
    };

    useEffect(() => {
        const fetchProducts = async () => {
            await getListOfProducts();
        };
        getShopDetails();

        const interval = setInterval(() => {
            fetchProducts();
        }, 5000);

        return () => clearInterval(interval);


    }, [])

    return (
        (shopDetails ?
            <div>
                <NavbarBackoffice />
                <CContainer className="mt-1">
                    <CRow>
                        <CCol md={12}>
                            {products.length === 0 ? <h4 className="display-6 text-center">Merci de patienter pour les mises à jour des produits ...
                                <CTooltip content="Ajouter un produit">
                                    <CIcon
                                        onClick={addProduct}
                                        type="submit"
                                        className="custom-icon-add"
                                        icon={icon.cilPlus}
                                    />
                                </CTooltip>
                            </h4> :
                                <h4 className="display-6 text-center">Tous les produits existent dans la base ( {products.length} ) &nbsp;&nbsp;
                                    <CTooltip content="Télécharger dans un fichier csv.">
                                        <CIcon
                                            onClick={downloadCSV}
                                            type="submit"
                                            className="custom-icon-download"
                                            icon={icon.cilCloudDownload}
                                        />
                                    </CTooltip>
                                    &nbsp;&nbsp;
                                    <CTooltip content="Ajouter un produit">
                                        <CIcon
                                            onClick={addProduct}
                                            type="submit"
                                            className="custom-icon-add"
                                            icon={icon.cilPlus}
                                        />
                                    </CTooltip>
                                </h4>}
                            <p className="text-center">CAT : Categorie - FRN : Fournisseur - Qté DISPO : Quantité disponible - Qté RES : Quantité Reservé - EQS : Etat quantité stock - Dernière_MAJ : Dernière actualisation</p>
                            {products &&
                                <CTable color="dark" striped>
                                    <CTableHead>
                                        <CTableRow>
                                            <CTableHeaderCell scope="col">Image</CTableHeaderCell>
                                            <CTableHeaderCell scope="col">Code</CTableHeaderCell>
                                            <CTableHeaderCell scope="col">Etat</CTableHeaderCell>
                                            <CTableHeaderCell scope="col">CAT</CTableHeaderCell>
                                            <CTableHeaderCell scope="col">Nom</CTableHeaderCell>
                                            {/* <CTableHeaderCell scope="col">FRN</CTableHeaderCell> */}
                                            <CTableHeaderCell scope="col">Prix_achat</CTableHeaderCell>
                                            <CTableHeaderCell scope="col">Prix_vente</CTableHeaderCell>
                                            <CTableHeaderCell scope="col">Marge_prix</CTableHeaderCell>
                                            <CTableHeaderCell scope="col">Qté DISPO</CTableHeaderCell>
                                            <CTableHeaderCell scope="col">Qté RES</CTableHeaderCell>
                                            <CTableHeaderCell scope="col">EQS</CTableHeaderCell>
                                            <CTableHeaderCell scope="col">Dernière_MAJ</CTableHeaderCell>
                                            <CTableHeaderCell scope="col">Actions</CTableHeaderCell>
                                        </CTableRow>
                                    </CTableHead>
                                    <CTableBody>
                                        {products.map((prod) => (
                                            <CTableRow key={prod.id} color={((prod.threshold_quantity < prod.quantity) && prod.is_active) ? 'light' : 'danger'}>
                                                <CTableHeaderCell scope="row">
                                                    <div className="clearfix">
                                                        <CImage align="center" rounded src={BASE_URL+prod.default_image} width={50} height={50} />
                                                    </div>
                                                </CTableHeaderCell>
                                                <CTableDataCell>{prod.code_product}</CTableDataCell>
                                                <CTableDataCell>{prod.is_active ? 'Actif' : 'Désactivé'}</CTableDataCell>
                                                <CTableDataCell>{prod.product_type.name}</CTableDataCell>
                                                <CTableDataCell>{prod.name}</CTableDataCell>
                                                {/* <CTableDataCell>{prod.supplier && prod.supplier.name}</CTableDataCell> */}
                                                <CTableDataCell>
                                                    {shopDetails.country.currency.symbol} <NumberFormat number={prod.price_purchase} />
                                                </CTableDataCell>
                                                <CTableDataCell>
                                                    {shopDetails.country.currency.symbol} <NumberFormat number={prod.price} />
                                                </CTableDataCell>
                                                <CTableDataCell>
                                                    {shopDetails.country.currency.symbol} <NumberFormat number={prod.price - prod.price_purchase} />
                                                </CTableDataCell>
                                                <CTableDataCell>
                                                    <NumberFormat number={prod.quantity} />
                                                </CTableDataCell>
                                                <CTableDataCell>
                                                    <NumberFormat number={prod.reserved_quantity} />
                                                </CTableDataCell>
                                                <CTableDataCell>
                                                    {prod.threshold_quantity < prod.quantity ? 'Normal' : 'Critique'}
                                                </CTableDataCell>
                                                <CTableDataCell>
                                                    <FormatDate dateString={prod.updated_at} />
                                                </CTableDataCell>
                                                <CTableDataCell style={{ display: 'flex', margin: 'auto', backgroundColor: "inherit", border: 'none', }}>
                                                    <CTooltip content="Modifier">
                                                        <CIcon
                                                            type="submit"
                                                            onClick={() => navigate(`/modifier-produit`, {
                                                                state: {
                                                                    productId: prod.id,
                                                                }
                                                            })}
                                                            className="custom-icon-edit"
                                                            icon={icon.cilColorBorder}
                                                        />
                                                    </CTooltip>
                                                    <CTooltip content="Supprimer">
                                                        <CIcon
                                                            type="submit"
                                                            onClick={() => deleteProduct(prod.id)}
                                                            className="custom-icon-delete"
                                                            icon={icon.cilTrash}
                                                        />
                                                    </CTooltip>
                                                </CTableDataCell>
                                            </CTableRow>
                                        ))}

                                    </CTableBody>
                                </CTable>
                            }
                        </CCol>
                    </CRow>
                </CContainer>
                <br /><br />
                <Footer />
            </div> : <div><NavbarBackoffice /><LoadingSpinner /></div>
        )
    );
};

export default ProductList;