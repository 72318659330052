import React from "react";

import {
    CCard,
    CCardImage,
    CCardBody,
    CCol,
    CRow,
} from '@coreui/bootstrap-react';

import { NumberFormat } from '../../components';


const Product = ({ selectedProduct, nameProduct, code, image, price, quantity, reserved_quantity, category, shopDetails }) => {

    const BASE_URL = "https://pi.backend.area.mg";
    return (
        <div key={code}>
            <CCard className="mb-0" style={{ width: '300px', height: '120px' }} >
                <CRow className="custom-row align-items-center g-0">
                    <CCol xs={4}>
                        <CCardImage className="custom-card-image2" src={BASE_URL+image} />
                    </CCol>
                    <CCol xs={8}>
                        <CCardBody className={selectedProduct === code ? 'custom-card-body2-selected' : 'custom-card-body2'}>
                            <div>
                                <p className="custom-card-title">{nameProduct}<br/>
                                 *** Prix : {shopDetails.country.currency.symbol}  <NumberFormat number={price}/> ***<br/>
                                <em className="custom-small-text">Stock disponible : {quantity}</em><br/>
                                <em className="custom-small-text">Stock reservé: {reserved_quantity}</em><br/>
                                </p>
                            </div>
                        </CCardBody>
                    </CCol>
                </CRow>
            </CCard>
        </div>
    );
};

export default Product;
