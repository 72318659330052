// LoadingSpinner.js

import React from 'react';
import { CSpinner } from '@coreui/react';

const LoadingSpinner = () => {
  return (
    <div className="text-center">
      <CSpinner
        color="primary"
        style={{ width: '3rem', height: '3rem' }}
        className="m-4"
      />
      <p>Chargement en cours ...</p>
    </div>
  );
};

export default LoadingSpinner;
