import React, { useEffect, useState, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import jwt_decode from 'jwt-decode';

import {
    CTooltip,
    CBadge,
    CContainer,
    CNavbar,
    CNavbarBrand,
    CNavbarToggler,
    CCollapse,
    CNavbarNav,
    CNavItem,
    CNavLink,
    CDropdown,
    CDropdownToggle,
    CDropdownMenu,
    CDropdownItem,
    CDropdownDivider,
    CImage,
    CButton,
} from '@coreui/bootstrap-react';

import CIcon from '@coreui/icons-react';
import * as icon from '@coreui/icons';

const Navbar = () => {
    const BASE_URL = 'https://pi.backend.area.mg';
    const navigate = useNavigate();
    const [visible, setVisible] = useState(false);
    const [ordersPending, setOrdersPending] = useState(null);
    const [shopDetails, setShopDetails] = useState(null);
    const [countCompletedTasks, setCountCompletedTasks] = useState(0);
    const [countPendingTasks, setCountPendingTasks] = useState(0);

    const decodedToken = useMemo(() => jwt_decode(localStorage.getItem('authTokens')), []);
    const username = useMemo(() => decodedToken.username, [decodedToken]);

    const logoutUser = () => {
        localStorage.removeItem('authTokens');
        navigate('/connexion');
    };

    const handlePageHome = () => navigate('/caisse');
    const handlePageReport = () => navigate('/facture-en-attente');
    const handlePageTodolist = () => navigate('/todo-list');
    const handleChangePassword = () => navigate('/changement-mot-de-passe');
    const handleInfoUser = () => navigate('/mon-information');

    const getListOfPendingOrders = async () => {
        const shop = await getShopDetails();
        const cashier = await getCashierUser();
        try {
            const response = await axios.get(`${BASE_URL}/shop/api/orders/pending/${shop.id}/${cashier.id}/`);
            if (response.data) {
                setOrdersPending(response.data);
            }
            return response.data;
        } catch (error) {
            console.log(error);
        }
    };

    const getStaffUserByUsername = async (username) => {
        try {
            const response = await axios.get(`${BASE_URL}/api/user/${username}/users`);
            return response.data;
        } catch (error) {
            console.log(error);
            alert("Vous ne pouvez pas accéder à cette page.");
        }
    };

    const getCashierUser = async () => {
        const user_connected = await getStaffUserByUsername(username);
        const user_id = user_connected.id;
        try {
            const response = await axios.get(`${BASE_URL}/shop/api/cashier/active/${user_id}/`);
            return response.data;
        } catch (error) {
            console.log(error);
        }
    };

    const getShopDetails = async () => {
        const cashier = await getCashierUser();
        const shop_id = cashier.shop;
        try {
            const response = await axios.get(`${BASE_URL}/shop/api/shop/${shop_id}/`);
            if (response.data) {
                setShopDetails(response.data);
            }
            return response.data;
        } catch (error) {
            console.log(error);
        }
    };

    const tasksStats = async () => {
        const shop = await getShopDetails();
        const shop_id = shop.id;
        try {
            const response = await axios.get(`${BASE_URL}/shop/api/tasks/pending&completed/shop/${shop_id}/`);

            const data = response.data;
            const completed_tasks = data.completed_tasks;
            const pending_tasks = data.pending_tasks;

            setCountCompletedTasks(completed_tasks.length);
            setCountPendingTasks(pending_tasks.length);
        } catch (error) {
            console.log('Error fetching tasks data:', error);
        }
    };

    useEffect(() => {
        async function fetchData() {
            await getShopDetails();
            await getListOfPendingOrders();
            await tasksStats();
        }
        fetchData();
        const interval = setInterval(() => {
            fetchData();
        }, 5000);

        return () => clearInterval(interval);
    }, []);

    return (
        <CNavbar expand="lg" colorScheme="light" className="bg-light" placement="sticky-top">
            <CContainer fluid>
                <CNavbarBrand href='#' onClick={handlePageHome} className="clearfix">
                    {shopDetails ?
                        <CImage align="center" src={shopDetails.logo} width={40} height={40} />
                        :
                        <CImage align="center" src="/logo.png" width={40} height={40} />
                    }
                </CNavbarBrand>
                <CNavbarToggler
                    aria-label="Toggle navigation"
                    aria-expanded={visible}
                    onClick={() => setVisible(!visible)}
                />
                <CCollapse className="navbar-collapse" visible={visible}>
                    <CNavbarNav>
                        <CNavItem className="custom-nav">
                            {shopDetails ? (
                                <CTooltip content={shopDetails.description}>
                                    <CNavLink href='#'>{shopDetails.name}</CNavLink>
                                </CTooltip>
                            ) : (
                                <CTooltip content="undefined">
                                    <CNavLink href='#'>SHELL-FY</CNavLink>
                                </CTooltip>
                            )}
                        </CNavItem>

                        <CNavItem>
                            <CNavLink onClick={handlePageHome}>
                                <CButton className="custom-link-button">
                                    <CIcon type="submit" className="icon-cart" icon={icon.cilBasket} />
                                    &nbsp;ENCAISSER UNE COMMANDE</CButton>
                            </CNavLink>
                        </CNavItem>
                        <CNavItem className="custom-nav">
                            <CNavLink href='#' onClick={handlePageReport}>
                                <CIcon type="submit" className="icon-default" icon={icon.cilNotes} />
                                &nbsp;&nbsp;&nbsp;FACTURE NON PAYÉE {ordersPending && (
                                    <CBadge color="danger" shape="rounded-pill">{ordersPending.length}</CBadge>)}&nbsp;&nbsp;
                            </CNavLink>
                        </CNavItem>
                        <CNavItem className="custom-nav">
                            <CNavLink href='#' onClick={handlePageTodolist}>
                                <CIcon type="submit" className="icon-default" icon={icon.cilCalendarCheck} />
                                &nbsp;Todo List&nbsp;&nbsp;&nbsp;
                                {countPendingTasks !== 0 && <>
                                    {countCompletedTasks &&
                                        (<CBadge color="success" shape="rounded-pill">{countCompletedTasks}</CBadge>)
                                    } sur &nbsp;
                                    {countPendingTasks &&
                                        (<CBadge color="warning" shape="rounded-pill">{countCompletedTasks + countPendingTasks}</CBadge>)
                                    } </>
                                }
                                &nbsp;&nbsp;&nbsp;&nbsp;
                            </CNavLink>
                        </CNavItem>
                        <CDropdown variant="nav-item" popper={false} className="custom-nav">
                            <CDropdownToggle>
                                <CTooltip content="Mon compte">
                                    <CIcon className="icon-default" icon={icon.cilUser} />
                                </CTooltip>
                            </CDropdownToggle>
                            <CDropdownMenu>
                                <CDropdownItem href="#" onClick={handleInfoUser}>
                                    <CIcon type="submit" className="icon-info-user" icon={icon.cilAddressBook} />
                                    &nbsp;&nbsp;Info
                                </CDropdownItem>
                                <CDropdownItem href="#" onClick={handleChangePassword}>
                                    <CIcon type="submit" className="icon-info-user" icon={icon.cilFingerprint} />
                                    &nbsp;&nbsp;Changement mot de passe
                                </CDropdownItem>
                                <CDropdownDivider />
                                <CDropdownItem href='#' onClick={logoutUser}>
                                    <CIcon type="submit" className="icon-info-user" icon={icon.cilAccountLogout} />
                                    &nbsp;&nbsp;Déconnexion
                                </CDropdownItem>
                            </CDropdownMenu>
                        </CDropdown>
                    </CNavbarNav>
                </CCollapse>
                <strong><CIcon className="icon-default" icon={icon.cilCalendar} /> {new Date().toLocaleDateString()}</strong>
            </CContainer>
        </CNavbar>
    )
};

export default React.memo(Navbar);