import React, { useState, useEffect } from 'react';
import {
    CContainer,
    CCol,
    CRow,
    CFormCheck,
    CFormInput,
    CButton,
} from '@coreui/bootstrap-react';

import { Navbar, LoadingSpinner } from '../../components';

import "./style.css";
import axios from 'axios';

import jwt_decode from 'jwt-decode';

const BASE_URL = 'https://pi.backend.area.mg'

const Task = ({ taskId, taskName, status }) => {
    const [completed, setCompleted] = useState(status);

    const handleStatusChange = async (taskId) => {
        setCompleted(!completed);
        try {
            await fetch(`${BASE_URL}/shop/api/task/${taskId}/status/`, {
                method: 'POST'
            });
        } catch (error) {
            // Handle error
            console.log(error)
            alert("Erreur de connexion à la base de données");
        }
    };

    return (
        <div>
            <CFormCheck type="checkbox" checked={completed} onChange={() => handleStatusChange(taskId)} />
            <span className='custom-text-span'>&nbsp; {taskName}</span>
        </div>
    );
};


const TodoList = () => {

    const [newTask, setNewTask] = useState('');
    const [tasks, setTasks] = useState([]);
    const [filter, setFilter] = useState('all');
    const [shopDetails, setShopDetails] = useState(null);

    // Assuming you have stored the access token in a variable called `accessToken`
    const decodedToken = jwt_decode(localStorage.getItem('authTokens'));
    const username = decodedToken.username;

    const [isLoading, setIsLoading] = useState(false);


    // Function return the staff user with their Username
    const getStaffUserByUsername = async (username) => {
        try {
            const response = await axios.get(`${BASE_URL}/api/user/${username}/users`);
            return response.data;
        } catch (error) {
            console.log(error);
            alert("Vous ne pouvez pas accéder à cette page.");
        };
    }

    // Function return the staff user if cashier or not 
    const getCashierUser = async () => {
        const userConnected = await getStaffUserByUsername(username);
        const user_id = userConnected.id;
        try {
            const response = await axios.get(`${BASE_URL}/shop/api/cashier/active/${user_id}/`);
            return response.data;
        } catch (error) {
            console.log(error);
        };
    }

    // Get shop info
    const getShopDetails = async () => {
        const cashier = await getCashierUser(username);
        const shop_id = cashier.shop;
        try {
            const response = await axios.get(`${BASE_URL}/shop/api/shop/${shop_id}/`);
            if (response.data) {
                setShopDetails(response.data);
            }
            return response.data;
        } catch (error) {
            console.log(error);
        };
    };

    const handleAddClick = async () => {
        // Set loading to true when starting the function
        setIsLoading(true);

        try {
            if (newTask.trim() !== '') {
                const task = {
                    task_name: newTask,
                    completed: false,
                    shop: shopDetails,
                };
                console.log(shopDetails);

                try {
                    const response = await fetch(`${BASE_URL}/shop/api/tasks/shop/${shopDetails.id}/`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify(task)
                    });
                    console.log(response);
                    if (response.ok) {
                        const data = await response.json();
                        setTasks([...tasks, data]);
                        setNewTask('');
                    } else {
                        // Handle error
                        alert("Erreur de connexion à la base de données");
                    }
                } catch (error) {
                    // Handle error
                    console.log(error);
                }
            }
        } catch (error) {
            // Handle errors here
            console.error('Error during handleAddClick:', error);
        } finally {
            // Set loading to false when the function is done, regardless of success or failure
            setIsLoading(false);
        }
    };



    const handleFilterChange = (event) => {
        setFilter(event.target.value);
    };

    const filteredTasks = tasks.filter((task) => {
        if (filter === 'active') {
            return !task.completed;
        } else if (filter === 'completed') {
            return task.completed;
        }
        return true; // 'all' filter
    });

    const activeItemsCount = tasks.filter((task) => !task.completed).length;

    useEffect(() => {
        const fetchTasks = async () => {
            const shop = await getShopDetails();
            try {
                const response = await axios.get(`${BASE_URL}/shop/api/tasks/shop/${shop.id}/`);
                if (response.data) {
                    const tasksData = await response.data;
                    setTasks(tasksData);
                }
                return response.data;
            } catch (error) {
                // Handle error
                console.log(error)
            }
        };
        const interval = setInterval(() => {
            fetchTasks();
            getShopDetails();
        }, 1000);

        return () => clearInterval(interval);
    }, [shopDetails]);

    return (
        <>
            <Navbar />
            <CContainer className="mt-1">
                <CRow>
                    <CCol md={12} className="text-center">
                        <h1 className="display-6">Liste de(s) chose(s) à faire</h1>
                        <p className="lead">Veuillez terminer toutes les tâches avant la fin de la journée.</p>
                    </CCol>
                </CRow>
                <CRow>
                    <CCol>
                        <CFormInput
                            type="text"
                            value={newTask}
                            onChange={(e) => setNewTask(e.target.value)}
                            placeholder="Qu'est-ce qui doit être fait ?"
                        />
                    </CCol>
                    <CCol>
                        <CButton onClick={handleAddClick} className='custom-btn-add'>Ajouter</CButton>
                    </CCol>
                </CRow><br />
                <CRow>
                    <CCol>
                        <CButton value="all" onClick={handleFilterChange} className='custom-btn-all'>
                            Tous les tâches
                        </CButton>&nbsp;&nbsp;
                        <CButton value="active" onClick={handleFilterChange} className='custom-btn-active'>
                            Non fait
                        </CButton>&nbsp;&nbsp;
                        <CButton value="completed" onClick={handleFilterChange} className='custom-btn-completed'>
                            Términé(s)
                        </CButton>
                    </CCol>
                </CRow>

                {isLoading ? <LoadingSpinner /> :
                    (<><ul>
                        {filteredTasks.map((task) => (
                            <li key={task.id}>
                                <Task
                                    taskName={task.task_name}
                                    status={task.completed}
                                    taskId={task.id}
                                />
                            </li>
                        ))}
                    </ul>

                        <div>
                            <p>{activeItemsCount} Nombre de(s) tâche(s) non fait</p>
                        </div></>)}
            </CContainer>
        </>

    );
};

export default TodoList;
