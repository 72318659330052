const FormatDate = (originalDate) => {
    const date =  originalDate.dateString;
    if (typeof date !== 'string') {
        // If originalDate is not a string, return an empty string or handle it according to your use case
        return '';
    }

    const [datePart] = date.split('T');
    const [year, month, day] = datePart.split('-');
    const formattedDate = `${day}-${month}-${year}`;
    return formattedDate;
};

export default FormatDate;
