import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import {
    CContainer, CRow, CCol, CForm,
    CFormLabel, CFormInput, CButton, CFormSelect,
} from '@coreui/bootstrap-react';

import { NavbarBackoffice, Footer, LoadingSpinner } from '../../components';

import axios from 'axios';

import jwt_decode from 'jwt-decode';

import CIcon from '@coreui/icons-react';
import * as icon from '@coreui/icons';

const EditInvoice = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const BASE_URL = 'https://pi.backend.area.mg';
    const [shopDetails, setShopDetails] = useState(null);

    const decodedToken = jwt_decode(localStorage.getItem('authTokens'));
    const username = decodedToken.username;

    const invoiceNumber = location.state ? location.state.invoiceNumber : '';

    const [isLoading, setIsLoading] = useState(false);

    const getStaffUserByUsername = async () => {
        try {
            const response = await axios.get(`${BASE_URL}/api/user/${username}/users`);
            return response.data;
        } catch (error) {
            console.log(error);
        }
    };

    const getShopDetails = async () => {
        const staff = await getStaffUserByUsername();
        const staff_id = staff.id;
        try {
            const response = await axios.get(`${BASE_URL}/shop/api/shop/owner/${staff_id}/`);
            if (response.data) {
                setShopDetails(response.data);
            }
            return response.data;
        } catch (error) {
            alert("vous n'êtes pas autorisé à accéder à cette page.");
            console.log(error);
        }
    };

    const [invoice, setInvoice] = useState({});
    const [formData, setFormData] = useState({
        order: { id: '', order_type: '' },
        cashier: { code: '' },
        invoice_number: '',
        invoice_date: '',
        due_date: '',
        coupon: '',
        subtotal: '',
        discount: '',
        tax: '',
        shipping_cost: '',
        total: '',
        is_paid: '',
        status: '',
        payment: { transaction_id: '', transaction_id_third: '', card_number_third: ''},
        payment_provider: { name: '', code_partner: '', type: '' },
        rest_paid_if_coupon_not_enough: '',
        updated_at: '',
        created_at: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        setIsLoading(true);
        e.preventDefault();

        try {
            await axios.put(`${BASE_URL}/shop/api/invoices/update_status/${invoiceNumber}/`, formData);
            alert('Modification réussie!');
            navigate('/factures');
        } catch (error) {
            console.error('Error updating invoice:', error);
            alert('Erreur dans la modification de la facture. Veuillez réessayer');
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        async function fetchData() {
            await getShopDetails();
            try {
                const response = await axios.get(`${BASE_URL}/shop/api/invoice/${invoiceNumber}/`);
                if (response.data) {
                    setInvoice(response.data);
                }
            } catch (error) {
                console.log(error);
            }
        }
        fetchData();
    }, [invoiceNumber]);

    useEffect(() => {
        const order = invoice.order || {};
        const payment = invoice.payment || {};
        const payment_provider = payment.payment_provider || {};
        
        setFormData({
            order: { id: order.id || '', order_type: order.order_type || '' },
            cashier: { code: order.cashier ? order.cashier.code : '' },
            invoice_number: invoice.invoice_number || '',
            invoice_date: invoice.invoice_date || '',
            due_date: invoice.due_date || '',
            coupon: invoice.coupon || '',
            subtotal: invoice.subtotal || '',
            discount: invoice.discount || '',
            tax: invoice.tax || '',
            shipping_cost: invoice.shipping_cost || '',
            total: invoice.total || '',
            is_paid: invoice.is_paid || '',
            status: invoice.status || '',
            payment: { 
                transaction_id: payment.transaction_id || '',
                transaction_id_third: payment.transaction_id_third || '',
                card_number_third: payment.card_number_third || '',
            },
            payment_provider: {
                name: payment_provider.name || '',
                code_partner: payment_provider.code_partner || '',
                type: payment_provider.type || '',
            },
            rest_paid_if_coupon_not_enough: invoice.rest_paid_if_coupon_not_enough || '',
            updated_at: invoice.updated_at || '',
            created_at: invoice.created_at || '',
        });
    }, [invoice]);

    return (
        (shopDetails ?
            <div>
                <NavbarBackoffice />
                <CContainer className="py-4">
                    <CRow className="justify-content-center">
                        <CCol sm="8" lg="6">
                            <CForm onSubmit={handleSubmit}>
                                {isLoading ? <LoadingSpinner /> :
                                    <>
                                        <CCol xs="auto">
                                            <CFormLabel htmlFor="order">Numéro Commande :</CFormLabel >
                                            <CFormInput type="text" id="order" name="order" value={formData.order.id} disabled />
                                        </CCol>
                                        <CCol xs="auto">
                                            <CFormLabel htmlFor="order">Type Commande :</CFormLabel >
                                            <CFormInput type="text" id="order" name="order" value={formData.order.order_type} disabled />
                                        </CCol>
                                        <CCol xs="auto">
                                            <CFormLabel htmlFor="order_cashier">Code Caisse :</CFormLabel >
                                            <CFormInput type="text" id="order_cashier" name="order_cashier" value={formData.cashier.code} disabled />
                                        </CCol>
                                        <CCol xs="auto">
                                            <CFormLabel htmlFor="invoice_number">Numéro Facture :</CFormLabel >
                                            <CFormInput type="text" id="invoice_number" name="invoice_number" value={formData.invoice_number} disabled />
                                        </CCol>
                                        <CCol xs="auto">
                                            <CFormLabel htmlFor="invoice_date">Date Facture :</CFormLabel >
                                            <CFormInput type="text" id="invoice_date" name="invoice_date" value={formData.invoice_date} disabled />
                                        </CCol>
                                        <CCol xs="auto">
                                            <CFormLabel htmlFor="due_date">Date d'échéance :</CFormLabel >
                                            <CFormInput type="text" id="due_date" name="due_date" value={formData.due_date} disabled />
                                        </CCol>
                                        <CCol xs="auto">
                                            <CFormLabel htmlFor="subtotal">Sous-Total en {shopDetails.country.currency.symbol} :</CFormLabel >
                                            <CFormInput type="number" id="subtotal" name="subtotal" value={formData.subtotal} disabled />
                                        </CCol>
                                        {formData.coupon &&
                                            <CCol xs="auto">
                                                <CFormLabel htmlFor="coupon">Coupon :</CFormLabel >
                                                <CFormInput type="number" id="coupon" name="coupon" value={formData.coupon.code + ' - ' +
                                                    shopDetails.country.currency.symbol + ' ' + formData.coupon.amount} disabled />
                                            </CCol>
                                        }
                                        <CCol xs="auto">
                                            <CFormLabel htmlFor="rest_paid_if_coupon_not_enough">Reste si un cas coupon insufisant</CFormLabel >
                                            <CFormInput type="number" id="rest_paid_if_coupon_not_enough" name="rest_paid_if_coupon_not_enough" value={invoice.rest_paid_if_coupon_not_enough} disabled />
                                        </CCol>
                                        <CCol xs="auto">
                                            <CFormLabel htmlFor="tax">Tax en {shopDetails.country.currency.symbol} :</CFormLabel >
                                            <CFormInput type="number" id="tax" name="tax" value={formData.tax} disabled />
                                        </CCol>
                                        <CCol xs="auto">
                                            <CFormLabel htmlFor="shipping_cost">Frais de livraison en {shopDetails.country.currency.symbol} :</CFormLabel >
                                            <CFormInput type="number" id="shipping_cost" name="shipping_cost" value={formData.shipping_cost} disabled />
                                        </CCol>
                                        <CCol xs="auto">
                                            <CFormLabel htmlFor="total">Total payé en {shopDetails.country.currency.symbol} :</CFormLabel >
                                            <CFormInput type="number" id="total" name="total" value={formData.total} disabled />
                                        </CCol>
                                        <CCol xs="auto">
                                            <CFormLabel htmlFor="is_paid">Etat :</CFormLabel >
                                            <CFormInput type="text" id="is_paid" name="is_paid" value={formData.is_paid ? 'Payé' : 'Non Payé'} disabled />
                                        </CCol>
                                        <hr />
                                        <CCol xs="auto">
                                            <CFormLabel htmlFor="status">Status :</CFormLabel>
                                            <CFormSelect id="status" name="status" value={formData.status} onChange={handleChange} required>
                                                {['complete', 'pending', 'failed', 'canceled'].map(optionStatus => (
                                                    (invoice.status !== optionStatus || formData.status === optionStatus) && (
                                                        <option key={optionStatus} value={optionStatus}>
                                                            {optionStatus === 'complete' ? 'Complete' :
                                                                optionStatus === 'pending' ? 'En attente validation' :
                                                                    optionStatus === 'failed' ? 'Echec' : 'Annulé'}
                                                        </option>
                                                    )
                                                ))}
                                            </CFormSelect>
                                        </CCol>
                                        <hr />
                                        <CCol xs="auto">
                                            <CFormLabel htmlFor="payment">Reference de Transaction :</CFormLabel >
                                            <CFormInput type="text" id="payment" name="payment" value={formData.payment.transaction_id} disabled />
                                        </CCol>
                                        <CCol xs="auto">
                                            <CFormLabel htmlFor="payment_provider">Fournisseur de paiement :</CFormLabel >
                                            <CFormInput type="text" id="payment_provider" name="payment_provider" value={formData.payment_provider ? formData.payment_provider.name : ''} disabled />
                                        </CCol>
                                        <CCol xs="auto">
                                            <CFormLabel htmlFor="payment">Code Fournisseur de paiement : </CFormLabel >
                                            <CFormInput type="text" id="payment_provider" name="payment_provider" value={formData.payment_provider ? formData.payment_provider.code_partner : ''} disabled />
                                        </CCol>
                                        <CCol xs="auto">
                                            <CFormLabel htmlFor="payment_provider">Type Fournisseur de paiement : </CFormLabel >
                                            <CFormInput type="text" id="payment_provider" name="payment_provider" value={formData.payment_provider ? formData.payment_provider.type : ''} disabled />
                                        </CCol>
                                        <CCol xs="auto">
                                            <CFormLabel htmlFor="transaction_id_third">External Transaction ID : </CFormLabel >
                                            <CFormInput type="text" id="transaction_id_third" name="transaction_id_third" value={formData.payment.transaction_id_third} disabled />
                                        </CCol>
                                        <CCol xs="auto">
                                            <CFormLabel htmlFor="card_number_third">Numéro de compte si paiement carte ou mobile money : </CFormLabel >
                                            <CFormInput type="text" id="card_number_third" name="card_number_third" value={formData.payment.card_number_third} disabled />
                                        </CCol>
                                        <CCol xs="auto">
                                            <CFormLabel htmlFor="rest_paid_if_coupon_not_enough">Reste payé dans le cas le coupon n'est pas suffisant : </CFormLabel >
                                            <CFormInput type="text" id="rest_paid_if_coupon_not_enough" name="rest_paid_if_coupon_not_enough" value={formData.rest_paid_if_coupon_not_enough} disabled />
                                        </CCol>
                                        <CCol xs="auto">
                                            <CFormLabel htmlFor="created_at">Date de création : </CFormLabel >
                                            <CFormInput type="text" id="created_at" name="created_at" value={formData.created_at} disabled />
                                        </CCol>
                                        <CCol xs="auto">
                                            <CFormLabel htmlFor="updated_at">Date de modification : </CFormLabel >
                                            <CFormInput type="text" id="updated_at" name="updated_at" value={formData.updated_at} disabled />
                                        </CCol>

                                        <hr />
                                        <CButton type="submit" color="primary">
                                            <CIcon type="submit" className="icon-default-add" icon={icon.cilSave} />
                                            &nbsp;Enregistrer la modification
                                        </CButton>
                                    </>
                                }
                            </CForm>
                        </CCol>
                    </CRow>
                </CContainer>
                <br /><br />
                <Footer />
            </div> : <div><NavbarBackoffice /><LoadingSpinner /></div>
        )
    );
};

export default EditInvoice;
