import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from 'react-router-dom';

import moment from 'moment';

import { BlobProvider, PDFViewer } from '@react-pdf/renderer';

import {
    CContainer,
    CCol,
    CRow,
    CFormInput,
    CTable,
    CTableHead,
    CTableRow,
    CTableHeaderCell,
    CTableDataCell,
    CTableBody,
    CFormSelect,
    CButton,
    CInputGroup,
    CInputGroupText,
    CForm,
} from '@coreui/bootstrap-react';

import { CTooltip } from '@coreui/react';

import CIcon from '@coreui/icons-react';
import * as icon from '@coreui/icons';

import { Category, Product, Navbar, NumberFormat, Footer, RecipeBillPDF, LoadingSpinner, LoadingSpinner2 } from '../../components';

import './style.css';

import axios from 'axios';

import jwt_decode from 'jwt-decode';

function Cashier() {
    const location = useLocation();
    const [isLoading, setIsLoading] = useState(false);
    const [isLoading2, setIsLoading2] = useState(false);

    // Save client's informations
    const [nameClient, setNameClient] = useState(location.state && location.state.nameClient ? location.state.nameClient : "UNKNOWN");
    const [contactClient, setContactClient] = useState(location.state && location.state.contactClient ? location.state.contactClient : "+261 33 00 000 00");
    const [emailClient, setEmailClient] = useState(location.state && location.state.emailClient ? location.state.emailClient : "clientXX@gmail.com");
    const [addressClient, setAddressClient] = useState(location.state && location.state.addressClient ? location.state.addressClient : "SHELL Tsimavaokely");

    // Set empty by default the cart of ProductList of client if new
    const [cartList, setCartList] = useState([]);
    // Set null by default the cart of Client if new
    const orderId = location.state && location.state.orderId ? location.state.orderId : null; // Use null as the default value if cart is not present
    const [existingOrder, setExistingOrder] = useState(true);
    const [newOrder, setNewOrder] = useState(true);
    const [inputDisabled, setInputDisabled] = useState(false);

    const BASE_URL = 'https://pi.backend.area.mg'
    const navigate = useNavigate();

    // Set bill number
    const [billNumber, setBillNumber] = useState(null);

    // Save all in object
    const [client, setClient] = useState([]);

    const [cashierConnected, setCashierConnected] = useState(null)
    const [selectedProductType, setSelectedProductType] = useState(0); // Set the initial selected product type ID
    const [selectedProduct, setSelectedProduct] = useState(0); // Set the initial selected product ID

    const [query, setQuery] = useState('');
    const [results, setResults] = useState([]);
    const [productTypes, setProductTypes] = useState([]);

    const [taxValue, setTaxValue] = useState(0)
    const [typeOfTaxValue, setTypeOfTaxValue] = useState("")
    const [typeOfTax, setTypeOfTax] = useState("")

    const [methodPayment, setMethodPayment] = useState([]);


    // Set visible btn remove cart
    const [visibleRemoveCart, setVisibleRemoveCart] = useState(false)
    // Set visible the mode of payment
    const [visibleModePayment, setVisibleModePayment] = useState(true);
    // Coupon check
    const [visibleGroupCheckCoupon, setVisibleGroupCheckCoupon] = useState(false);
    const [visibleGroupCheckCouponIfInsufficient, setVisibleGroupCheckCouponIfInsufficient] = useState(false);
    // Button paid if cash - save if credit card or mobile money - check if coupon
    const [nameBtn, setNameBtn] = useState("Payer");
    // Set visible btn payment
    const [visibleBtnPaid, setVisibleBtnPaid] = useState(false);
    // Set default amount or coupon value
    const [amountOrCouponOrRef, setAmountOrCouponOrRef] = useState("");
    // Placeholder input of - entered the money if cash - entered the REFERENCE if credit card or mobile money or coupon
    const [placeholder, setPlaceholder] = useState("Montant donné");
    // Coupon amount
    const [couponAmount, setCouponAmount] = useState(0);
    // Money back to customer
    const [visibleBackAmount, setVisibleBackAmount] = useState(true);
    const [backAmount, setBackAmount] = useState(0);
    // Rest of money to be paid when using insuffisant coupon
    const [restAmount, setRestAmount] = useState(0);
    // Check avaible amount in the coupon
    const [checkAmount, setCheckAmount] = useState("Insuffisant");
    // Set visible button when the cashier want to save but not paid directly
    const [visibleBtnSave, setVisibleBtnSave] = useState(false);
    // Will show this when payment done
    const [visibleGroupNotifPaid, setVisibleGroupNotifPaid] = useState(false);
    // Will show this when failure
    const [visibleGroupNotifNotPaid, setVisibleGroupNotifNotPaid] = useState(false);
    // Mode de payment 
    const [modePayment, setModePayment] = useState("Cash");
    // Provider of payment 
    const [providerPayment, setProviderPayment] = useState(null);
    // Shop details
    const [shopDetails, setShopDetails] = useState(null);

    // Get the user connected
    // Assuming you have stored the access token in a variable called `accessToken`
    const decodedToken = jwt_decode(localStorage.getItem('authTokens'));
    const username = decodedToken.username;

    // Rest of money if coupon not enough
    const [refTransactionOfRestMoney, setRefTransactionOfRestMoney] = useState('Cash');
    const getRefTransactionOfRestMoney = async (e) => {
        let refTransID = e.target.value;
        if (refTransID !== "") {
            setRefTransactionOfRestMoney(refTransID);
        }
    }

    // If change the client's information
    const handleNameClient = async (e) => {
        const name = e.target.value;
        if (name !== "") {
            setNameClient(name)
        }
    }
    const handleContactClient = async (e) => {
        const contact = e.target.value;
        if (contact !== "") {
            setContactClient(contact)
        }
    }
    const handleEmailClient = async (e) => {
        const email = e.target.value;
        if (email !== "") {
            setEmailClient(email)
        }
    }
    const handleAddressClient = async (e) => {
        const address = e.target.value;
        if (address !== "") {
            setAddressClient(address)
        }
    }



    // if change the mode of payment
    const handelModePayment = async (e) => {
        const code_partner = e.target.value;
        const payment_provider = await getPaymentProviderDetails(code_partner);
        const type = payment_provider.type;

        setModePayment(type);
        setProviderPayment(payment_provider);
        if (type === "Cash") {
            setVisibleModePayment(true);
            setVisibleGroupCheckCoupon(false);
            setVisibleGroupCheckCouponIfInsufficient(false);
            setNameBtn("Payer");
            setVisibleBtnPaid(false);
            setPlaceholder("Montant donné");
            setCouponAmount(0);
            setVisibleBackAmount(true);
            setBackAmount(0);
            setRestAmount(0);
            setCheckAmount("");
            setVisibleGroupNotifPaid(false);
            setVisibleGroupNotifNotPaid(false);
        };
        if (type === "Mobilemoney") {
            setVisibleModePayment(true);
            setVisibleGroupCheckCoupon(false);
            setVisibleGroupCheckCouponIfInsufficient(false);
            setNameBtn("Enregistrer");
            setVisibleBtnPaid(false);
            setPlaceholder("Reference transaction ");
            setCouponAmount(0);
            setVisibleBackAmount(false);
            setBackAmount(0);
            setRestAmount(0);
            setCheckAmount("");
            setVisibleGroupNotifPaid(false);
            setVisibleGroupNotifNotPaid(false);
        };
        if (type === "Creditcard") {
            setVisibleModePayment(true);
            setVisibleGroupCheckCoupon(false);
            setVisibleGroupCheckCouponIfInsufficient(false);
            setNameBtn("Enregistrer");
            setVisibleBtnPaid(false);
            setPlaceholder("Reference transaction ");
            setCouponAmount(0);
            setVisibleBackAmount(false);
            setBackAmount(0);
            setRestAmount(0);
            setCheckAmount("");
            setVisibleGroupNotifPaid(false);
            setVisibleGroupNotifNotPaid(false);
        };
        if (type === "Coupon") {
            setVisibleModePayment(true);
            setVisibleGroupCheckCoupon(true);
            setVisibleGroupCheckCouponIfInsufficient(false);
            setNameBtn("Payer via coupon");
            setVisibleBtnPaid(false);
            setPlaceholder("Code coupon");
            setCouponAmount(0);
            setVisibleBackAmount(false);
            setBackAmount(0);
            setRestAmount(0);
            setCheckAmount("");
            setVisibleGroupNotifPaid(false);
            setVisibleGroupNotifNotPaid(false);
        };
    }

    // Auto Calcul if there some change with cart like remove or add
    const autoCalcul = async (givenAmountOrCoupon, modePayment, totalAmount) => {
        if (cartList.length <= 0) {
            setVisibleBackAmount(false);
        }

        if (modePayment === "Cash") {
            setVisibleGroupCheckCouponIfInsufficient(false);
            setVisibleGroupCheckCoupon(false);
            if (isNaN(givenAmountOrCoupon) || givenAmountOrCoupon === "" || givenAmountOrCoupon < totalAmount) {
                setVisibleBtnPaid(false);
                setBackAmount(0);
                return;
            } else {
                setVisibleBtnPaid(true);
                setBackAmount(givenAmountOrCoupon - totalAmount);
                return;
            }
        }
        if (modePayment === "Coupon") {
            if (givenAmountOrCoupon === "") {
                setVisibleGroupCheckCoupon(false);
            } else {
                setVisibleGroupCheckCoupon(true);
            }
            setVisibleGroupCheckCouponIfInsufficient(false);
            await handleCheckCoupon(givenAmountOrCoupon);
            setVisibleBackAmount(false);
            return;
        }

        if (modePayment === "Mobilemoney" || modePayment === "Creditcard") {
            setVisibleBackAmount(false);
            return;
        }
    }

    // Function return the staff user with their Username
    const getStaffUserByUsername = async (username) => {
        try {
            const response = await axios.get(`${BASE_URL}/api/user/${username}/users`);
            return response.data;
        } catch (error) {
            console.log(error);
            alert("Vous ne pouvez pas accéder à cette page.");
        };
    }

    // Function return the staff user if cashier or not 
    const getCashierUser = async (user_id) => {
        try {
            const response = await axios.get(`${BASE_URL}/shop/api/cashier/active/${user_id}/`);
            return response.data;
        } catch (error) {
            console.log(error);
        };
    }

    // Function to get product type
    const handleProductTypeChange = (productTypeId) => {
        try {
            setQuery('');
            setSelectedProductType(productTypeId);
        } catch (error) {
            // Handle errors here
            console.error('Error during handleProductTypeChange:', error);
        }
    };

    // Handle to fecth discount product specific
    const handleCheckDiscount = async (productId) => {
        try {
            const response = await axios.get(`${BASE_URL}/shop/api/discount/${productId}/discounts/`);
            return response.data;
        } catch (error) {
            console.error(error);
            return 0; // Return a default discount value or handle the error as needed
        }
    };

    // Handle to fecth discount on shop or all shop if available  
    const handleShopCheckDiscount = async () => {
        try {
            const user_connected = await getStaffUserByUsername(username); // Object
            const cashier = await getCashierUser(user_connected.id); // Object
            const shop = await getShopDetails(cashier.shop); // Object

            const response = await axios.get(`${BASE_URL}/shop/api/discount/${shop.id}/`);
            return response.data;
        } catch (error) {
            console.error(error);
        }
    };

    // Function to get tax value updated on DB
    const getUpdatedTaxValue = async () => {
        const user_connected = await getStaffUserByUsername(username); // Object
        const cashier = await getCashierUser(user_connected.id); // Object
        const shop = await getShopDetails(cashier.shop); // Object
        const zone_id = shop.state.id;
        // Tax calculation
        // Initiate the tax 
        let tax;
        try {
            const response = await axios.get(`${BASE_URL}/shop/api/tax/${zone_id}/zone/`);
            const totalAmount = calculateTotalAmount(cartList);
            const taxZoneType = response.data;
            if (taxZoneType) {
                let taxValue;
                const type_rate = taxZoneType.type_rate;
                setTypeOfTax(taxZoneType.tax_type.name);
                if (type_rate === "Pct") {
                    taxValue = taxZoneType.rate_pct / 100;
                    tax = totalAmount * taxValue;
                    setTaxValue(tax);
                    setTypeOfTaxValue(taxZoneType.rate_pct + " %");
                } else {
                    taxValue = taxZoneType.rate_fixed;
                    tax = totalAmount - taxValue;
                    setTaxValue(tax);
                    setTypeOfTaxValue(taxValue + " Montant Fixé");
                }
            } else {
                tax = 0;
                setTaxValue(tax);
            }
            return tax;

        } catch (error) {
            console.log(error);
        };
    }


    // Function to add product on the list
    const handleAddToCart = async (productId, code_product, name, price, stock_available, stock_reserved) => {
        // Set loading to true when starting the function
        setIsLoading(true);

        setSelectedProduct(code_product);
        const quantity_product_available = stock_available - stock_reserved;

        let qt_reserved;
        if (quantity_product_available > 0) {
            qt_reserved = stock_reserved + 1;
        } else {
            qt_reserved = stock_reserved;
        }

        // Check if the product is already in the cart
        const existingProduct = cartList.find((item) => item.code_product === code_product);

        try {
            if (existingProduct) {
                // If the product is already in the cart, update the quantity
                const updatedCart = cartList.map((item) => {
                    if (item.code_product === code_product && item.stock_reserved <= item.stock_available) {

                        const qt_stock_available = item.stock_available - item.stock_reserved;
                        const stock_reserved_updated = Math.min(item.stock_reserved + 1, stock_available);

                        if (quantity_product_available === 0 || qt_stock_available === 0) {
                            alert("Notre stock disponible est désormais dépassé.");
                        }
                        const updatedQuantity = Math.min(item.quantity + 1, item.quantity + qt_stock_available);

                        return { ...item, quantity: updatedQuantity, stock_reserved: stock_reserved_updated };
                    }


                    return item;

                });

                setCartList(updatedCart);
                if (orderId && !newOrder) {
                    await updateProductReservedQuantity(productId, qt_reserved, stock_available);
                    await autoSaveOrder(orderId, updatedCart);
                };

            } else {
                parseFloat(price);
                const price_default = price;
                let discount = 0;

                const shopDiscount = await handleShopCheckDiscount();

                if (shopDiscount && (shopDiscount.shop.id === cashierConnected.shop || shopDiscount.shop === null)) {
                    discount = shopDiscount;
                } else {
                    discount = await handleCheckDiscount(productId);
                };

                if (discount !== 0) {
                    const value_type = discount.value_type;
                    const value = discount.value;
                    if (value_type === "percent") {
                        price -= (price * value) / 100;
                    } else {
                        price -= value;
                    }
                };

                if (quantity_product_available === 0) {
                    alert("Notre stock disponible est désormais dépassé.");
                } else {
                    const product = {
                        productId: productId,
                        code_product: code_product,
                        name: name,
                        quantity: 1,
                        price: price,
                        stock_available: stock_available,
                        stock_reserved: stock_reserved + 1,
                        discount: discount,
                        price_default: parseFloat(price_default),
                        discount_price: price_default - price,
                    };
                    const updatedCart = [...cartList, product];
                    setCartList(updatedCart);
                    if (orderId && !newOrder) {
                        await updateProductReservedQuantity(productId, qt_reserved, stock_available);
                        await autoSaveOrder(orderId, updatedCart);
                    }
                }
            }
        } catch (error) {
            // Handle errors here
            console.error('Error during handleAddToCart:', error);
        } finally {
            // Set loading to false when the function is done, regardless of success or failure
            setIsLoading(false);
        }
    };


    // Function to remove product on the list
    const handleRemoveFromCart = async (productId, code_product, stock_reserved, quantity, stock_available) => {
        // Do not load the spinner if new cart
        if (!newOrder) {
            setIsLoading(true);
        }

        try {
            const calcul_dif = stock_reserved - quantity;
            const updatedReservedQuantity = Math.max(calcul_dif, 0);

            const updatedCart = cartList.filter((item) => item.code_product !== code_product);
            setCartList(updatedCart);
            autoCalcul(amountOrCouponOrRef, modePayment);

            if (orderId && !newOrder) {
                await updateProductReservedQuantity(productId, updatedReservedQuantity, stock_available);
                await autoSaveOrder(orderId, updatedCart);
            }
        } catch (error) {
            // Handle errors here
            console.error('Error during handleRemoveFromCart:', error);
        } finally {
            setIsLoading(false);
        }
    };


    // Function to update the quantity of product
    const handleUpdateQuantity = async (productId, code_product, stock_reserved, update_qt, stock_available, current_qt) => {
        const quantity_product_available = stock_available - stock_reserved;
        let qt_reserved;

        // Do not load the spinner if new cart
        if (!newOrder) {
            setIsLoading(true);
        }

        try {

            if (quantity_product_available === 0 && current_qt < update_qt) {
                qt_reserved = stock_reserved;
                alert("Notre stock disponible est désormais dépassé.");
            } else if (update_qt > 0 && stock_available >= stock_reserved) {
                qt_reserved = stock_reserved + (update_qt - current_qt);
                const updatedCart = cartList.map((item) => {
                    if (item.code_product === code_product) {
                        const updatedQuantity = update_qt;
                        return { ...item, quantity: updatedQuantity, stock_reserved: qt_reserved };
                    }
                    return item;
                });

                setCartList(updatedCart);
                if (orderId && !newOrder && update_qt !== 0) {
                    await updateProductReservedQuantity(productId, qt_reserved, stock_available);
                    await autoSaveOrder(orderId, updatedCart);
                }
            }

        } catch (error) {
            // Handle errors here
            console.error('Error during handleUpdateQuantity:', error);
        } finally {
            setIsLoading(false);
        }

    };



    // Remove all product in cart
    const handleAllRemoveFromCart = async () => {
        if (!newOrder) {
            setIsLoading(true);
        }

        const confirmDelete = window.confirm('Êtes-vous sûr de vouloir effacer tous les articles ?');
        try {
            if (confirmDelete) {
                if (orderId && !newOrder) {
                    await Promise.all(
                        cartList.map(async (item) => {
                            const productId = item.productId;
                            const stock_reserved = item.stock_reserved - item.quantity;
                            const updatedReservedQuantity = Math.max(stock_reserved, 0);
                            const stock_available = item.stock_available;
                            await updateProductReservedQuantity(productId, updatedReservedQuantity, stock_available);
                        })
                    );

                };
                const updatedCartList = [];
                setCartList(updatedCartList);
                if (orderId && !newOrder) {
                    autoSaveOrder(orderId, updatedCartList);
                }
            }
        } catch (error) {
            // Handle errors here
            console.error('Error during handleAllRemoveFromCart:', error);
        } finally {
            setIsLoading(false);
        }
    };



    // Product count
    const countProductAdded = () => {
        let totalProduct = 0;
        for (const product of cartList) {
            totalProduct += product.quantity;
        }
        return totalProduct;
    };

    // Total amount of cart list without discount
    const calculateTotalAmount = (cart_list) => {
        let totalAmount = 0;
        for (const product of cart_list) {
            totalAmount += product.price * product.quantity;
        }
        return totalAmount;
    };

    // Total discount
    const calculateDiscount = (cart_list) => {
        let total_discount = 0
        for (const product of cart_list) {
            total_discount += product.discount_price * product.quantity;
        }
        return total_discount;
    }

    const handleSearch = async (e) => {
        // Set loading to true when starting the function
        setIsLoading2(true);

        setSelectedProductType(0);
        const shop_id = cashierConnected.shop;

        e.preventDefault();

        try {
            const response = await axios.get(`${BASE_URL}/shop/api/product/search/`, {
                params: { query, shop_id },
            });
            setResults(response.data.results);
        } catch (error) {
            // Handle errors here
            console.error('Error during handleSearch:', error);
        } finally {
            // Set loading to false when the function is done, regardless of success or failure
            setIsLoading2(false);
        }
    };

    // Function check maintenance service ongoing and block all pages
    const checkMaintenanceService = async () => {
        try {
            const response = await axios.get(`${BASE_URL}/shop/api/maintenance/`);
            if (response.data) {
                // const date_scheduled = response.data.date_scheduled
                // const date_completed = response.data.date_scheduled
                alert("Indisponibilité temporaire pour cause de maintenance. Veuillez revenir dans un instant ")
                localStorage.removeItem('authTokens');
                navigate('/connexion');
            }
            return response.data;
        } catch (error) {
            console.log(error);
        };
    };


    // Get the list of provider of payment
    const getListOfProviderPayment = async () => {
        try {
            const response = await axios.get(`${BASE_URL}/shop/api/payment-provider/`);
            if (response.data) {
                setMethodPayment(response.data);
            }
            return response.data;
        } catch (error) {
            console.log(error);
        };
    };


    // Fetch coupon by code
    const handleCheckCoupon = async (code_coupon) => {
        try {
            const shop_id = cashierConnected.shop;
            const response = await axios.get(`${BASE_URL}/shop/api/coupon/check/${shop_id}/${code_coupon}/`);
            const coupon = response.data;
            if (coupon) {
                if (coupon.shop === shop_id || coupon.shop === null) {
                    setCouponAmount(coupon.amount);
                    if (coupon.amount < totalAmount) {
                        setCheckAmount("insuffisant");
                        setVisibleGroupCheckCouponIfInsufficient(true);
                        // calcul of rest amount
                        const restMoney = totalAmount - coupon.amount;
                        setRestAmount(restMoney);
                        setVisibleBtnPaid(true);
                        return coupon;
                    }
                    setVisibleGroupCheckCouponIfInsufficient(false);
                    setCheckAmount("correct");
                    setRestAmount(0);
                    setVisibleBtnPaid(true);
                    return coupon;
                }
                return null;
            }
        } catch (error) {
            console.log(error);
            setCouponAmount(0);
            setCheckAmount("incorrect")
        };
    }

    // Update reserved quantity
    const updateProductReservedQuantity = async (productId, reserved_quantity, stock_available) => {

        try {
            const response = await fetch(`${BASE_URL}/shop/api/product/${productId}/update/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(
                    {
                        reserved_quantity: reserved_quantity,
                        stock_available: stock_available
                    }
                ),
            });
            await response.json();

        } catch (error) {
            console.error(error);
        }
    };

    const totalAmount = calculateTotalAmount(cartList);
    const countProduct = countProductAdded();
    const total_discount = calculateDiscount(cartList);

    // Update existing order if changed
    const autoSaveOrder = async (order_id, cart_list) => {

        const calcul_total_amount = calculateTotalAmount(cart_list);
        const calcul_total_discount = calculateDiscount(cart_list);
        // Get connected cashier
        const user_connected = await getStaffUserByUsername(username); // Object

        const cashier = await getCashierUser(user_connected.id); // Object
        const cashier_id = cashier.id;

        try {
            const response = await fetch(`${BASE_URL}/shop/api/cart-auto-save/${order_id}/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    cart_items: cart_list,
                    totalAmount: calcul_total_amount,
                    totalDiscount: calcul_total_discount,
                    cashier: cashier_id,
                    shop: shopDetails.id,
                }),
            });
            //const data = await response.json();

            if (!response.ok) {
                setVisibleGroupNotifNotPaid(true);
                throw new Error('Failed to add items to cart');
            } else {
                alert("La mise à jour du panier est réussie")
            }
            // Response from the backend
            //console.log(data);
        } catch (error) {
            console.error(error);
        }
    };

    // Save order
    const handleSaveOrder = async () => {
        // Set loading to true when starting the function
        setIsLoading(true);

        // Save all data in order table
        // const coupon = await handleCheckCoupon(amountOrCouponOrRef);

        // Get connected cashier
        const user_connected = await getStaffUserByUsername(username); // Object


        const cashier = await getCashierUser(user_connected.id); // Object
        const cashier_id = cashier.id;
        const shop = await getShopDetails(cashier.shop); // Object

        try {
            const response = await fetch(`${BASE_URL}/shop/api/cart-save/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    cart_items: cartList,
                    client: client,
                    totalAmount: totalAmount,
                    totalDiscount: total_discount,
                    cashier: cashier_id,
                    shop: shop.id,
                }),
            });
            await response.json();
            //console.log(data); // Response from the backend

            if (!response.ok) {
                setVisibleGroupNotifNotPaid(true);
                throw new Error('Failed to add items to cart');
            } else {
                cartList.forEach((item) => {
                    let productId = item.productId;
                    let stock_reserved = item.stock_reserved;
                    let stock_available = item.stock_available;
                    updateProductReservedQuantity(productId, stock_reserved, stock_available);
                });

                setCartList([]);

                // Set all info client by default
                setNameClient("UNKNOWN");
                setContactClient("+261 33 00 000 00");
                setEmailClient("clientXX@gmail.com");
                setAddressClient("SHELL Tsimavaokely");
                alert("Enregistrement panier réussi");
            }
        } catch (error) {
            // Handle errors here
            console.error('Error during handleSaveOrder:', error);
        } finally {
            // Set loading to false when the function is done, regardless of success or failure
            setIsLoading(false);
        }
    };

    // Function to update all reserved quantity after payment
    const updateReservedAndQuantityProduct = async (cart_list) => {
        // MAJ product
        if (cart_list) {
            cart_list.forEach((item) => {
                let productId = item.productId;
                let stock_reserved = item.stock_reserved - item.quantity;
                let stock_available = item.stock_available - item.quantity;
                updateProductReservedQuantity(productId, stock_reserved, stock_available);
            });
        }
    }

    // Function to get coupon via code before set in invoice details 
    // Fetch coupon by code

    // Function to update coupon if used
    const updateCouponIfUsed = async (code_coupon) => {
        const shop_id = cashierConnected.shop;
        const response = await axios.post(`${BASE_URL}/shop/api/coupon/check/${shop_id}/${code_coupon}/`);
        console.log(response);
        return response.data;
    }

    // Function to get shop details 
    const getShopDetails = async (shop_id) => {
        const response = await axios.get(`${BASE_URL}/shop/api/shop/${shop_id}/`);
        const shop = response.data;
        setShopDetails(shop);
        return response.data;
    }

    // Function to get payment provider by name
    const getPaymentProviderDetails = async (code_partner) => {
        const response = await axios.get(`${BASE_URL}/shop/api/payment/${code_partner}/provider/`);
        return response.data;
    }

    // Function to get order
    const getOrderById = async (order_id) => {
        const response = await axios.get(`${BASE_URL}/shop/api/order/${order_id}/`);
        return response.data;
    }

    // Function to save the order before pay
    const saveOrderBeforePay = async () => {
        // Set loading to true when starting the function
        setIsLoading(true);
        // Get connected cashier
        const user_connected = await getStaffUserByUsername(username); // Object

        const cashier = await getCashierUser(user_connected.id); // Object
        const cashier_id = cashier.id;

        try {
            const response = await fetch(`${BASE_URL}/shop/api/cart-save/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    cart_items: cartList,
                    client: client,
                    totalAmount: totalAmount,
                    totalDiscount: total_discount,
                    cashier: cashier_id,
                    shop: shopDetails.id,
                }),
            });
            const data = await response.json();
            if (response.ok) {
                cartList.forEach((item) => {
                    let productId = item.productId;
                    let stock_reserved = item.stock_reserved + item.quantity;
                    let stock_available = item.stock_available
                    updateProductReservedQuantity(productId, stock_reserved, stock_available);
                });
            }

            return data;

        } catch (error) {
            setVisibleGroupNotifNotPaid(true);
            console.error('Error during saveOrderBeforePay:', error);
            return null;
        } finally {
            // Set loading to false when the function is done, regardless of success or failure
            setIsLoading(false);
        }
    };

    // Function to update order if payment is successful
    const updateOrderIfPaymentOk = async (order_id, coupon_id) => {
        try {
            const response = await fetch(`${BASE_URL}/shop/api/order/${order_id}/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    order_id: order_id,
                    coupon_id: coupon_id,
                }),
            });
            const data = await response.json();

            if (!response.ok) {
                setVisibleGroupNotifNotPaid(true);
            }
            console.log(data);
            return data;

        } catch (error) {
            setVisibleGroupNotifNotPaid(true);
            console.error(error);
        }
    }

    // Function to update payment if successful
    const updatePaymentStatus = async (payment_id) => {
        try {
            const response = await fetch(`${BASE_URL}/shop/api/payment/${payment_id}/details/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    payment_id: payment_id,
                }),
            });
            const data = await response.json();

            if (!response.ok) {
                setVisibleGroupNotifNotPaid(true);
            }
            console.log(data);
            return data;

        } catch (error) {
            setVisibleGroupNotifNotPaid(true);
            console.error(error);
        }
    }

    // Function to remove the payment if successful
    const removePayment = async (payment_id) => {
        try {
            const response = await fetch(`${BASE_URL}/shop/api/payment/${payment_id}/details/`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    payment_id: payment_id,
                }),
            });
            const data = await response.json();

            if (response.ok) {
                setVisibleGroupNotifNotPaid(true);
            }
            console.log(data);
            return data;

        } catch (error) {
            setVisibleGroupNotifNotPaid(true);
            console.error(error);
        }
    }

    // Function to update payment table for each order paid
    const initiatePaymentOrder = async () => {

        // Save payment table
        // Get the provider payment id
        let payment_provider_id;
        let payment_provider_id_type;
        if (!providerPayment) {
            const list_of_provider_payment = await getListOfProviderPayment();
            payment_provider_id = list_of_provider_payment[0].id;
            payment_provider_id_type = list_of_provider_payment[0].type;
        } else {
            payment_provider_id = providerPayment.id; // Object is providerPayment
            payment_provider_id_type = providerPayment.type;
        };

        let transaction_id_third;
        if (payment_provider_id_type === "Mobilemoney" || payment_provider_id_type === "Creditcard" || payment_provider_id_type === "Coupon") {
            transaction_id_third = amountOrCouponOrRef;
        } else {
            transaction_id_third = null;
        }

        const amount = totalAmount;

        // card_number_third
        // Get connected cashier
        const user_connected = await getStaffUserByUsername(username); // Object

        const cashier = await getCashierUser(user_connected.id); // Object
        const cashier_id = cashier.id;

        const shop = await getShopDetails(cashier.shop); // Object
        const shop_id = shop.id;

        try {
            const response = await fetch(`${BASE_URL}/shop/api/payments/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    payment_provider: payment_provider_id, // Object
                    amount: amount,
                    transaction_id_third: transaction_id_third,
                    // card_number_third: card_number_third,
                    shops: shop_id,
                    cashier: cashier_id,
                }),
            });
            const data = await response.json();

            if (!response.ok) {
                setVisibleGroupNotifNotPaid(true);
            }
            return data;

        } catch (error) {
            setVisibleGroupNotifNotPaid(true);
            console.error(error);
        }
    }

    // Payment
    const handlePaymentOrder = async () => {
        // Set loading to true when starting the function
        setIsLoading(true);
        // Save all data in order and invoice table

        // Initiate payment
        const payment = await initiatePaymentOrder();
        const payment_id = payment.id;

        let shop_id;
        if (!payment) {
            alert("Erreur lors de l'enregistrement du paiement");
            shop_id = null;
        } else {
            shop_id = payment.shops;
        };

        // Generate bill number
        const currentDateTime = moment();
        const formattedDateTime = currentDateTime.format('YYYY-MM-DD-HHmmss');

        const invoice_number = 'FAC-' + formattedDateTime;
        // Update bill number
        setBillNumber(invoice_number);

        // Get total discount
        const discount = total_discount

        // Get details of coupon if exist
        const coupon = await handleCheckCoupon(amountOrCouponOrRef);
        let coupon_id;
        if (coupon) {
            coupon_id = coupon.id;
            console.log(coupon);
            console.log(coupon.id);
        } else {
            coupon_id = null;
        }

        const total = totalAmount;
        const tax = await getUpdatedTaxValue();

        // Get validate order
        let order;
        if (orderId && !newOrder) {
            order = await getOrderById(orderId); // Object
        } else {
            order = await saveOrderBeforePay(); // Object
        };

        let order_id;
        if (order) {
            order_id = order.id;
        } else {
            order_id = null;
        }
        // Set status
        let status;
        if (modePayment === "Cash") {
            status = "complete";
        } else {
            status = "pending";
        }

        //rest_paid_if_coupon_not_enough
        const rest_paid_if_coupon_not_enough = restAmount.toString() + " Ar | Ref_ext_id : " + refTransactionOfRestMoney;

        try {
            // Simulate an asynchronous operation for demonstration purposes
            await new Promise((resolve) => setTimeout(resolve, 2000));

            const response = await fetch(`${BASE_URL}/shop/api/invoices/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    order: order_id,
                    invoice_number: invoice_number,
                    shop: shop_id,
                    discount: discount,
                    coupon: coupon_id,
                    tax: tax,
                    total: total,
                    status: status,
                    payment: payment_id,
                    rest_paid_if_coupon_not_enough: rest_paid_if_coupon_not_enough,
                }),
            });
            const data = await response.json();
            if (response.ok) {
                setVisibleGroupNotifPaid(true);
                // Update reserved and quantity available
                await updateReservedAndQuantityProduct(cartList);

                if (coupon) {
                    // Update coupon details after using
                    await updateCouponIfUsed(amountOrCouponOrRef);
                };

                // Update the status of order to TRUE
                await updateOrderIfPaymentOk(order_id, coupon_id);

                // Update the status of payment to TRUE
                await updatePaymentStatus(payment.transaction_id);

                setNewOrder(true);
                setVisibleBtnPaid(false);
                setVisibleBtnSave(false);
                setVisibleRemoveCart(false);
                setVisibleModePayment(false);
                setVisibleGroupNotifNotPaid(false);
                alert("Paiement effectué sous le numéro de facture :" + invoice_number)
            }

            setIsLoading(false);
            return data;
        } catch (error) {
            await removePayment(payment.transaction_id);
            setVisibleGroupNotifNotPaid(true);
            console.error('Error during handlePaymentOrder:', error);
            alert("Erreur lors de l'enregistrement de la FACTURE");
        } finally {
            // Set loading to false when the function is done, regardless of success or failure
            setIsLoading(false);
        }

    }

    // Verification of data
    const handleCheckCouponOrAmount = async (e) => {
        const cashOrCodeGiven = e.target.value;
        if (cartList.length <= 0 || cashOrCodeGiven === "") {
            return (
                setBackAmount(0),
                setVisibleBtnPaid(false),
                setVisibleRemoveCart(false));
        }
        // Calculation of the amount paid out and the outstanding money that needs to be shared with the customer
        if (modePayment === "Cash") {
            const backMoney = cashOrCodeGiven - totalAmount;
            if (backMoney >= 0) {
                return (
                    setBackAmount(backMoney),
                    setVisibleBtnPaid(true),
                    setVisibleRemoveCart(true),
                    setVisibleBtnSave(true),
                    setAmountOrCouponOrRef(cashOrCodeGiven));
            }

            return (
                setBackAmount(0),
                setVisibleBtnPaid(false),
                setVisibleRemoveCart(true),
                setAmountOrCouponOrRef(cashOrCodeGiven));

        }

        if (modePayment === "Coupon") {
            setAmountOrCouponOrRef(cashOrCodeGiven)
            const coupon = await handleCheckCoupon(cashOrCodeGiven);
            if (coupon) {
                setVisibleBackAmount(false);
                alert("Code coupon correct");
            } else {
                return (
                    setVisibleBackAmount(false),
                    setVisibleBtnPaid(false),
                    setVisibleRemoveCart(true)
                );
            }

            return (
                setVisibleBackAmount(false),
                setVisibleBtnPaid(true),
                setVisibleRemoveCart(true),
                setAmountOrCouponOrRef(cashOrCodeGiven)
            );
        };

        if (modePayment === "Mobilemoney" || modePayment === "Creditcard") {
            if (cashOrCodeGiven === "") {
                return (
                    setVisibleBackAmount(false),
                    setVisibleBtnPaid(false),
                    setVisibleRemoveCart(true),
                    setAmountOrCouponOrRef("")
                );
            } else {
                return (
                    setVisibleBackAmount(false),
                    setVisibleBtnPaid(true),
                    setVisibleRemoveCart(true),
                    setAmountOrCouponOrRef(cashOrCodeGiven)
                );
            }
        }

    };

    // Try to get the pending order if orderId not null || selected from report
    const updatedCartOrderItem = async (orderIdPending) => {
        // Set loading to true when starting the function
        setIsLoading(true);

        try {
            const updatedCartResponse = await axios.get(`${BASE_URL}/shop/api/cart/${orderIdPending}/`);
            const updatedCart = updatedCartResponse.data;

            // Set cart selected if pending bill needs to be updated
            if (updatedCart) {
                const productList = updatedCart.products;
                const updatedCartList = productList.map((orderItem) => ({
                    productId: orderItem.product.id,
                    code_product: orderItem.product.code_product,
                    name: orderItem.product.name,
                    quantity: orderItem.quantity,
                    price: orderItem.unit_price,
                    stock_available: orderItem.product.quantity,
                    stock_reserved: orderItem.product.reserved_quantity,
                    discount: 0,
                    price_default: parseFloat(orderItem.unit_price),
                    discount_price: 0,
                }));

                setCartList([...cartList, ...updatedCartList]);
                return cartList;
            }
        } catch (error) {
            // Handle errors here
            console.error('Error during updatedCartOrderItem:', error);
        } finally {
            // Set loading to false when the function is done, regardless of success or failure
            setIsLoading(false);
        }
    };

    // Function to 
    const handleNextClient = async () => {
        // Set loading to true when starting the function
        setIsLoading(true);

        try {
            // Put empty cart
            setCartList([]);
            // Set all info client by default
            setNameClient("UNKNOWN");
            setContactClient("+261 33 00 000 00");
            setEmailClient("clientXX@gmail.com");
            setAddressClient("SHELL Tsimavaokely");
            setExistingOrder(false);
            setInputDisabled(false);
            setNewOrder(true);
            setVisibleGroupNotifPaid(false);
            setVisibleGroupNotifNotPaid(false);
            setVisibleGroupCheckCoupon(false);
            setVisibleGroupCheckCouponIfInsufficient(false);
            setBillNumber(null);
            setAmountOrCouponOrRef("");
            setCouponAmount(0);
        } catch (error) {
            // Handle errors here
            console.error('Error during handleNextClient:', error);
        } finally {
            // Set loading to false when the function is done, regardless of success or failure
            setIsLoading(false);
        }
    }

    // THIS PART OF CODE NEED REVIEW
    const handleGenerateBillPDF = () => {
        // Replace these values with the actual data you want to display
        // Set loading to true when starting the function
        setIsLoading(true);

        try {
            const billData = {
                logoImage: shopDetails.logo,
                enterpriseName: shopDetails.name,
                nifStat: shopDetails.nif_stat,
                enterpriseAddress: shopDetails.address_line_1,
                enterprisePhone: shopDetails.phone,
                total_discount: total_discount,
                currency: shopDetails.country.currency.symbol,
                typeOfTaxValue: typeOfTaxValue,
                typeOfTax: typeOfTax,
                taxValue: taxValue,
                totalAmount: totalAmount,
                nameOfCashier: cashierConnected.first_name + " " + cashierConnected.last_name,
                cartList: cartList,
                modePayment: modePayment,
                amountOrCouponOrRef: amountOrCouponOrRef,
                backAmount: backAmount,
            };

            // This will open the PDF in a new tab for viewing
            // If you want to download it, you can use 'Blob' instead of 'PDFViewer'
            return (
                // <PDFDownloadLink document={<RecipeBillPDF dataBill= {billData} />} fileName="bill.pdf">
                //   {({ blob, url, loading, error }) => (loading ? 'Generating PDF...' : 'Download PDF')}
                // </PDFDownloadLink>
                <BlobProvider document={<RecipeBillPDF dataBill={billData} />}>
                    {({ blob, url, loading, error }) => {
                        if (loading) {
                            return 'Generating PDF...';
                        } else if (blob) {
                            // If the PDF is generated, render the PDFViewer with the blob URL
                            return <PDFViewer width="100%" height="600px" src={url} />;
                        } else {
                            return 'Error generating PDF';
                        }
                    }}
                </BlobProvider>
            );
        } catch (error) {
            // Handle errors here
            console.error('Error during handleGenerateBillPDF:', error);
        } finally {
            // Set loading to false when the function is done, regardless of success or failure
            setIsLoading(false);
        }
    };

    // Redirection page if not corrected user
    const getUserConnectedId = async () => {
        const user_connected = await getStaffUserByUsername(username);
        if (user_connected) {
            const cashier_connected = await getCashierUser(user_connected.id);
            if (!cashier_connected) {
                navigate('/dashboard');
                return;
            }
            setCashierConnected(cashier_connected);
            return cashier_connected;
        }
        return null;
    };

    const fetchProducts = async () => {
        // loading for product list checking
        setIsLoading2(true);

        const cashier = await getUserConnectedId();
        const shop_id = cashier.shop;
        try {
            const response = await axios.get(`${BASE_URL}/shop/api/product-type/${selectedProductType}/${shop_id}/products/`);
            setResults(response.data);
        } catch (error) {
            console.log(error);
        } finally {
            // Set loading to false when the function is done, regardless of success or failure
            setIsLoading2(false);
        }
    };

    useEffect(() => {
        // check cart if not empty
        if (cartList.length > 0) {
            if (!billNumber) {
                setVisibleModePayment(true);
            } else {
                setVisibleModePayment(false);
            }

            if (orderId && !newOrder) {
                setVisibleBtnSave(false);
            } else {
                setVisibleBtnSave(true);
            }
            setVisibleBackAmount(true);
            setVisibleRemoveCart(true);
        } else {
            setVisibleModePayment(false);
            setVisibleBtnSave(false);
            setVisibleBackAmount(false);
            setVisibleRemoveCart(false);
            setVisibleGroupCheckCoupon(false);
            setVisibleGroupCheckCouponIfInsufficient(false);
        }

        // Keep the client's info as it is and set the cart to be updated
        if (orderId && existingOrder) {
            updatedCartOrderItem(orderId);
            setExistingOrder(false);
            setInputDisabled(true);
            setNewOrder(false)
        }

        getUserConnectedId();

        const fetchProductTypes = async () => {
            const user_connected = await getStaffUserByUsername(username); // Object
            const cashier = await getCashierUser(user_connected.id); // Object
            const shop = await getShopDetails(cashier.shop); // Object
            const type_shop = shop.type_shop.id;
            try {
                const response = await axios.get(`${BASE_URL}/shop/api/product-types/${type_shop}/list/`);
                setProductTypes(response.data);
            } catch (error) {
                console.error('Error fetching product types:', error);
                alert('Erreur de connexion à la base de données')
            }
        };
        fetchProductTypes();

        
        // Save client
        const infoClient = {
            name: nameClient,
            contact: contactClient,
            email: emailClient,
            address: addressClient,
        };
        setClient(infoClient);
        fetchProducts();
        getUpdatedTaxValue();
        checkMaintenanceService();
        getListOfProviderPayment();
        autoCalcul(amountOrCouponOrRef, modePayment, totalAmount);

    }, [selectedProductType,
        username,
        modePayment,
        totalAmount,
        amountOrCouponOrRef,
        billNumber,
        nameClient,
        contactClient,
        emailClient,
        addressClient,
        cartList,
        total_discount,
        providerPayment,
        restAmount,
        typeOfTaxValue,
        typeOfTax,
        taxValue,
        backAmount,
    ]);

    return (
        (shopDetails ?
            <div>
                <Navbar />
                <CContainer fluid>
                    <CRow className="row align-items-start">

                        <CCol className="custom-col" xs={12} md={6}>
                            {!billNumber ? <>
                                <CRow className="m-auto">
                                    <CForm className="row g-3" onSubmit={handleSearch}>
                                        <CFormInput
                                            className="custom-input-search"
                                            type="text"
                                            placeholder="Nom ou code produit"
                                            aria-label="default"
                                            value={query}
                                            onChange={(e) => setQuery(e.target.value)}
                                            required
                                        />
                                        <CButton color="#E5E5E5" size="sm" className="custom-button-search" type="submit">
                                            <CIcon type="submit" className="icon-search" icon={icon.cilSearch} />
                                        </CButton>

                                    </CForm>
                                </CRow>
                                <hr />
                                <CRow className="" xs={{ cols: 2, gutter: 2 }} lg={{ cols: 5, gutter: 3 }}>
                                    {productTypes.map((cat) => (
                                        <CCol onClick={() => handleProductTypeChange(cat.id)}>
                                            <Category selectedProductType={selectedProductType} nameCategory={cat.name} code={cat.id} imgCategory={cat.default_image} />
                                        </CCol>
                                    ))}
                                </CRow>
                                <hr />
                                {isLoading2 ? <LoadingSpinner2 /> :
                                    <>
                                        <p className="custom-p"><strong>{results.length}</strong>{(results.length > 1) ? (<> articles trouvés</>) : (<> article trouvé</>)}</p>
                                        <CRow className="" xs={{ cols: 1, gutter: 2 }} lg={{ cols: 2, gutter: 3 }}>
                                            {results.map((prod) => (
                                                <CCol
                                                    key={prod.id}
                                                    onClick={() => handleAddToCart(prod.id, prod.code_product, prod.name, prod.price, prod.quantity, prod.reserved_quantity)}
                                                >
                                                    <Product
                                                        selectedProduct={selectedProduct}
                                                        nameProduct={prod.name}
                                                        code={prod.code_product}
                                                        image={prod.default_image}
                                                        price={prod.price}
                                                        quantity={prod.quantity}
                                                        reserved_quantity={prod.reserved_quantity}
                                                        category={prod.product_type}
                                                        shopDetails={shopDetails}
                                                    />
                                                </CCol>
                                            ))}
                                        </CRow>
                                    </>
                                }
                            </> : <>
                                <CRow>
                                    <br /><br />
                                    <p>Veuillez vérifier le numéro de facture ci-dessous dans le cas où la facture n'est pas enregistrée : </p><br /><br />
                                    <h4><strong style={{ color: 'green' }}>{billNumber}</strong></h4><br /><br /><br /><br />
                                    {isLoading && <p><h4 style={{ color: 'red' }}>... Veuillez SVP patienter ...</h4><br /><br /><br /><br /></p>}
                                    <p>Veuillez cliquer sur le bouton <strong style={{ color: 'green' }}>***client suivant***</strong> pour enregistrer une nouvelle facture.</p>
                                    <h4>Information SHOP</h4>
                                    <p>Adresse : {shopDetails.city.name} ( {shopDetails.city.postal_code.code} ) - {shopDetails.state.name}</p>
                                    <p>Contact : {shopDetails.phone}</p>
                                    <p>License : {shopDetails.contract_duration} Mois</p>
                                </CRow>
                            </>}
                        </CCol>
                        <CCol className="col" xs={12} md={6}>
                            <CRow className="row align-items-start mt-2">
                                <CCol xs={6}>
                                    <CInputGroup className="mb-1">
                                        <CInputGroupText id="basic-addon1"><CIcon className="custom-icon" icon={icon.cilAddressBook} /></CInputGroupText>
                                        <CFormInput onChange={handleNameClient} type="text" size="sm" placeholder="Nom client" aria-label="customer-name" aria-describedby="basic-addon1" value={nameClient} required disabled={inputDisabled} />
                                    </CInputGroup>
                                </CCol>
                                <CCol xs={6}>
                                    <CInputGroup className="mb-1">
                                        <CInputGroupText id="basic-addon1"><CIcon className="custom-icon" icon={icon.cilAddressBook} /></CInputGroupText>
                                        <CFormInput onChange={handleContactClient} type="text" size="sm" placeholder="Contact client" aria-label="customer-name" aria-describedby="basic-addon1" value={contactClient} required disabled={inputDisabled} />
                                    </CInputGroup>
                                </CCol>
                            </CRow>
                            <CRow>
                                <CCol xs={6}>
                                    <CInputGroup className="mb-1">
                                        <CInputGroupText id="basic-addon1"><CIcon className="custom-icon" icon={icon.cilEnvelopeClosed} /></CInputGroupText>
                                        <CFormInput onChange={handleEmailClient} type="text" size="sm" placeholder="Adresse e-mail" aria-label="customer-email-address" aria-describedby="basic-addon1" value={emailClient} required disabled={inputDisabled} />
                                    </CInputGroup>
                                </CCol>
                                <CCol xs={6}>
                                    <CInputGroup className="mb-1">
                                        <CInputGroupText id="basic-addon1"><CIcon className="custom-icon" icon={icon.cilHouse} /></CInputGroupText>
                                        <CFormInput onChange={handleAddressClient} type="text" size="sm" placeholder="Adresse client" aria-label="customer-address" aria-describedby="basic-addon1" value={addressClient} required disabled={inputDisabled} />
                                    </CInputGroup>
                                </CCol>
                            </CRow>
                            <CRow>
                                {billNumber && <>
                                    <CCol xs={12}>
                                        <CInputGroup className="mb-1">
                                            <CInputGroupText id="basic-addon1">N° Facture:</CInputGroupText>
                                            <CFormInput type="text" size="sm" value={billNumber} aria-label="bill-number" aria-describedby="basic-addon1" disabled readOnly />
                                        </CInputGroup>
                                    </CCol></>}
                            </CRow>
                            <hr />
                            {isLoading ? <LoadingSpinner /> :
                                (<><p className="custom-p"><strong>{countProduct} </strong> {countProduct < 2 ? " article ajouté" : " articles ajoutés"}</p>
                                    <CTable color="dark" hover>
                                        <CTableHead>
                                            <CTableRow>
                                                <CTableHeaderCell scope="col">Code Article</CTableHeaderCell>
                                                <CTableHeaderCell scope="col">Désignation</CTableHeaderCell>
                                                <CTableHeaderCell scope="col">Qté</CTableHeaderCell>
                                                <CTableHeaderCell scope="col">Prix unitaire</CTableHeaderCell>
                                                {!billNumber &&
                                                    <CTableHeaderCell scope="col">Actions</CTableHeaderCell>
                                                }
                                            </CTableRow>
                                        </CTableHead>
                                        <CTableBody>
                                            {cartList.map((prod) => (
                                                <CTableRow color="light">
                                                    <CTableHeaderCell scope="row">{prod.code_product}</CTableHeaderCell>
                                                    <CTableDataCell>{prod.name}</CTableDataCell>
                                                    <CTableDataCell>{prod.quantity}</CTableDataCell>
                                                    <CTableDataCell><NumberFormat number={prod.price} />{prod.discount !== 0 ? (<span style={{ textDecoration: 'line-through', color: 'crimson', }}> <NumberFormat number={prod.price_default} /> </span>) : ""} </CTableDataCell>
                                                    {!billNumber &&
                                                        <CTableDataCell>
                                                            <CIcon onClick={() => handleUpdateQuantity(prod.productId, prod.code_product, prod.stock_reserved, (prod.quantity - 1), prod.stock_available, prod.quantity)} type="submit" className="custom-icon-minus" icon={icon.cilMinus} />
                                                            <CIcon onClick={() => handleUpdateQuantity(prod.productId, prod.code_product, prod.stock_reserved, (prod.quantity + 1), prod.stock_available, prod.quantity)} type="submit" className="custom-icon-plus" icon={icon.cilPlus} />
                                                            <CIcon onClick={() => handleRemoveFromCart(prod.productId, prod.code_product, prod.stock_reserved, prod.quantity, prod.stock_available)} type="submit" className="custom-icon-delete" icon={icon.cilX} />
                                                        </CTableDataCell>
                                                    }
                                                </CTableRow>
                                            ))}
                                        </CTableBody>
                                    </CTable>
                                    <hr />
                                    <CCol>
                                        <label className="custom-quote-label">Remise : ( <NumberFormat number={total_discount} /> ) {shopDetails.country.currency.symbol}</label><br />
                                        <label className="custom-quote-label">Sous-total ( HT ) : <NumberFormat number={totalAmount - taxValue} /> {shopDetails.country.currency.symbol}</label><br />
                                        <label className="custom-quote-label">Tax ( {typeOfTax} - {typeOfTaxValue} ) : <NumberFormat number={taxValue} /> {shopDetails.country.currency.symbol}</label><br />
                                        <label className="custom-quote-label2">Total à payer ( TTC ) : <NumberFormat number={totalAmount} /> {shopDetails.country.currency.symbol}</label><br />
                                    </CCol>
                                    <hr />
                                    <CContainer>
                                        <CRow>
                                            {visibleModePayment && <>
                                                <CCol xs={3}>
                                                    <CFormSelect onChange={handelModePayment} style={{ width: '140px', }} size="sm" className="mb-3" aria-label="Small select example">
                                                        {methodPayment.map((method) => (
                                                            <option value={method.code_partner}>{method.name}</option>
                                                        ))}
                                                    </CFormSelect>
                                                </CCol>
                                                <CCol xs={4}>
                                                    <CFormInput
                                                        type="text"
                                                        size="sm"
                                                        aria-describedby="validationCustom03Feedback"
                                                        placeholder={placeholder}
                                                        label="Montant"
                                                        onChange={handleCheckCouponOrAmount}
                                                        value={amountOrCouponOrRef}
                                                        required
                                                    />
                                                </CCol>
                                                {visibleBtnPaid && <>
                                                    <CCol xs={2}>
                                                        <CButton onClick={handlePaymentOrder} size="sm" color="success">{nameBtn}</CButton>
                                                    </CCol>
                                                </>}
                                                {visibleRemoveCart && <>
                                                    <CCol xs={1}>
                                                        <CTooltip content="Vider le panier">
                                                            <CIcon onClick={handleAllRemoveFromCart} type="button" className="custom-btn-crash" icon={icon.cilTrash} />
                                                        </CTooltip>
                                                    </CCol>
                                                </>}

                                            </>}

                                            {visibleBtnSave && <>
                                                <CCol xs={1}>
                                                    <CTooltip content="Sauvegarder et payer plus tard">
                                                        <CIcon onClick={handleSaveOrder} type="button" className="custom-print-icon" icon={icon.cilSave} />
                                                    </CTooltip>
                                                </CCol></>}
                                            {visibleBackAmount && <>
                                                <CCol xs={6}>
                                                    <label>La monnaie à rendre: <NumberFormat number={backAmount} /> {shopDetails.country.currency.symbol}</label>
                                                </CCol>
                                            </>}
                                        </CRow><br />
                                        {visibleGroupCheckCoupon && <>
                                            <CRow>
                                                <CCol xs={4}>
                                                    <label>Montant : <NumberFormat number={couponAmount} /> {shopDetails.country.currency.symbol}</label>
                                                </CCol>
                                                <CCol xs={2}>
                                                    <label className={checkAmount}>{checkAmount}</label>
                                                </CCol>
                                                <CCol xs={4}>
                                                    <label style={{ color: "red", fontWeight: "bold" }}>Rester à payer : <NumberFormat number={restAmount} /> {shopDetails.country.currency.symbol}</label>
                                                </CCol>
                                            </CRow><br />
                                        </>}

                                        {visibleGroupCheckCouponIfInsufficient && <>
                                            <h6 style={{ color: "red", fontWeight: "bold" }}>Merci de régler le reste en espèces ou par d'autres modes de paiement.</h6>
                                            <CRow>
                                                <CCol xs={12}>
                                                    <CInputGroup className="mb-1">
                                                        <CInputGroupText id="basic-addon1">Reference si VISA ou MobileMoney:</CInputGroupText>
                                                        <CFormInput
                                                            type="text"
                                                            size="sm"
                                                            placeholder="Reference si VISA ou MobileMoney"
                                                            aria-label="refTransaction"
                                                            aria-describedby="basic-addon1"
                                                            onChange={getRefTransactionOfRestMoney}
                                                            value={refTransactionOfRestMoney}
                                                        />
                                                    </CInputGroup>
                                                </CCol>
                                            </CRow><br />
                                        </>}
                                    </CContainer></>)}
                            {visibleGroupNotifPaid && <>
                                <CRow>
                                    <CCol xs={5}>
                                        <label className="text-success">Facture enregistré avec succes : {billNumber}</label>
                                    </CCol>
                                    <CCol xs={5}>
                                        <CButton onClick={handleNextClient} type="submit" className="m-1" size="sm" color="primary">Client Suivant</CButton>
                                    </CCol>
                                    <CCol xs={1}>
                                        <CTooltip content="Imprimer">
                                            <CIcon onClick={handleGenerateBillPDF} type="button" className="custom-print-icon" icon={icon.cilPrint} />
                                        </CTooltip>
                                    </CCol>
                                </CRow><br />
                            </>}
                            {visibleGroupNotifNotPaid && <>
                                <CRow>
                                    <CCol xs={5}>
                                        <label className="text-danger">Facture non enregistré</label><br /><br />
                                    </CCol>
                                </CRow>
                            </>}
                        </CCol>
                    </CRow>
                </CContainer>
                <br /><br />
                <Footer />
            </div> : <div><Navbar /><LoadingSpinner /></div>
        )
    );
}

export default Cashier;
