import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from 'react-router-dom';

import {
    CContainer,
    CCol,
    CRow,
    CFormInput,
    CTable,
    CTableHead,
    CTableRow,
    CTableHeaderCell,
    CTableDataCell,
    CTableBody,
    CInputGroup,
    CInputGroupText,
} from '@coreui/bootstrap-react';

import CIcon from '@coreui/icons-react';
import * as icon from '@coreui/icons';

import { Footer, NavbarBackoffice, NumberFormat, LoadingSpinner } from '../../components';

import "./style.css";

import axios from 'axios';

import jwt_decode from 'jwt-decode';

function OrderDetails() {
    const location = useLocation();

    // Save client's informations
    const nameClient = (location.state.nameClient ? location.state.nameClient : "inconnu");
    const codeCaisse = (location.state.codeCaisse ? location.state.codeCaisse : "");
    const contactClient = (location.state.contactClient ? location.state.contactClient : "033XXXXXXX");
    const emailClient = (location.state.emailClient ? location.state.emailClient : "client@client.mg");
    const addressClient = (location.state.addressClient ? location.state.addressClient : "SHELL Tsimavaokely");
    const isPaid = (location.state.isPaid ? location.state.isPaid : "");
    const orderType = (location.state.orderType ? location.state.orderType : "");

    // Set empty by default the cart of ProductList of client if new
    const [cartList, setCartList] = useState([]);

    // Set null by default the cart of Client if new
    const orderId = location.state && location.state.orderId ? location.state.orderId : null; // Use null as the default value if cart is not present

    const BASE_URL = 'https://pi.backend.area.mg'
    const navigate = useNavigate();

    const [taxValue, setTaxValue] = useState(0)
    const [typeOfTaxValue, setTypeOfTaxValue] = useState("")
    const [typeOfTax, setTypeOfTax] = useState("")

    // Shop details
    const [shopDetails, setShopDetails] = useState(null);

    // Get the user connected
    // Assuming you have stored the access token in a variable called `accessToken`
    const decodedToken = jwt_decode(localStorage.getItem('authTokens'));
    const username = decodedToken.username;

    // Function return the staff user with their Username
    const getStaffUserByUsername = async () => {
        try {
            const response = await axios.get(`${BASE_URL}/api/user/${username}/users`);
            return response.data;
        } catch (error) {
            console.log(error);
            alert("Vous ne pouvez pas accéder à cette page.");
        };
    }

    // Function check maintenance service ongoing and block all pages
    const checkMaintenanceService = async () => {
        try {
            const response = await axios.get(`${BASE_URL}/shop/api/maintenance/`);
            if (response.data) {
                // const date_scheduled = response.data.date_scheduled
                // const date_completed = response.data.date_scheduled
                alert("Indisponibilité temporaire pour cause de maintenance. Veuillez revenir dans un instant ")
                localStorage.removeItem('authTokens');
                navigate('/connexion');
            }
            return response.data;
        } catch (error) {
            console.log(error);
        };
    };

    // Get shop info
    const getShopDetails = async () => {
        const staff = await getStaffUserByUsername();
        const staff_id = staff.id;

        try {
            const response = await axios.get(`${BASE_URL}/shop/api/shop/owner/${staff_id}/`);
            if (response.data) {
                setShopDetails(response.data);
            }
            return response.data;
        } catch (error) {
            console.log(error);
        };
    };

    // Try to get the pending order if orderId not null || selected from report
    const updatedCartOrderItem = async () => {
        try {
            const updatedCartResponse = await axios.get(`${BASE_URL}/shop/api/cart/${orderId}/`);
            const updatedCart = updatedCartResponse.data;

            // Set cart selected if pending bill needs to be updated
            if (updatedCart) {
                const productList = updatedCart.products;
                const updatedCartList = productList.map((orderItem) => ({
                    productId: orderItem.product.id,
                    code_product: orderItem.product.code_product,
                    name: orderItem.product.name,
                    quantity: orderItem.quantity,
                    price: orderItem.unit_price,
                    stock_available: orderItem.product.quantity,
                    stock_reserved: orderItem.product.reserved_quantity,
                    discount: 0,
                    price_default: parseFloat(orderItem.unit_price),
                    discount_price: 0,
                }));

                setCartList([...cartList, ...updatedCartList]);
                return cartList;
            }
        } catch (error) {
            console.log(error);
        }
    };

    // Product count
    const countProductAdded = () => {
        let totalProduct = 0;
        for (const product of cartList) {
            totalProduct += product.quantity;
        }
        return totalProduct;
    };


    // Total amount of cart list without discount
    const calculateTotalAmount = () => {
        let totalAmount = 0;
        for (const product of cartList) {
            totalAmount += product.price * product.quantity;
        }
        return totalAmount;
    };

    // Total discount
    const calculateDiscount = () => {
        let total_discount = 0
        for (const product of cartList) {
            total_discount += product.discount_price * product.quantity;
        }
        return total_discount;
    }

    // Function to get tax value updated on DB
    const getUpdatedTaxValue = async () => {
        const staff = await getStaffUserByUsername();
        const staff_id = staff.id;
        const shop = await getShopDetails(staff_id); // Object
        const zone_id = shop.state.id;
        // Tax calculation
        // Initiate the tax 
        let tax;
        try {
            const response = await axios.get(`${BASE_URL}/shop/api/tax/${zone_id}/zone/`);
            const totalAmount = calculateTotalAmount(cartList);
            const taxZoneType = response.data;
            if (taxZoneType) {
                let taxValue;
                const type_rate = response.data.type_rate;
                setTypeOfTax(taxZoneType.tax_type.name);
                if (type_rate === "Pct") {
                    taxValue = taxZoneType.rate_pct / 100;
                    tax = totalAmount * taxValue;
                    setTaxValue(tax);
                    setTypeOfTaxValue(taxZoneType.rate_pct + " %");
                } else {
                    taxValue = taxZoneType.rate_fixed;
                    tax = totalAmount - taxValue;
                    setTaxValue(tax);
                    setTypeOfTaxValue(taxValue + " Montant Fixé");
                }
            } else {
                tax = 0;
                setTaxValue(tax);
            }
            return tax;

        } catch (error) {
            console.log(error);
        };
    }

    const totalAmount = calculateTotalAmount();
    const total_discount = calculateDiscount();
    const countProduct = countProductAdded();


    useEffect(() => {
        const fetchData = async () => {
            await updatedCartOrderItem();
            await getShopDetails();
            await checkMaintenanceService();
            await getUpdatedTaxValue();
        };

        fetchData();
    }, []);

    return (
        (shopDetails ?
            <>
                <NavbarBackoffice />
                <CContainer className="mt-2">
                    <CRow>
                        <h4>&#9734; Informations client</h4>
                        <hr/>
                        <CCol xs={6}>
                            <CInputGroup className="mb-1">
                                <CInputGroupText id="basic-addon1"><CIcon className="custom-icon" icon={icon.cilAddressBook} /></CInputGroupText>
                                <CFormInput type="text" size="sm" placeholder="Nom client" aria-label="customer-name" aria-describedby="basic-addon1" value={nameClient} required disabled />
                            </CInputGroup>
                        </CCol>
                        <CCol xs={6}>
                            <CInputGroup className="mb-1">
                                <CInputGroupText id="basic-addon1"><CIcon className="custom-icon" icon={icon.cilAddressBook} /></CInputGroupText>
                                <CFormInput type="text" size="sm" placeholder="Contact client" aria-label="customer-name" aria-describedby="basic-addon1" value={contactClient} required disabled />
                            </CInputGroup>
                        </CCol>
                    </CRow>
                    <CRow>
                        <CCol xs={6}>
                            <CInputGroup className="mb-1">
                                <CInputGroupText id="basic-addon1"><CIcon className="custom-icon" icon={icon.cilEnvelopeClosed} /></CInputGroupText>
                                <CFormInput type="text" size="sm" placeholder="Adresse e-mail" aria-label="customer-email-address" aria-describedby="basic-addon1" value={emailClient} required disabled />
                            </CInputGroup>
                        </CCol>
                        <CCol xs={6}>
                            <CInputGroup className="mb-1">
                                <CInputGroupText id="basic-addon1"><CIcon className="custom-icon" icon={icon.cilHouse} /></CInputGroupText>
                                <CFormInput type="text" size="sm" placeholder="Adresse client" aria-label="customer-address" aria-describedby="basic-addon1" value={addressClient} required disabled />
                            </CInputGroup>
                        </CCol>
                    </CRow>
                    <hr /><br/>
                    <h4>&#9734; Détails de la commande</h4>
                    <hr/>
                    <p className="custom-p"><strong>{countProduct} </strong> {countProduct < 2 ? " article ajouté" : " articles ajoutés"}</p>
                    <CTable color="dark" hover>
                        <CTableHead>
                            <CTableRow>
                                <CTableHeaderCell scope="col">Code Article</CTableHeaderCell>
                                <CTableHeaderCell scope="col">Désignation</CTableHeaderCell>
                                <CTableHeaderCell scope="col">Qté</CTableHeaderCell>
                                <CTableHeaderCell scope="col">Prix unitaire</CTableHeaderCell>
                            </CTableRow>
                        </CTableHead>
                        <CTableBody>
                            {cartList.map((prod) => (
                                <CTableRow color="light">
                                    <CTableHeaderCell scope="row">{prod.code_product}</CTableHeaderCell>
                                    <CTableDataCell>{prod.name}</CTableDataCell>
                                    <CTableDataCell>{prod.quantity}</CTableDataCell>
                                    <CTableDataCell><NumberFormat number={prod.price} />{prod.discount !== 0 ? (<span style={{ textDecoration: 'line-through', color: 'crimson', }}> <NumberFormat number={prod.price_default} /> </span>) : ""} </CTableDataCell>
                                </CTableRow>
                            ))}
                        </CTableBody>
                    </CTable>
                    <hr />
                    <CCol>
                        <label className="custom-quote-label">Remise : {shopDetails.country.currency.symbol} ( <NumberFormat number={total_discount} /> )</label><br />
                        <label className="custom-quote-label">Sous-total ( HT ) : {shopDetails.country.currency.symbol} <NumberFormat number={totalAmount - taxValue} /> </label><br />
                        <label className="custom-quote-label">Tax ( {typeOfTax} - {typeOfTaxValue} ) : {shopDetails.country.currency.symbol} <NumberFormat number={taxValue} /></label><br />
                        <label className="custom-quote-label2">Total à payer (TTC) : {shopDetails.country.currency.symbol} <NumberFormat number={totalAmount} /></label><br />
                    </CCol>
                    <hr /><br/>
                    <h4>&#9734; Etat commande</h4>
                    <hr/>
                    <CRow>
                        <CCol xs={6}>
                            <CInputGroup className="mb-1">
                                <CInputGroupText id="basic-addon1">Type de commande ( Code caisse : {codeCaisse}) :</CInputGroupText>
                                <CFormInput type="text" size="sm" aria-describedby="basic-addon1" value={orderType} required disabled />
                            </CInputGroup>
                        </CCol>
                        <CCol xs={6}>
                            <CInputGroup className="mb-1">
                                <CInputGroupText id="basic-addon1">Etat : </CInputGroupText>
                                <CFormInput type="text" size="sm" aria-describedby="basic-addon1" value={isPaid ? "Payé" : "Non payé"} required disabled />
                            </CInputGroup>
                        </CCol>
                    </CRow>
                    <hr/>
                </CContainer>
                <br/><br/><br/>
                <Footer/>
            </> : <div><NavbarBackoffice /><LoadingSpinner /></div>
        )
    );
}

export default OrderDetails;
