import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';

import {
    CContainer,
    CCol,
    CRow,
    CTooltip,
    CTable,
    CTableHead,
    CTableRow,
    CTableHeaderCell,
    CTableBody,
    CTableDataCell,
} from '@coreui/bootstrap-react';

import { NavbarBackoffice, Footer, LoadingSpinner } from '../../components';

import { NumberFormat, FormatDate } from '../../components';
import CIcon from '@coreui/icons-react';
import * as icon from '@coreui/icons';

import axios from 'axios';
import "./style.css";

import jwt_decode from 'jwt-decode';

const ExpenseList = () => {
    const BASE_URL = 'https://pi.backend.area.mg';
    const [shopDetails, setShopDetails] = useState(null);

    const navigate = useNavigate();

    // Get list of bill pending 
    const [expenses, setExpenses] = useState([]);
    // Get the list of provider of payment
    const getListOfExpense30days = async (shop_id) => {
        try {
            const response = await axios.get(`${BASE_URL}/shop/api/expenses/${shop_id}/`);
            const expenses = response.data;

            return expenses;

        } catch (error) {
            console.log(error);
        };
    };

    const addExpense = () => {
        navigate('/ajouter-dépense');
    }

    // Function to delete expense for a shop
    const deleteExpense = async (expense_id) => {
        const shop = await getShopDetails();
        // Get shop_id
        const shop_id = shop.id;

        // Ask for confirmation before deleting the material
        const confirmDelete = window.confirm('Êtes-vous sûr de vouloir supprimer cet item ?');
        if (!confirmDelete) {
            return; // If the user clicks "Cancel," do not proceed with the deletion
        }

        try {
            const response = await axios.delete(`${BASE_URL}/shop/api/expense/${expense_id}/${shop_id}/delete/`);
            if (response) {
                alert("La suppression reussie");
            }
        } catch (error) {
            console.log(error);
            alert("Une erreur s'est produite");
        };
    };

    // Assuming you have stored the access token in a variable called `accessToken`
    const decodedToken = jwt_decode(localStorage.getItem('authTokens'));
    const username = decodedToken.username;


    // Function return the staff user with their Username
    const getStaffUserByUsername = async () => {
        try {
            const response = await axios.get(`${BASE_URL}/api/user/${username}/users`);
            return response.data;
        } catch (error) {
            console.log(error);
            alert("Vous ne pouvez pas accéder à cette page.");
        };
    }

    // Get shop info
    const getShopDetails = async () => {
        const staff = await getStaffUserByUsername();
        const staff_id = staff.id;
        try {
            const response = await axios.get(`${BASE_URL}/shop/api/shop/owner/${staff_id}/`);
            if (response.data) {
                setShopDetails(response.data);
            }
            return response.data;
        } catch (error) {
            console.log(error);
        };
    };

    // Function to convert data to CSV format
    function convertToCSV(data) {
        const header = [
            'Libele', 'Type', 'Montant_' + shopDetails.country.currency.symbol, 'Description', 'Etat', 'Date_Enregistrement', 'Derniere_Actualisation'
        ];

        const rows = data.map(expense => [
            expense.label,
            expense.expense_type.name,
            expense.amount,
            expense.description,
            expense.is_completed ? 'Clos' : 'Non clos',
            expense.created_at,
            expense.updated_at
        ]);

        return [header, ...rows].map(row => row.join(',')).join('\n');
    }

    // Function to download CSV file
    const downloadCSV = async () => {
        const datetime = new Date().toISOString().replace(/:/g, '-');
        const filename = `depenses_${datetime}.csv`;

        if (!expenses || expenses.length === 0) {
            alert("Aucune dépense trouvée");
            return;
        }

        try {
            const data = convertToCSV(expenses);

            const csvContent = 'data:text/csv;charset=utf-8,' + encodeURIComponent(data);
            const link = document.createElement('a');
            link.setAttribute('href', csvContent);
            link.setAttribute('download', filename);
            link.click();
        } catch (error) {
            console.error("Error exporting data to CSV:", error);
            alert("Une erreur s'est produite lors de l'exportation des données en CSV.");
        }
    };

    useEffect(() => {
        const fetchExpenses = async () => {
            const shop = await getShopDetails();
            const allExpenses = await getListOfExpense30days(shop.id);

            // Filter expenses from the last 30 days based on the 'updated_at' field
            const thirtyDaysAgo = new Date();
            thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);

            const filteredExpenses = allExpenses.filter(
                (order) => new Date(order.updated_at) >= thirtyDaysAgo
            );

            setExpenses(filteredExpenses);
        };
        getShopDetails();

        const interval = setInterval(() => {
            fetchExpenses();
        }, 2000);

        return () => clearInterval(interval);
    }, [])

    return (
        (shopDetails ?
            <div>
                <NavbarBackoffice />
                <CContainer className="mt-1">
                    <CRow>
                        <CCol md={12}>
                            {expenses.length === 0 ? <h4 className="display-6 text-center">Veuillez patienter pour les mises à jour des dépenses durant 30 derniers jours......
                                &nbsp;&nbsp;
                                <CTooltip content="Ajouter un dépense">
                                    <CIcon
                                        onClick={addExpense}
                                        type="submit"
                                        className="custom-icon-add"
                                        icon={icon.cilPlus}
                                    />
                                </CTooltip>
                            </h4> :
                                <h4 className="display-6 text-center">Dépenses des 30 derniers jours ( {expenses.length} ) &nbsp;&nbsp;
                                    <CTooltip content="Télécharger dans un fichier csv.">
                                        <CIcon
                                            onClick={downloadCSV}
                                            type="submit"
                                            className="custom-icon-download"
                                            icon={icon.cilCloudDownload}
                                        />
                                    </CTooltip>
                                    &nbsp;&nbsp;
                                    <CTooltip content="Ajouter un dépense">
                                        <CIcon
                                            onClick={addExpense}
                                            type="submit"
                                            className="custom-icon-add"
                                            icon={icon.cilPlus}
                                        />
                                    </CTooltip>
                                </h4>}
                            {expenses &&
                                <CTable color="dark" striped>
                                    <CTableHead>
                                        <CTableRow>
                                            <CTableHeaderCell scope="col">Libélé</CTableHeaderCell>
                                            <CTableHeaderCell scope="col">Type</CTableHeaderCell>
                                            <CTableHeaderCell scope="col">Montant_({shopDetails.country.currency.iso_code})</CTableHeaderCell>
                                            <CTableHeaderCell scope="col">Déscription</CTableHeaderCell>
                                            <CTableHeaderCell scope="col">Etat</CTableHeaderCell>
                                            <CTableHeaderCell scope="col">Date du registre</CTableHeaderCell>
                                            <CTableHeaderCell scope="col">Dernière actualisation</CTableHeaderCell>
                                            <CTableHeaderCell scope="col">Action</CTableHeaderCell>
                                        </CTableRow>
                                    </CTableHead>
                                    <CTableBody>
                                        {expenses.map((expense) => (
                                            <CTableRow key={expense.id} color={expense.is_completed ? 'light' : 'danger'}>
                                                <CTableDataCell>{expense.label}</CTableDataCell>
                                                <CTableDataCell>{expense.expense_type.name}</CTableDataCell>
                                                <CTableDataCell>
                                                    {shopDetails.country.currency.symbol} <NumberFormat number={expense.amount} /> 
                                                </CTableDataCell>
                                                <CTableDataCell>{expense.description}</CTableDataCell>
                                                <CTableDataCell>{expense.is_completed ? 'Clos' : 'Non clos'}</CTableDataCell>
                                                <CTableDataCell>
                                                    <FormatDate dateString={expense.created_at} />
                                                </CTableDataCell>
                                                <CTableDataCell>
                                                    <FormatDate dateString={expense.updated_at} />
                                                </CTableDataCell>
                                                <CTableDataCell style={{ display: 'flex',margin: 'auto', backgroundColor: "inherit", border: 'none', }}>
                                                    <CTooltip content="Modifier">
                                                        <CIcon
                                                            type="submit"
                                                            onClick={() => navigate(`/modifier-dépense`, {
                                                                state: {
                                                                    expenseId: expense.id,
                                                                }
                                                            })}
                                                            className="custom-icon-edit"
                                                            icon={icon.cilColorBorder}
                                                        />
                                                    </CTooltip>
                                                    <CTooltip content="Supprimer">
                                                        <CIcon
                                                            type="submit"
                                                            onClick={() => deleteExpense(expense.id)}
                                                            className="custom-icon-delete"
                                                            icon={icon.cilTrash}
                                                        />
                                                    </CTooltip>
                                                </CTableDataCell>
                                            </CTableRow>
                                        ))}

                                    </CTableBody>
                                </CTable>
                            }
                        </CCol>
                    </CRow>
                </CContainer>
                <br /><br />
                <Footer />
            </div> : <div><NavbarBackoffice /><LoadingSpinner /></div>
        )
    );
};

export default ExpenseList;