import React, { createContext, useState, useEffect } from 'react';
import jwt_decode from 'jwt-decode';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
    const BASE_URL = 'https://pi.backend.area.mg'

    const navigate = useNavigate();
    const location = useLocation();
    
    const [authTokens, setAuthTokens] = useState(() => JSON.parse(localStorage.getItem('authTokens')) || null);
    const [user, setUser] = useState(() => authTokens ? jwt_decode(authTokens.access) : null);
    const [loading, setLoading] = useState(false);

    // Function return the staff user with their Username
    const getStaffUserByUsername = async (username) => {
        try {
            const response = await axios.get(`${BASE_URL}/api/user/${username}/users`);
            return response.data;
        } catch (error) {
            //console.log(error);
            alert("Vous ne pouvez pas accéder à cette page.");
        };
    }

    // Function return the staff user if cashier or not 
    const getCashierUser = async (cashier_account) => {
        try {
            const response = await axios.get(`${BASE_URL}/shop/api/cashier/active/${cashier_account}/`);
            return response.data;
        } catch (error) {
            console.log(error);
        };
    }

    const loginUser = async (username, password) => {
        try {

            const tokens = await getAuthenticationTokens(username, password);
            
            if (tokens) {
                const user = await fetchUserInformation(username);
                handleUserLogin(user, tokens);
                return user.username; // Return the user information
            }
    
        } catch (error) {
            handleLoginError(error);
        }
    };
    
    const getAuthenticationTokens = async (username, password) => {
        try {
            setLoading(true); // Set loading to true before making the request

            const response = await axios.post(`${BASE_URL}/api/token/`, {
                username: username,
                password: password,
            });
    
            if (response.status === 200) {
                return response.data;
            } else {
                handleLoginError(response);
            }
        } catch (error) {
            handleLoginError(error);
        } finally {
            setLoading(false); // Set loading to false whether the request succeeds or fails
        }
    };

    const fetchUserInformation = async (username) => {
        try {
            const user = await getStaffUserByUsername(username);
            return user;
        } catch (error) {
            handleLoginError(error);
        }
    };

    const handleLoginError = (error) => {
        console.error('Login failed : ', error);
        if (error.code === "ERR_BAD_REQUEST") {
            alert("Le pseudo ou le mot de passe que vous avez saisi n'est pas valide.")
        } else {
            alert("Veuillez vérifier votre connexion, s'il vous plaît.")
        }
        //alert("La connexion a échoué en raison d'informations d'identification incorrectes ou d'une erreur");
    };

    const handleUserLogin = async (user, tokens) => {
        setAuthTokens(tokens);
        setUser(jwt_decode(tokens.access));
        localStorage.setItem('authTokens', JSON.stringify(tokens));
    
        const cashier = await getCashierUser(user.id);
    
        if (cashier) {
            navigate('/caisse');
        } else {
            navigate('/dashboard');
        }
    };
    
    // Old function
    // const loginUser = async (username, password) => {
    //     try {
    //         const response = await axios.post(`${BASE_URL}/api/token/`, {
    //             username: username,
    //             password: password,
    //         });

    //         const { data } = response;

    //         if (response.status === 200) {
    //             const user = await getStaffUserByUsername(username);
    //             if (user) {
    //                 setAuthTokens(data);
    //                 setUser(jwt_decode(data.access));
    //                 localStorage.setItem('authTokens', JSON.stringify(data));

    //                 const cashier = await getCashierUser(user.id);

    //                 if (cashier) {
    //                     navigate('/caisse');
    //                     return cashier;
    //                 } else {
    //                     navigate('/dashboard');
    //                     return user;
    //                 }
    //             }
    //             return response;
    //         } else {
    //             console.log(response);
    //             alert('Incorrect credentials');
    //             return response;
    //         }
    //     } catch (error) {
    //         console.log(error);
    //         return 0;
    //     }
    // };

    const logoutUser = () => {
        setAuthTokens(null);
        setUser(null);
        localStorage.removeItem('authTokens');
        navigate('/connexion');
    };

    // Function check maintenance service ongoing and block all pages
    const checkMaintenanceService = async () => {
        try {
            const response = await axios.get(`${BASE_URL}/shop/api/maintenance/`);
            if (response.data) {
                // const date_scheduled = response.data.date_scheduled
                // const date_completed = response.data.date_scheduled
                alert("Indisponibilité temporaire pour cause de maintenance. Veuillez revenir dans un instant ")
                logoutUser();
            }
            return response.data;
        } catch (error) {
            console.log(error);
        };
    };

    useEffect(() => {
        
        const refreshToken = async () => {
            try {
                const response = await axios.post(`${BASE_URL}/api/token/refresh/`, {
                    refresh: authTokens && authTokens.refresh,
                });

                const { data } = response;

                if (response.status === 200) {
                    setAuthTokens(data);
                    setUser(jwt_decode(data.access));
                    localStorage.setItem('authTokens', JSON.stringify(data));
                } else {
                    logoutUser();
                }

                if (loading) {
                    setLoading(false);
                }
            } catch (error) {
                //console.error('Token refresh failed', error);
                logoutUser();
            }
        };

        const shouldRefreshToken = location.pathname !== '/reinitialiser-mot-de-passe';

        if (authTokens && shouldRefreshToken && loading) {
            refreshToken();
            checkMaintenanceService();
        }

        const tokenRefreshInterval = setInterval(refreshToken, 1000 * 60 * 4); // Refresh token every 4 minutes

        return () => clearInterval(tokenRefreshInterval);

    }, [authTokens, loading]);

    const contextData = {
        user: user,
        authTokens: authTokens,
        loginUser: loginUser,
        logoutUser: logoutUser,
        loading: loading, // Include loading in the context data
    };

    return <AuthContext.Provider value={contextData}>{children}</AuthContext.Provider>;
};

export { AuthContext, AuthProvider };
