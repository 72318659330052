import React, { useContext, useState } from 'react';
import { AuthContext } from '../../context/AuthContext';
import { CContainer, CImage, CRow, CForm, CCardLink, CButton, CFormFloating, CFormInput, CFormLabel } from '@coreui/react';
import CIcon from '@coreui/icons-react';
import * as icon from '@coreui/icons';
import { FaEye, FaEyeSlash } from 'react-icons/fa';

import "./style.css";

export default function Login() {
    const [errorMessage, setErrorMessage] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const { loginUser, loading } = useContext(AuthContext);

    const handleTogglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setErrorMessage("");

        const formData = {
            username: e.target.username.value,
            password: e.target.password.value,
        };

        try {
            // Use the loading state from the AuthContext to manage loading states
            if (loading) {
                return; // Do nothing if the authentication process is already ongoing
            }

            // Trigger the authentication process
            const connection = await loginUser(formData.username, formData.password);

            // Check if the connection is successful
            if (connection) {
                // Connection successful, handle accordingly
                setErrorMessage("");
                console.log("Login successful");
                // You might want to redirect the user to another page or update the UI here
            } else {
                // Connection failed, display an error message
                setErrorMessage("Une erreur s'est produite, veuillez vérifier et réessayer.");
            }

        } catch (error) {
            console.error(error);
        }
    };

    return (
        <div>
            <CContainer className="d-flex align-items-center justify-content-center min-vh-50">
                <CForm method='post' onSubmit={handleSubmit} className="text-center login">
                    <CImage className="logo" rounded src="/pi.png" width={150} />
                    <h5 className="label-title">Connectez-vous</h5>
                    <CRow alignVertical>
                        <CFormFloating className="mb-0">
                            <CFormInput
                                className="input-username"
                                type="text" id="floatingInput"
                                name="username"
                                placeholder="pseudo"
                                required
                            />
                            <CFormLabel className="label-username" htmlFor="floatingInput">
                                <CIcon className="icon-username" icon={icon.cilUser} />
                                Pseudo
                            </CFormLabel>
                        </CFormFloating>
                        <CFormFloating>
                            <CFormInput
                                className="input-password"
                                type={showPassword ? 'text' : 'password'}
                                id="floatingPassword"
                                name="password"
                                placeholder="Mot de passe"
                                required
                            />
                            <CFormLabel className="label-password" htmlFor="exampleFormControlTextarea1">
                                <CIcon className="icon-password" icon={icon.cilLockLocked} />
                                Mot de passe
                            </CFormLabel>
                            <CButton
                                type="button"
                                className="password-toggle-button2"
                                onClick={handleTogglePasswordVisibility}
                            >
                                {showPassword ? <FaEyeSlash /> : <FaEye />}
                                <span className="visually-hidden">
                                    {showPassword ? "Hide Password" : "Show Password"}
                                </span>
                            </CButton>
                        </CFormFloating>
                        <p className="errorMessage">{errorMessage}</p>
                        <CCardLink className="custom-link" href="/reinitialiser-mot-de-passe">
                            Mot de passe oublié ?
                        </CCardLink>
                    </CRow>
                    <CRow alignVertical className="custom-button-row">
                        <CButton
                            className="custom-submit"
                            color="primary"
                            size="lg"
                            type="submit"
                            disabled={loading}
                        >
                            {loading ? "Chargement ..." : "Se connecter"}
                        </CButton>
                    </CRow>
                </CForm>
            </CContainer>
        </div>
    );
}