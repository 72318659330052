import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import {
    CContainer, CRow, CCol, CForm,
    CFormLabel, CFormInput, CFormTextarea, CButton,
} from '@coreui/bootstrap-react';

import { NavbarBackoffice, Footer, LoadingSpinner } from '../../components';

import axios from 'axios';

import jwt_decode from 'jwt-decode';

import CIcon from '@coreui/icons-react';
import * as icon from '@coreui/icons';

const EditSupplier = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const BASE_URL = 'https://pi.backend.area.mg';
    const [shopDetails, setShopDetails] = useState(null);

    // Assuming you have stored the access token in a variable called `accessToken`
    const decodedToken = jwt_decode(localStorage.getItem('authTokens'));
    const username = decodedToken.username;

    // Perform a conditional check to access the suppID property
    const suppID = (location.state ? location.state.supplierID : '');

    // Define a loading state
    const [isLoading, setIsLoading] = useState(false);

    // Function return the staff user with their Username
    const getStaffUserByUsername = async () => {
        try {
            const response = await axios.get(`${BASE_URL}/api/user/${username}/users`);
            return response.data;
        } catch (error) {
            console.log(error);
        };
    }

    // Get shop info
    const getShopDetails = async () => {
        const staff = await getStaffUserByUsername();
        const staff_id = staff.id;
        try {
            const response = await axios.get(`${BASE_URL}/shop/api/shop/owner/${staff_id}/`);
            if (response.data) {
                setShopDetails(response.data);
            }
            return response.data;
        } catch (error) {
            alert("vous n'êtes pas autorisé à accéder à cette page.")
            console.log(error);
        };
    };

    const [supplier, setSupplier] = useState({});
    const [formData, setFormData] = React.useState({
        name: '',
        code: '',
        description: '',
        address: '',
        phone: '',
        email: '',
        website: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        // Set loading to true when starting the function
        setIsLoading(true);
        // Get shop id
        const shop_id = shopDetails.id;

        e.preventDefault();
        try {
            // Replace 'YOUR_API_URL' with the actual URL for supplierCreateAPIView
            await axios.put(`${BASE_URL}/shop/api/supplier/${suppID}/${shop_id}/edit/`, formData);
            // Optionally, you can add a success message or redirect the user to another page.
            alert('Modification réussie!');
            navigate('/fournisseurs');
        } catch (error) {
            // Handle any errors that may occur during the API call
            console.error('Error updating supplier:', error);
            alert('Erreur dans la modification du matériel .Veuillez réessayer');
        } finally {
            // Set loading to false when the function is done, regardless of success or failure
            setIsLoading(false);
        }
    };

    useEffect(() => {
        // Update the form data when the Expense details are fetched
        setFormData({
            name: supplier.name || '',
            code: supplier.code || '',
            description: supplier.description || '',
            address: supplier.address || '',
            phone: supplier.phone || '',
            email: supplier.email || '',
            website: supplier.website || '',
        });
    }, [supplier]);

    useEffect(() => {
        async function fetchData() {
            const shop = await getShopDetails();
            const shop_id = shop.id;
            // Fetch the Expense details from the API using the provided expenseId
            axios.get(`${BASE_URL}/shop/api/supplier/${suppID}/${shop_id}/edit/`)
                .then((response) => setSupplier(response.data))
                .catch((error) => console.log(error));
        }
        fetchData();

    }, []);

    return (
        (shopDetails ?
            <div>
                <NavbarBackoffice />
                <CContainer className="py-4">
                    <CRow className="justify-content-center">
                        <CCol sm="8" lg="6">
                            <CForm onSubmit={handleSubmit}>
                                {isLoading ? <LoadingSpinner /> :
                                    <>
                                        <CCol xs="auto">
                                            <CFormLabel htmlFor="name">Nom du fournisseur :</CFormLabel >
                                            <CFormInput type="text" id="name" name="name" value={formData.name} onChange={handleChange} required />
                                        </CCol>
                                        <CCol xs="auto">
                                            <CFormLabel htmlFor="description">Code fournisseur :</CFormLabel >
                                            <CFormInput type="text" id="code" name="code" value={supplier.code} disabled />
                                        </CCol>
                                        <CCol xs="auto">
                                            <CFormLabel htmlFor="description">Déscription :</CFormLabel >
                                            <CFormTextarea id="description" name="description" value={formData.description} onChange={handleChange} />
                                        </CCol>
                                        <CCol xs="auto">
                                            <CFormLabel htmlFor="address">Adresse :</CFormLabel >
                                            <CFormInput type="text" id="address" name="address" value={formData.address} onChange={handleChange} required />
                                        </CCol>
                                        <CCol xs="auto">
                                            <CFormLabel htmlFor="phone">Téléphone :</CFormLabel >
                                            <CFormInput type="text" id="phone" name="phone" value={formData.phone} onChange={handleChange} />
                                        </CCol>
                                        <CCol xs="auto">
                                            <CFormLabel htmlFor="email">Adresse-email :</CFormLabel >
                                            <CFormInput type="email" id="email" name="email" value={formData.email} onChange={handleChange} />
                                        </CCol>
                                        <CCol xs="auto">
                                            <CFormLabel htmlFor="website">Site Web:</CFormLabel >
                                            <CFormInput type="text" id="website" name="website" value={formData.website} onChange={handleChange} />
                                        </CCol>
                                        <hr />
                                        <CButton type="submit" color="primary">
                                            <CIcon type="submit" className="icon-default-add" icon={icon.cilSave} />
                                            &nbsp;Enregistrer la modification
                                        </CButton>
                                    </>
                                }
                            </CForm>
                        </CCol>
                    </CRow>
                </CContainer>
                <br /><br />
                <Footer />
            </div> : <div><NavbarBackoffice /><LoadingSpinner /></div>
        )
    );
};

export default EditSupplier;
